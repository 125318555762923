import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../api/API';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { displayFormatedDateAndTime } from '../../utils';
import {
  edgeTypes,
  nodeTypes,
} from '../../pages/Settings/Workflows/components/WorkflowCanvas';
import FloatingConnectionLine from '../../pages/Settings/Workflows/components/edges/FloatingEdge/FloatingConnectionLine';
import { ActivityFeedWidgetQueryKey } from '../ActivityFeedWidget';
import WorkflowExecutionContext from '../../contexts/WorkflowExecutionContext';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

export interface SeekMutationVariables {
  nodeTargetId: string;
}

interface Props {
  triggerId: string;
  targetCuid: string;
}

export default function WorkflowExecutionsViewer({
  triggerId,
  targetCuid,
}: Props) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [showExecutionLogTab, setShowExecutionLogTab] = useState(false);

  const invalidateQueries = () => {
    queryClient.invalidateQueries(['workflows']);
    queryClient.invalidateQueries(['inventory-model', targetCuid]);
    queryClient.invalidateQueries({
      queryKey: [ActivityFeedWidgetQueryKey],
    });
  };

  const seek = useMutation(
    [],
    async ({ nodeTargetId }: SeekMutationVariables) => {
      return await API.WorkflowSeekTo(triggerId, targetCuid, nodeTargetId);
    },
    {
      onSuccess: () => {
        refetch();
        invalidateQueries();
        toast({
          title: 'Workflow execution seeked successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error seeking workflow execution',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
    },
  );

  const resumeWait = useMutation(
    [],
    async () => {
      return await API.WorkflowResumeWait(triggerId, targetCuid);
    },
    {
      onSuccess: () => {
        refetch();
        invalidateQueries();
        toast({
          title: 'Workflow execution seeked successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const { data, isLoading, refetch } = useQuery(
    ['workflows', 'executions', triggerId, targetCuid],
    async () => {
      return API.GetWorkflowExecutions(triggerId, targetCuid);
    },
    {
      enabled: isOpen,
      onSuccess: data => {
        if (data.exists) {
          const nodes = data.execution.workflow.version.source.nodes.map(
            (node: Node) => {
              node.data.execution = data.execution;
              if (node.id === data.execution.current_node) {
                return {
                  ...node,
                  className: 'current-workflow-node',
                };
              }
              return node;
            },
          );
          setNodes(nodes || []);
          setEdges(data.execution.workflow.version.source.edges || []);
        }
      },
    },
  );

  const startExecution = useMutation(
    ['workflows', 'executions', triggerId, targetCuid],
    async () => {
      return API.StartWorkflowExecutions(triggerId, targetCuid);
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    },
  );

  return (
    <>
      <Button
        variant={'ghost'}
        onClick={onOpen}
        leftIcon={<Icon as={ArrowsRightLeftIcon} />}
        data-testid="see-workflow-btn"
      >
        See Workflow
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent
          bg="neutral.50"
          data-testid="workflow-modal"
          h="95vh"
          maxHeight="95vh"
          width="100%"
          maxW="95%"
        >
          {isLoading ? (
            <Progress isIndeterminate={true} />
          ) : (
            <>
              {data?.exists ? (
                <>
                  <ModalHeader>{data.execution.workflow.title}</ModalHeader>
                  <ModalCloseButton data-testid="close-workflow-modal-btn" />

                  <ModalBody>
                    <Tabs size="md" colorScheme="brand">
                      {showExecutionLogTab && (
                        <TabList>
                          <Tab
                            onDoubleClick={() =>
                              setShowExecutionLogTab(!showExecutionLogTab)
                            }
                          >
                            Workflow
                          </Tab>
                          <Tab>Execution Log</Tab>
                        </TabList>
                      )}

                      <TabPanels>
                        <TabPanel>
                          <Box
                            h={'calc(100vh - clamp(100px, 18vh, 180px ))'}
                            w={'full'}
                          >
                            <WorkflowExecutionContext.Provider
                              value={{
                                seek,
                                resumeWait,
                              }}
                            >
                              <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                nodeTypes={nodeTypes}
                                edgeTypes={edgeTypes}
                                connectionLineComponent={FloatingConnectionLine}
                                fitView
                                fitViewOptions={{
                                  nodes: nodes.filter(
                                    node =>
                                      node.className ===
                                      'current-workflow-node',
                                  ),
                                }}
                                minZoom={0.2}
                                maxZoom={1}
                              >
                                <Background />
                                <MiniMap />
                                <Controls />
                              </ReactFlow>
                            </WorkflowExecutionContext.Provider>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Accordion allowToggle>
                            {data.execution.steps.map((step, index) => (
                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                      {displayFormatedDateAndTime(
                                        step.created_at,
                                      )}{' '}
                                      - {step.ran_by?.name || 'user'} triggered{' '}
                                      {step.execution_data.trigger} -{' '}
                                      {step.tags.join(', ')}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <pre>
                                    {JSON.stringify(
                                      step.execution_data,
                                      null,
                                      2,
                                    )}
                                  </pre>
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                </>
              ) : (
                <ModalBody>
                  <Text>There are no executions for this workflow</Text>
                  <Button
                    onClick={() => startExecution.mutate()}
                    isLoading={startExecution.isLoading}
                  >
                    Start execution for {triggerId}
                  </Button>
                </ModalBody>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
