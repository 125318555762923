import {
  Box,
  Heading,
  Text,
  VStack,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Spinner,
  SimpleGrid,
  Badge,
  Flex,
  Divider,
  HStack,
  Avatar,
  Button,
  IconButton,
  useToast,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams, Link } from 'react-router-dom';
import { useUsers } from '../../hooks/admin';
import { displayFormatedDateAndTime } from '../../utils';
import {
  EyeIcon,
  EyeSlashIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import { EditUserModal } from '../components/EditUserModal';
import { AddToOrganizationModal } from '../components/AddToOrganizationModal';
import { ManageUserRolesModal } from '../components/ManageUserRolesModal';
import { Organization } from '../../api/AdminAPI';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI from '../../api/AdminAPI';

// Helper component for displaying field pairs
const FieldPair = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <Box>
    <Text fontWeight="bold" color="gray.500" mb={1}>
      {label}
    </Text>
    <Text>{value}</Text>
  </Box>
);

function ViewUser() {
  const queryClient = useQueryClient();
  const { cuid } = useParams();
  const { data: users, isLoading } = useUsers();
  const cardBg = useColorModeValue('white', 'gray.800');

  const user = users?.find(u => u.cuid === cuid);

  // States for managing API secrets visibility
  const [visibleSecrets, setVisibleSecrets] = useState<{
    [key: string]: boolean;
  }>({});
  const toast = useToast();

  const editModalDisclosure = useDisclosure();
  const addOrgModalDisclosure = useDisclosure();
  const [selectedOrgForRoles, setSelectedOrgForRoles] =
    useState<Organization | null>(null);
  const manageRolesModalDisclosure = useDisclosure();

  // Helper function to toggle secret visibility
  const toggleSecretVisibility = (index: number) => {
    setVisibleSecrets(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Helper function to copy to clipboard
  const copyToClipboard = (text: string, label: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: 'Copied!',
          description: `${label} copied to clipboard`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      err => {
        toast({
          title: 'Failed to copy',
          description: 'Please try again',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      },
    );
  };

  const revokeCredentialsMutation = useMutation({
    mutationFn: async ({
      userCuid,
      apiKey,
    }: {
      userCuid: string;
      apiKey: string;
    }) => {
      return AdminAPI.RevokeUserApiCredentials(userCuid, apiKey);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      toast({
        title: 'API credentials revoked and regenerated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: 'Error revoking API credentials',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" h="400px">
        <Spinner size="xl" color="brand.500" />
      </Box>
    );
  }

  if (!user) {
    return (
      <Box p={8}>
        <Text>User not found</Text>
      </Box>
    );
  }

  return (
    <Box>
      <VStack spacing={8} align="stretch">
        <Flex justify="space-between" align="center">
          <HStack spacing={4}>
            <Avatar size="lg" src={user.picture} name={user.name} />
            <Heading size="lg">
              {user.last_name ? `${user.name} ${user.last_name}` : user.name}
            </Heading>
          </HStack>
          <Button
            variant="secondary"
            onClick={editModalDisclosure.onOpen}
            leftIcon={<Icon as={PencilIcon} w={4} h={4} />}
          >
            Edit User
          </Button>
        </Flex>

        <Card bg={cardBg} shadow="md">
          <CardHeader>
            <Heading size="md">User Details</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={6} align="stretch" divider={<Divider />}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                <FieldPair label="User ID" value={user.cuid} />
                <FieldPair label="Email" value={user.email} />
                {user.job_title && (
                  <FieldPair label="Job Title" value={user.job_title} />
                )}
                <FieldPair
                  label="Created At"
                  value={displayFormatedDateAndTime(user.created_at)}
                />
                {user.updated_at && (
                  <FieldPair
                    label="Last Updated"
                    value={displayFormatedDateAndTime(user.updated_at)}
                  />
                )}
                <FieldPair
                  label="Terms Accepted"
                  value={
                    <Badge colorScheme={user.accepted_tyc ? 'green' : 'red'}>
                      {user.accepted_tyc ? 'Yes' : 'No'}
                    </Badge>
                  }
                />
                <FieldPair
                  label="AI Terms Accepted"
                  value={
                    <Badge
                      colorScheme={user.accepted_ai_terms ? 'green' : 'red'}
                    >
                      {user.accepted_ai_terms ? 'Yes' : 'No'}
                    </Badge>
                  }
                />
              </SimpleGrid>
            </VStack>
          </CardBody>
        </Card>

        <Card bg={cardBg} shadow="md">
          <CardHeader>
            <Flex justify="space-between" align="center">
              <Heading size="md">Organizations & Roles</Heading>
              <Button
                size="sm"
                variant="secondary"
                leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
                onClick={addOrgModalDisclosure.onOpen}
              >
                Add to Organization
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <VStack spacing={4} align="stretch">
              {user.organizations.map((org: Organization) => (
                <Box key={org.cuid} p={4} borderWidth="1px" borderRadius="md">
                  <Flex justify="space-between" align="center" mb={2}>
                    <Link to={`/admin/organizations/${org.cuid}`}>
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color="brand.500"
                        _hover={{ textDecoration: 'underline' }}
                      >
                        {org.name}
                      </Text>
                    </Link>
                    <Button
                      size="sm"
                      variant="secondary"
                      leftIcon={<Icon as={PencilIcon} w={4} h={4} />}
                      onClick={() => {
                        setSelectedOrgForRoles(org);
                        manageRolesModalDisclosure.onOpen();
                      }}
                    >
                      Manage Roles
                    </Button>
                  </Flex>
                  <HStack spacing={2}>
                    {org.roles.map(role => (
                      <Badge key={role.cuid} colorScheme="blue">
                        {role.name}
                      </Badge>
                    ))}
                  </HStack>
                </Box>
              ))}
            </VStack>
          </CardBody>
        </Card>

        {user.api_clients && user.api_clients.length > 0 && (
          <Card bg={cardBg} shadow="md">
            <CardHeader>
              <Heading size="md">API Credentials</Heading>
            </CardHeader>
            <CardBody>
              <VStack spacing={6} align="stretch">
                {user.api_clients.map((client, index) => (
                  <Box
                    key={client.api_key}
                    p={6}
                    borderWidth="1px"
                    borderRadius="md"
                    position="relative"
                  >
                    <VStack spacing={4} align="stretch">
                      <Flex justify="space-between" align="flex-start">
                        <VStack align="stretch" spacing={4} flex={1}>
                          <Box>
                            <Text fontWeight="medium" color="gray.500" mb={2}>
                              API Key
                            </Text>
                            <HStack spacing={2}>
                              <Text fontFamily="mono">{client.api_key}</Text>
                              <Button
                                size="xs"
                                variant="ghost"
                                onClick={() =>
                                  copyToClipboard(client.api_key, 'API Key')
                                }
                              >
                                Copy
                              </Button>
                            </HStack>
                          </Box>

                          <Box>
                            <Text fontWeight="medium" color="gray.500" mb={2}>
                              API Secret
                            </Text>
                            <HStack spacing={2}>
                              <Text fontFamily="mono">
                                {visibleSecrets[index]
                                  ? client.api_secret
                                  : '••••••••••••••••'}
                              </Text>
                              <IconButton
                                aria-label={
                                  visibleSecrets[index]
                                    ? 'Hide API Secret'
                                    : 'Show API Secret'
                                }
                                icon={
                                  <Icon
                                    as={
                                      visibleSecrets[index]
                                        ? EyeSlashIcon
                                        : EyeIcon
                                    }
                                    w={4}
                                    h={4}
                                  />
                                }
                                size="xs"
                                variant="ghost"
                                onClick={() => toggleSecretVisibility(index)}
                              />
                              <Button
                                size="xs"
                                variant="ghost"
                                onClick={() =>
                                  copyToClipboard(
                                    client.api_secret,
                                    'API Secret',
                                  )
                                }
                              >
                                Copy
                              </Button>
                            </HStack>
                          </Box>

                          <Box>
                            <Text fontWeight="medium" color="gray.500" mb={2}>
                              Organization
                            </Text>
                            <Link
                              to={`/admin/organizations/${client.organization_cuid}`}
                            >
                              <Text
                                color="brand.500"
                                _hover={{ textDecoration: 'underline' }}
                              >
                                {user.organizations.find(
                                  org => org.cuid === client.organization_cuid,
                                )?.name || client.organization_cuid}
                              </Text>
                            </Link>
                          </Box>
                        </VStack>

                        <Button
                          size="sm"
                          colorScheme="red"
                          variant="outline"
                          onClick={() =>
                            revokeCredentialsMutation.mutate({
                              userCuid: user.cuid,
                              apiKey: client.api_key,
                            })
                          }
                          isLoading={revokeCredentialsMutation.isLoading}
                        >
                          Revoke & Regenerate
                        </Button>
                      </Flex>
                    </VStack>
                  </Box>
                ))}
              </VStack>
            </CardBody>
          </Card>
        )}

        <EditUserModal
          isOpen={editModalDisclosure.isOpen}
          onClose={editModalDisclosure.onClose}
          user={user}
        />
        <AddToOrganizationModal
          isOpen={addOrgModalDisclosure.isOpen}
          onClose={addOrgModalDisclosure.onClose}
          user={user}
        />
        {selectedOrgForRoles && (
          <ManageUserRolesModal
            isOpen={manageRolesModalDisclosure.isOpen}
            onClose={() => {
              manageRolesModalDisclosure.onClose();
              setSelectedOrgForRoles(null);
            }}
            user={user}
            organization={selectedOrgForRoles}
          />
        )}
      </VStack>
    </Box>
  );
}

export default ViewUser;
