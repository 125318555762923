import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import { Label } from '../../Layout';
import { GridViewItem } from '../../GridView/GridViewItem';
import { TSavedView, TSavedViewTypes } from '../../../models/saved_view';
import ModelInventoryTable from '../../ModelInventoryTable';
import FindingsTable from '../../FindingsTable';

const FieldSet = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <Stack
    w="full"
    p={2}
    border={1}
    borderRadius={'md'}
    borderStyle={'solid'}
    alignItems={'normal'}
    borderColor={'var(--chakra-colors-chakra-border-color)'}
    gap={2}
  >
    <Label>{label}</Label>
    {children}
  </Stack>
);

interface ModalProps {
  type: TSavedViewTypes;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (savedView: TSavedView) => Promise<void>;
}

const AddSavedViewToDashboardModal: React.FC<ModalProps> = ({
  type: selectedViewType,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [selectedView, setSelectedView] = React.useState<TSavedView>();
  const [isRenderReady, setIsRenderReady] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { isLoading, data: savedViews } = useQuery(
    ['saved-views'],
    async () => {
      const findingViews = await API.GetSavedViews('finding');
      const modelViews = await API.GetSavedViews('model');
      return [...findingViews, ...modelViews];
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    if (isOpen && selectedViewType && selectedView) {
      setTimeout(() => {
        setIsRenderReady(true);
      }, 500);
    } else {
      setIsRenderReady(false);
    }
  }, [isOpen, selectedViewType, selectedView]);

  if (!isOpen) {
    return null;
  }

  const viewTypeOptions = [
    { label: 'Inventory Model', value: 'model' },
    { label: 'Findings', value: 'finding' },
  ];

  const viewOptions =
    savedViews
      ?.filter(view => view.type === selectedViewType)
      .map(view => ({
        label: view.name,
        value: view.cuid,
      })) || [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'5xl'}
    >
      <ModalOverlay />
      <ModalContent transition={'all .3s ease-in-out'}>
        <ModalHeader>
          Add{' '}
          {selectedViewType === 'model' ? 'Model Inventory' : 'Model Findings'}{' '}
          View
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flex={1} overflow={'visible'}>
          <VStack gap={4} flex={1}>
            {viewOptions.length > 0 ? (
              <FieldSet label="View Name">
                <Select
                  size={'md'}
                  colorScheme="brand"
                  classNamePrefix="chakra-react-select"
                  isSearchable={false}
                  onChange={(e: any) => {
                    if (!e) {
                      setSelectedView(undefined);
                      return;
                    }
                    const found = savedViews?.find(
                      view => view.cuid === e.value,
                    );
                    setSelectedView(found);
                  }}
                  options={viewOptions}
                  value={viewOptions.find(v => v.value === selectedView?.cuid)}
                />
              </FieldSet>
            ) : (
              <Stack
                fontStyle={'oblique'}
                color={'neutral.500'}
                textAlign={'center'}
              >
                <Text>
                  There doesn't seem to be any saved{' '}
                  {selectedViewType === 'model'
                    ? 'Model Inventory'
                    : 'Model Findings'}{' '}
                  views.
                </Text>
                <Text>
                  Create a{' '}
                  {selectedViewType === 'model'
                    ? 'Model Inventory'
                    : 'Model Findings'}{' '}
                  view first and then you will be able to add it to your
                  dashboard here.
                </Text>
              </Stack>
            )}
            {selectedView && selectedViewType && (
              <VStack h="full" flexGrow={1}>
                <Box
                  w={'full'}
                  h="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isRenderReady && (
                    <GridViewItem
                      style={{
                        width: '100%',
                        height: '500px',
                        overflow: 'hidden',
                      }}
                      title={selectedView.name}
                    >
                      {selectedView.type === 'model' && (
                        <ModelInventoryTable
                          displayTable
                          filters={selectedView.content.filters}
                          sortBy={selectedView.content.sortBy}
                          columns={selectedView.content.columns}
                        />
                      )}
                      {selectedView.type === 'finding' && (
                        <FindingsTable
                          filters={selectedView.content.filters}
                          sortBy={selectedView.content.sortBy}
                          columns={selectedView.content.columns}
                        />
                      )}
                    </GridViewItem>
                  )}
                </Box>
              </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isLoading={isSubmitting}
            variant={'primary'}
            onClick={async () => {
              if (selectedView && selectedViewType) {
                setIsSubmitting(true);
                onSubmit(selectedView);
                setIsSubmitting(false);
              }
            }}
            isDisabled={!selectedView || !selectedViewType || isLoading}
          >
            Add{' '}
            {selectedViewType === 'model'
              ? 'Model Inventory'
              : 'Model Findings'}{' '}
            View
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSavedViewToDashboardModal;
