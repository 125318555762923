import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../api/API';
import { ApprovalVote, ApprovalVoter } from '../../models/approval';
import { useState } from 'react';
import ModelInventoryItem from '../ModelInventoryItem';
import { TInventoryModel } from '../../models/inventory_model';
import { Label } from '../Layout';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ChevronDownIcon, ChevronUpIcon, Icon } from '@chakra-ui/icons';
import _ from 'lodash';
import AvatarProxy from '../AvatarProxy';
import { DocumentTextIcon } from '@heroicons/react/24/outline';

interface WorkflowApprovalItemProps {
  variant?: 'page';
  voter: ApprovalVoter;
}

interface VoteVariables {
  vote: ApprovalVote;
  notes: string;
}

const VoterStatusColumns: {
  vote: ApprovalVote;
  bgColor: string;
}[] = [
  {
    vote: 'rejected',
    bgColor: 'red.100',
  },
  {
    vote: null,
    bgColor: 'gray.100',
  },
  {
    vote: 'approved',
    bgColor: 'green.100',
  },
];

const VoteDetailsModal = ({
  voter,
  onClose,
  isOpen,
}: {
  voter?: ApprovalVoter;
  onClose: () => void;
  isOpen: boolean;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Notes for {voter?.user.name}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Textarea value={voter?.notes} bg={'white'} readOnly />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function WorkflowApprovalItem({
  variant,
  voter,
}: WorkflowApprovalItemProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [notes, setNotes] = useState('');
  const [voted, setVoted] = useState<ApprovalVote>(null);
  const [showVoters, setShowVoters] = useState(false);
  const [voterForDetails, setVoterForDetails] =
    useState<ApprovalVoter | null>();

  const voterDetailsModal = useDisclosure();

  const vote = useMutation(
    ['approvals', 'voters'],
    async ({ vote, notes }: VoteVariables) => {
      return API.PostApprovalVote(voter.cuid, vote, notes);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['approvals', 'voters']);
        queryClient.invalidateQueries(['inventory-model']);
        queryClient.invalidateQueries(['workflows']);
        toast({
          title: 'Decision submitted successfully',
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
          variant: 'subtle',
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: error.message,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        }
      },
      onSettled: () => {
        onClose();
      },
    },
  );
  const onSubmit = async () => {
    vote.mutate({ vote: voted, notes });
  };

  const target_display_component = {
    InventoryModel: (
      <Box bg={useColorModeValue('white', 'neutral.850')} rounded={'md'}>
        <ModelInventoryItem
          inventoryModel={voter.approval.execution.target as TInventoryModel}
        />
      </Box>
    ),
  };

  const onClose = () => {
    setVoted(null);
  };

  const alreadyVoted = voter.vote !== null;

  return (
    <>
      <VoteDetailsModal
        isOpen={voterDetailsModal.isOpen}
        voter={voterForDetails || undefined}
        onClose={voterDetailsModal.onClose}
      />
      <Stack
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        p={4}
        rounded={'md'}
        gap={4}
      >
        <Stack gap={2}>
          <Heading as={'h3'}>{voter.approval.title}</Heading>
          <Text>{voter.approval.message}</Text>

          {!alreadyVoted && (
            <HStack w={'full'}>
              <Button
                onClick={() => setShowVoters(!showVoters)}
                variant={'ghost'}
                rightIcon={
                  <Icon
                    as={showVoters ? ChevronUpIcon : ChevronDownIcon}
                    boxSize={5}
                  />
                }
              >
                {showVoters ? 'Hide' : 'Show'} Approvers
              </Button>
              <Spacer />
              <Button
                bg={'red.100'}
                color={'red.700'}
                _hover={{ bg: 'red.50' }}
                _dark={{ bg: 'red.100' }}
                onClick={() => setVoted('rejected')}
                isActive={voted === 'rejected'}
                leftIcon={<Icon as={XMarkIcon} boxSize={5} />}
              >
                Reject
              </Button>
              <Button
                bg={'green.100'}
                color={'green.700'}
                _hover={{ bg: 'green.50' }}
                _dark={{ bg: 'green.100' }}
                onClick={() => setVoted('approved')}
                isActive={voted === 'approved'}
                leftIcon={<Icon as={CheckIcon} boxSize={5} />}
              >
                Approve
              </Button>
            </HStack>
          )}
        </Stack>

        {variant === 'page' && (
          <>
            {
              target_display_component[
                voter.approval.execution.target_type as 'InventoryModel'
              ]
            }
          </>
        )}

        <Collapse in={alreadyVoted || showVoters} animateOpacity>
          <Flex>
            <HStack
              flexGrow={1}
              w="full"
              align="flex-start"
              gap={1}
              rounded={'md'}
              overflow={'hidden'}
            >
              {VoterStatusColumns.map(statusColumn => {
                const votersForStatus = voter.approval.voters.filter(
                  v => v.vote === statusColumn.vote,
                );
                return (
                  <VStack
                    align="flex-start"
                    bgColor={useColorModeValue('white', 'neutral.850')}
                    p={2}
                    gap={2}
                    h={'full'}
                    border={'1px solid'}
                    borderColor={useColorModeValue(
                      'neutral.200',
                      'neutral.800',
                    )}
                    borderTop={'8px solid'}
                    borderTopColor={statusColumn.bgColor}
                    roundedBottomLeft={
                      statusColumn.vote === 'rejected' ? 'md' : 0
                    }
                    roundedBottomRight={
                      statusColumn.vote === 'approved' ? 'md' : 0
                    }
                    w={'full'}
                  >
                    <Heading as={'h4'}>
                      {_.capitalize(statusColumn.vote || 'pending')}
                    </Heading>
                    <VStack gap={1} align="flex-start" w={'full'}>
                      {votersForStatus.map(v => {
                        return (
                          <HStack>
                            <HStack
                              bg={useColorModeValue(
                                'neutral.100',
                                'neutral.800',
                              )}
                              rounded={'full'}
                              pr={2}
                            >
                              <AvatarProxy
                                size={'sm'}
                                name={v.user.name}
                                src={
                                  v.user.picture ||
                                  'https://placekitten.com/100/100'
                                }
                              />
                              <Text fontSize={'sm'} lineHeight={'normal'}>
                                {v.user.name}
                              </Text>
                            </HStack>
                            {v.notes && (
                              <Popover trigger="hover">
                                <PopoverTrigger>
                                  <Icon as={DocumentTextIcon} />
                                </PopoverTrigger>
                                <PopoverContent shadow={'md'}>
                                  <PopoverArrow />
                                  <PopoverHeader
                                    fontSize={'sm'}
                                    fontWeight={'bold'}
                                    roundedTop={'md'}
                                  >
                                    Notes
                                  </PopoverHeader>
                                  <PopoverBody rounded={'md'} pt={0}>
                                    {v.notes}
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            )}
                          </HStack>
                        );
                      })}
                    </VStack>
                  </VStack>
                );
              })}
            </HStack>
          </Flex>
        </Collapse>
      </Stack>
      <Modal isCentered isOpen={!!voted} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent data-testid="change-status-modal">
          <ModalHeader>
            <Text>{voted === 'approved' ? 'Approve' : 'Reject'}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <FormControl isRequired>
                <Label mb={2}>NOTES:</Label>
                <Textarea
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  bg={'white'}
                  placeholder={'Enter your decision notes here. '}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setVoted(null)} disabled={false}>
              Cancel
            </Button>
            <Spacer />
            <Button onClick={onSubmit} isLoading={vote.isLoading}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
