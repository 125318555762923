import { useReducer } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useToast,
  Button,
} from '@chakra-ui/react';
import API from '../../../api/API';
import TitleDescriptionForm from '../../../components/TitleDescriptionForm';
import UserRoleTable from '../../../components/UserRoleTable';
import useOrganizationUsersWithRoles from '../../../hooks/useOrganizationUsersWithRoles';
import { TUser } from '../../../models';

interface CreateGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ModalState {
  currentStepIndex: number;
  newGroupName: string;
  newGroupDescription: string;
  disableNextStep: boolean;
  groupCuid?: string;
  selectedUserCuids: string[];
}

type ACTION_TYPE =
  | 'EDIT_GROUP_NAME'
  | 'EDIT_GROUP_DESCRIPTION'
  | 'SHOW_USER_SELECTION'
  | 'UPDATE_USER_SELECTION'
  | 'SHOW_GROUP_DETAILS'
  | 'RESET';

interface ModalStateAction extends Partial<ModalState> {
  type: ACTION_TYPE;
}

const GROUP_DESCRIPTION_MAX_LENGTH = 150;

const modalInitialState: ModalState = {
  currentStepIndex: 0,
  newGroupName: '',
  newGroupDescription: '',
  disableNextStep: true,
  selectedUserCuids: [],
};

function modalStateReducer(
  modalState: ModalState,
  action: ModalStateAction,
): ModalState {
  switch (action.type) {
    case 'EDIT_GROUP_NAME': {
      return {
        ...modalState,
        newGroupName: action.newGroupName ?? modalInitialState.newGroupName,
        disableNextStep: action.disableNextStep ?? modalState.disableNextStep,
      };
    }
    case 'EDIT_GROUP_DESCRIPTION': {
      return {
        ...modalState,
        newGroupDescription:
          action.newGroupDescription ?? modalInitialState.newGroupDescription,
        disableNextStep: action.disableNextStep ?? modalState.disableNextStep,
      };
    }
    case 'SHOW_USER_SELECTION': {
      return {
        ...modalState,
        currentStepIndex: 1,
      };
    }
    case 'UPDATE_USER_SELECTION': {
      return {
        ...modalState,
        selectedUserCuids: action.selectedUserCuids ?? [],
      };
    }
    case 'SHOW_GROUP_DETAILS': {
      return {
        ...modalState,
        currentStepIndex: 0,
      };
    }
    case 'RESET': {
      return modalInitialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function CreateGroupModal({
  isOpen,
  onClose,
}: CreateGroupModalProps) {
  const toast = useToast();
  const { organizationUsersWithRoles } = useOrganizationUsersWithRoles();
  const queryClient = useQueryClient();

  const [
    {
      currentStepIndex,
      newGroupName,
      newGroupDescription,
      disableNextStep,
      selectedUserCuids,
    },
    dispatch,
  ] = useReducer(modalStateReducer, modalInitialState);

  const closeAndReset = () => {
    onClose();
    dispatch({ type: 'RESET' });
  };

  const addUsersToGroupMutation = useMutation(
    [],
    async ({ groupCuid, users }: { groupCuid: string; users: string[] }) => {
      await API.AddUsersToGroup(groupCuid, users);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups');
        closeAndReset();
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error adding users to group',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const createGroupMutation = useMutation(
    [],
    async ({ name, description }: { name: string; description: string }) => {
      return API.CreateGroup(name, description);
    },
    {
      onSuccess: ({ cuid }) => {
        void addUsersToGroupMutation.mutate({
          groupCuid: cuid,
          users: selectedUserCuids,
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error creating group',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const steps = [
    {
      stepIndex: 0,
      title: 'Group Details',
      content: (
        <TitleDescriptionForm
          titleLabel="Group Name"
          title={newGroupName}
          titlePlaceholder="Admins"
          onTitleChange={(updatedGroupName: string) => {
            const isTitleValid = updatedGroupName !== '';
            dispatch({
              type: 'EDIT_GROUP_NAME',
              newGroupName: updatedGroupName,
              disableNextStep:
                !isTitleValid ||
                newGroupDescription.length > GROUP_DESCRIPTION_MAX_LENGTH,
            });
            return isTitleValid;
          }}
          descriptionLabel="Group Description"
          description={newGroupDescription}
          descriptionPlaceholder="Describe what this group is for."
          onDescriptionChange={(updatedGroupDescription: string) => {
            const isDescriptionValid =
              updatedGroupDescription.length <= GROUP_DESCRIPTION_MAX_LENGTH;
            dispatch({
              type: 'EDIT_GROUP_DESCRIPTION',
              newGroupDescription: updatedGroupDescription,
              disableNextStep: !isDescriptionValid || newGroupName === '',
            });
            return isDescriptionValid;
          }}
          descriptionCharacterLimit={GROUP_DESCRIPTION_MAX_LENGTH}
        />
      ),
      footer: (
        <>
          <Button variant={'tertiary'} onClick={closeAndReset}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={disableNextStep}
            variant={'primary'}
            onClick={(): void => dispatch({ type: 'SHOW_USER_SELECTION' })}
          >
            Continue
          </Button>
        </>
      ),
    },
    {
      stepIndex: 1,
      title: 'Invite users to group',
      content: (
        <UserRoleTable
          users={organizationUsersWithRoles}
          selectedUserCuids={selectedUserCuids}
          enableRoleManagement={false}
          onUserSelect={(selectedUsers: TUser[]): TUser[] => {
            dispatch({
              type: 'UPDATE_USER_SELECTION',
              selectedUserCuids: selectedUsers.map(({ cuid }) => cuid),
            });
            return selectedUsers;
          }}
        />
      ),
      footer: (
        <HStack>
          <Button
            onClick={(): void => dispatch({ type: 'SHOW_GROUP_DETAILS' })}
          >
            Back
          </Button>
          <Button
            variant={'primary'}
            onClick={(): void =>
              createGroupMutation.mutate({
                name: newGroupName,
                description: newGroupDescription,
              })
            }
          >
            Create Group
          </Button>
        </HStack>
      ),
    },
  ];

  const currentStep = steps.find(
    ({ stepIndex }) => stepIndex === currentStepIndex,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndReset}
      isCentered
      closeOnEsc
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent data-testid="create-new-group-modal">
        <ModalBody>
          <ModalHeader pl={0}>Create New Group</ModalHeader>
          <ModalCloseButton />
          <Stack spacing={4}>
            <Stepper index={currentStepIndex} colorScheme="pink" size="sm">
              {steps.map(({ title }, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <StepTitle>{title}</StepTitle>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {currentStep?.content}
          </Stack>
        </ModalBody>
        <ModalFooter>{currentStep?.footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}
