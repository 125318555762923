import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CircularProgress,
  Flex,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  Button,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { Label } from '../Layout';
import { TStatusesWorkflowStatus } from '../../models/statuses_workflow';
import StatusesWorkflowContext from '../../contexts/StatusesWorkflowContext';

const colors = [
  { primary: 'gray.100', secondary: 'gray.300', tertiary: 'gray.800' },
  { primary: 'red.100', secondary: 'red.300', tertiary: 'red.800' },
  { primary: 'orange.100', secondary: 'orange.300', tertiary: 'orange.800' },
  { primary: 'amber.100', secondary: 'amber.300', tertiary: 'amber.800' },
  { primary: 'yellow.100', secondary: 'yellow.300', tertiary: 'yellow.800' },
  { primary: 'lime.100', secondary: 'lime.300', tertiary: 'lime.800' },
  { primary: 'green.100', secondary: 'green.300', tertiary: 'green.800' },
  { primary: 'emerald.100', secondary: 'emerald.300', tertiary: 'emerald.800' },
  { primary: 'teal.100', secondary: 'teal.300', tertiary: 'teal.800' },
  { primary: 'cyan.100', secondary: 'cyan.300', tertiary: 'cyan.800' },
  { primary: 'sky.100', secondary: 'sky.300', tertiary: 'sky.800' },
  { primary: 'indigo.100', secondary: 'indigo.300', tertiary: 'indigo.800' },
  { primary: 'violet.100', secondary: 'violet.300', tertiary: 'violet.800' },
  { primary: 'fuchsia.100', secondary: 'fuchsia.300', tertiary: 'fuchsia.800' },
  { primary: 'pink.100', secondary: 'pink.300', tertiary: 'pink.800' },
];

interface IAddStatusModalProps {
  isOpen: boolean;
  workflowStatus?: TStatusesWorkflowStatus;
  onClose: () => void;
}
export default function AddStatusModal({
  isOpen,
  workflowStatus = {
    name: '',
    description: '',
    colors: colors[0],
  },
  onClose,
}: IAddStatusModalProps) {
  const { workflow, createWorkflowStatusMutation } = useContext(
    StatusesWorkflowContext,
  );
  const [status, setStatus] = useState<TStatusesWorkflowStatus>(workflowStatus);

  useEffect(() => {
    if (workflowStatus?.cuid) {
      setStatus(workflowStatus);
    }
  }, [workflowStatus]);

  const { isLoading: rolesIsLoading } = useQuery(
    ['org-roles'],
    async () => {
      return await API.GetOrganizationRoles();
    },
    { initialData: () => [] },
  );

  if (rolesIsLoading) {
    return (
      <Stack justify={'center'} align={'center'}>
        <CircularProgress
          size="40px"
          thickness="2px"
          isIndeterminate
          color="brand.base"
        />
        <Text fontSize={'sm'} color={'inherit'}>
          Please hold...
        </Text>
      </Stack>
    );
  }

  const closeModal = () => {
    createWorkflowStatusMutation.reset();
    onClose();
    setStatus({
      name: '',
      description: '',
      colors: {},
    });
  };

  const onClickSave = () => {
    createWorkflowStatusMutation.mutate(
      { workflow, status },
      {
        onSuccess: (data: TStatusesWorkflowStatus) => {
          closeModal();
        },
      },
    );
  };

  const saveDisabled = !(
    status.name.length > 3 && status.description!.length > 3
  );

  // For existing status, the approved and rejected ones, should be read only names
  // until we figure out transition types that determine "logical conclusions" or "end events"
  // such as rejections and approvals.
  const nameReadOnly =
    !!workflowStatus?.cuid && ['Rejected', 'Approved'].includes(status.name);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={closeModal} size="3xl">
        <ModalOverlay />
        <ModalContent>
          {workflowStatus?.cuid ? (
            <ModalHeader>{status.name}</ModalHeader>
          ) : (
            <ModalHeader>Add Model Lifecycle Status</ModalHeader>
          )}

          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4}>
              {createWorkflowStatusMutation.isError && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle fontSize="sm">
                    Could not create model lifecycle status:
                  </AlertTitle>
                  <AlertDescription fontSize="sm">
                    {API.getAPIErrorMessage(createWorkflowStatusMutation.error)}
                  </AlertDescription>
                </Alert>
              )}

              <FormControl isRequired>
                <Label mb={2}>Name</Label>
                <Input
                  isDisabled={nameReadOnly}
                  readOnly={nameReadOnly}
                  type="text"
                  value={status?.name}
                  onChange={event =>
                    setStatus({ ...status, name: event.target.value })
                  }
                />
              </FormControl>

              {nameReadOnly && (
                <Alert status="info">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>
                      "{status.name}" is a reserved status name.
                    </AlertTitle>
                    <AlertDescription>
                      ValidMind uses this status to determine the outcome of the
                      document.
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

              <FormControl isRequired>
                <Label mb={2}>Description</Label>
                <Textarea
                  value={status.description}
                  onChange={event =>
                    setStatus({ ...status, description: event.target.value })
                  }
                  rows={4}
                ></Textarea>
              </FormControl>

              <FormControl isRequired>
                <Label mb={2}>Color</Label>
                <HStack spacing="10px">
                  {colors.map(c => (
                    <Box
                      key={c.primary}
                      w="40px"
                      h="40px"
                      bgColor={c.primary}
                      borderWidth={status.colors.primary === c.primary ? 4 : 2}
                      borderColor={
                        status.colors.primary === c.primary
                          ? 'brand.base'
                          : c.secondary
                      }
                      cursor={'pointer'}
                      rounded={'md'}
                      onClick={() => setStatus({ ...status, colors: c })}
                    ></Box>
                  ))}
                </HStack>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width="100%">
              <Button onClick={closeModal} variant={'ghost'}>
                Cancel
              </Button>
              <Spacer />
              <Button
                disabled={saveDisabled}
                onClick={onClickSave}
                variant={'primary'}
              >
                {createWorkflowStatusMutation.isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
