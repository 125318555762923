import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useOrganizations } from '../../hooks/admin';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI, {
  Organization,
  Role,
  PatchUserRequest,
} from '../../api/AdminAPI';
import { Select } from 'chakra-react-select';

interface AddToOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

export function AddToOrganizationModal({
  isOpen,
  onClose,
  user,
}: AddToOrganizationModalProps) {
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const { data: organizations } = useOrganizations();
  const queryClient = useQueryClient();
  const toast = useToast();

  // Filter out organizations the user is already part of
  const availableOrganizations = organizations?.filter(
    (org: Organization) =>
      !user.organizations.find(
        (userOrg: Organization) => userOrg.cuid === org.cuid,
      ),
  );

  const updateUserMutation = useMutation({
    mutationFn: async (patchData: PatchUserRequest) => {
      return AdminAPI.PatchUser(user.cuid, patchData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      toast({
        title: 'User added to organization',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    },
    onError: (error: any) => {
      toast({
        title: 'Error adding user to organization',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleSubmit = () => {
    if (!selectedOrganization) return;

    const updateData: PatchUserRequest = {
      email: user.email,
      name: user.name,
      last_name: user.last_name,
      organization: {
        organization_cuid: selectedOrganization.cuid,
        roles: selectedRoles.map((role: Role) => role.cuid),
      },
    };

    updateUserMutation.mutate(updateData);
  };

  const handleClose = () => {
    setSelectedOrganization(null);
    setSelectedRoles([]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add to Organization</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel>Organization</FormLabel>
              <Select
                options={availableOrganizations?.map(org => ({
                  value: org.cuid,
                  label: org.name,
                  data: org,
                }))}
                value={
                  selectedOrganization
                    ? {
                        value: selectedOrganization.cuid,
                        label: selectedOrganization.name,
                        data: selectedOrganization,
                      }
                    : null
                }
                onChange={option => {
                  setSelectedOrganization(option?.data || null);
                  setSelectedRoles([]);
                }}
                placeholder="Select organization"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Roles</FormLabel>
              <Select
                isMulti
                isDisabled={!selectedOrganization}
                options={
                  selectedOrganization?.roles
                    .filter(role => role.scope !== 'Model')
                    .map(role => ({
                      value: role.cuid,
                      label: role.name,
                      data: role,
                    })) || []
                }
                placeholder="Select roles"
                value={selectedRoles.map(role => ({
                  value: role.cuid,
                  label: role.name,
                  data: role,
                }))}
                onChange={selectedOptions =>
                  setSelectedRoles(selectedOptions.map(option => option.data))
                }
                closeMenuOnSelect={false}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={updateUserMutation.isLoading}
            isDisabled={!selectedOrganization || selectedRoles.length === 0}
          >
            Add to Organization
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
