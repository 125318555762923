import { useContext, useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { Guideline } from '../../../../models/guideline';
import { useCurrentModel } from '../../../../hooks/useModelInventory';
import { AIGenerationConfig } from '../../../TextContentEditor/AITextContentEditor';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import { MetadataContentEditor } from '../MetadataContentEditor';
import { useAuth } from 'react-oidc-context';
import UsersContext from '../../../../contexts/UsersContext';

export default function RiskAssessmentNotes({
  guideline,
  readOnlyFields,
}: {
  guideline: Guideline;
  readOnlyFields: boolean;
}) {
  const auth = useAuth();
  const { user } = auth;
  const { currentUser } = useContext(UsersContext);

  const { inventoryModel } = useCurrentModel();
  const [aiGenerationConfig, setAIGenerationConfig] =
    useState<AIGenerationConfig>();

  useEffect(() => {
    (async () => {
      setAIGenerationConfig({
        initEvent: {
          eventName: 'generate-risk-assessment',
          args: {
            accessToken: user?.access_token,
            inventory_model_cuid: inventoryModel?.cuid,
            content_id: guideline.content_id,
            config: {},
            user_cuid: currentUser?.cuid, // All args should be lowercase
          },
        },
        streamEvent: {
          eventName: `assessment-${guideline.cuid}`,
        },
        options: {
          enableUserPrompt: true,
        },
      });
    })();
  }, [inventoryModel, guideline]);

  const metadata = {
    content_id: guideline.content_id,
    content_type: ModelDocumentTypeEnum.validation_report,
  };

  return (
    <>
      <Box>
        <Heading as={'h4'}>Risk Assessment Notes</Heading>
        <MetadataContentEditor
          contents={metadata}
          readOnly={readOnlyFields}
          documentType={ModelDocumentTypeEnum.validation_report}
          customAIGenerationConfig={aiGenerationConfig}
        />
      </Box>
    </>
  );
}
