import { useQuery } from 'react-query';
import API from '../../../../../api/API';

function useStatusesWorkflows() {
  const query = useQuery(['statuses'], async () => {
    return await API.GetStatusesWorkflows();
  });

  return { ...query };
}

function useStatus(cuid: string) {
  const query = useQuery(
    ['statuses', cuid],
    async () => {
      return await API.GetStatusesWorkflowStatus(cuid);
    },
    {
      enabled: !!cuid,
    },
  );

  return { ...query };
}

export { useStatusesWorkflows, useStatus };
