import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  Icon,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  IconButton,
  LinkBox,
  Stack,
  HStack,
  Modal,
  useToast,
} from '@chakra-ui/react';
import {
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import GroupView from '../../../components/GroupView';
import UserRoleTable from '../../../components/UserRoleTable';
import { TGroup, TUser } from '../../../models';
import GroupMemberEditor from './GroupMemberEditor';
import GroupModelTable from './GroupModelTable';
import { UsersContext } from '../../../contexts';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { DangerMenuItem } from '../../../components/DangerMenuItem';
import { AddIcon } from '@chakra-ui/icons';
import useOrganizationUsersWithRoles from '../../../hooks/useOrganizationUsersWithRoles';
import API from '../../../api/API';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ConfirmationAlert from '../../../components/ConfirmationAlert';

export interface TGroupWithMembers extends TGroup {
  members: TUser[];
}

export default function Group({ group }: { group: TGroupWithMembers }) {
  const { organizationUsersWithRoles } = useOrganizationUsersWithRoles();
  const [showDeleteGroupConfirmation, setShowDeleteGroupConfirmation] =
    useState(false);
  const addMemberDisclosure = useDisclosure();
  const groupDisclosure = useDisclosure();

  const [membersToRemove, setMembersToRemove] = useState<string[]>([]);
  const { members, cuid } = group;
  const { userHasPermission } = useContext(UsersContext);

  const queryClient = useQueryClient();

  const canUpdateGroup = userHasPermission(['update_group'], 'all');
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
  const toast = useToast();
  useEffect(() => {
    setMembersToAdd([]);
  }, [addMemberDisclosure.isOpen]);

  useEffect(() => {
    setMembersToRemove([]);
  }, [members]);

  const handleUserSelect = (selectedUsers: TUser[]): TUser[] => {
    setMembersToRemove(selectedUsers.map(({ cuid }) => cuid));
    return selectedUsers;
  };

  const handleUserAddSelect = (selectedUsers: TUser[]): TUser[] => {
    setMembersToAdd(selectedUsers.map(({ cuid }) => cuid));
    return selectedUsers;
  };
  const { data: groupMembers = [] } = useQuery(
    ['group-members', group.cuid],
    async () => {
      return API.GetGroupUsers(group.cuid);
    },
  );
  // Filter out users who are already members of the group
  const availableUsers = organizationUsersWithRoles.filter(
    user => !groupMembers.some(member => member.cuid === user.cuid),
  );
  const handleAddToGroup = async () => {
    addToGroupGroupMutation.mutate({ groupId: group.cuid, membersToAdd });
    addMemberDisclosure.onClose();
  };

  const addToGroupGroupMutation = useMutation(
    [],
    async ({
      groupId,
      membersToAdd,
    }: {
      groupId: string;
      membersToAdd: string[];
    }) => {
      await API.AddUsersToGroup(groupId, membersToAdd);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups-members');
        void queryClient.invalidateQueries(['group-members', group.cuid]);
      },
    },
  );
  const deleteGroupMutation = useMutation(
    [],
    async (groupId: string) => {
      await API.DeleteGroup(groupId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error deleting group',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );
  const handleDeleteGroup = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      deleteGroupMutation.mutate(group.cuid);
    }
    setShowDeleteGroupConfirmation(false);
  };

  return (
    <LinkBox
      p={4}
      gap={4}
      rounded={'md'}
      border={'1px solid'}
      transition={'all 0.3s ease-in-out'}
      data-testid="group-container"
      _hover={
        groupDisclosure.isOpen
          ? {}
          : {
              bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
              borderColor: useColorModeValue(
                'brandSecondary.100',
                'brandSecondary.800',
              ),
              color: useColorModeValue('inherit', 'brandSecondary.25'),
            }
      }
      borderColor={
        groupDisclosure.isOpen
          ? 'brand.base'
          : useColorModeValue('neutral.200', 'neutral.800')
      }
      role="group"
      onClick={groupDisclosure.isOpen ? undefined : groupDisclosure.onToggle}
      cursor={groupDisclosure.isOpen ? 'unset' : 'pointer'}
    >
      <GroupView
        group={group}
        menu={
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              aria-label="Options"
              icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
              display={groupDisclosure.isOpen ? 'block' : 'none'}
              _groupHover={{
                display: 'block',
              }}
              onClick={event => {
                event.stopPropagation();
              }}
            />
            <MenuList>
              <MenuItem
                icon={
                  <Icon
                    as={groupDisclosure.isOpen ? EyeSlashIcon : EyeIcon}
                    boxSize={5}
                  />
                }
                onClick={groupDisclosure.onToggle}
              >
                {groupDisclosure.isOpen ? 'Hide Details' : 'See Details'}
              </MenuItem>
              <MenuItem
                icon={<Icon as={UserPlusIcon} boxSize={5} />}
                onClick={addMemberDisclosure.onOpen}
              >
                Add New Member
              </MenuItem>
              <MenuDivider />
              <DangerMenuItem
                icon={<Icon as={TrashIcon} boxSize={5} />}
                onClick={() => setShowDeleteGroupConfirmation(true)}
              >
                Delete Group
              </DangerMenuItem>
            </MenuList>
          </Menu>
        }
      />
      <Collapse in={groupDisclosure.isOpen}>
        <Stack mx={1}>
          <Button
            hidden={!groupDisclosure.isOpen}
            variant={'ghost'}
            leftIcon={
              groupDisclosure.isOpen ? (
                <Icon as={EyeSlashIcon} boxSize={4} />
              ) : (
                <Icon as={EyeIcon} boxSize={4} />
              )
            }
            onClick={groupDisclosure.onToggle}
            data-testid="group-details-toggle"
            alignSelf={'flex-end'}
          >
            {groupDisclosure.isOpen ? 'Hide Details' : 'See Details'}
          </Button>
          <UserRoleTable
            users={members}
            enableRoleManagement={false}
            onUserSelect={canUpdateGroup ? handleUserSelect : undefined}
          />
          {canUpdateGroup && (
            <GroupMemberEditor
              group={group}
              membersToRemove={membersToRemove}
              onOpenAddMember={addMemberDisclosure.onOpen}
              onDeleteGroup={() => {
                setShowDeleteGroupConfirmation(true);
              }}
            />
          )}
          <GroupModelTable groupCuid={cuid} />
        </Stack>
      </Collapse>
      <Modal
        isCentered
        isOpen={addMemberDisclosure.isOpen}
        onClose={addMemberDisclosure.onClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader p={0}>Add New Member</ModalHeader>
          <ModalCloseButton mt={3.5} mr={2} />
          <ModalBody pt={4} pl={0} pr={0} data-testid="add-to-group-modal">
            <UserRoleTable
              users={availableUsers}
              enableRoleManagement={false}
              onUserSelect={handleUserAddSelect}
            />
            <HStack pt={6} justifyContent={'end'}>
              <Button
                isDisabled={membersToAdd.length === 0}
                fontSize="sm"
                leftIcon={<AddIcon />}
                onClick={handleAddToGroup}
              >
                Add to Group
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ConfirmationAlert
        open={showDeleteGroupConfirmation}
        title="Delete Group"
        dialogBody={`Are you sure you'd like to delete "${group.name}" Group?
          All users will remain in the system but they will lose access to the models the group has access to.`}
        cancelButton={<Button variant="ghost">No, Cancel</Button>}
        confirmButton={<Button variant={'primary'}>Yes, Delete</Button>}
        onConfirm={handleDeleteGroup}
        size="3xl"
      />
    </LinkBox>
  );
}
