import {
  Box,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  VStack,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  useColorModeValue,
  ModalFooter,
  Select,
  Stack,
  HStack,
  Center,
  CircularProgress,
  Button,
  Flex,
  Wrap,
  TagCloseButton,
} from '@chakra-ui/react';
import { DataTable } from '../DataTable';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { displayFormatedDateAndTime } from '../../utils';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BarsArrowDownIcon,
  CalendarIcon,
  CircleStackIcon,
  CubeIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { TInputResult } from '../../api/API';
import { useQuery } from 'react-query';
import { TUser } from '../../models';
import AvatarProxy from '../AvatarProxy';
import TestResultsFilter from '../TestResultsFilter';
import { ModelDocumentTypeEnum } from '../../models/model_document';
import { BlockWrapperContext } from '../Templates/BlockWrapper';

export interface THistory {
  created_at: number;
  user?: TUser;
  inputs: TInputResult[];
}

interface InputInfoModalProps {
  createdAt: number;
  inputs: TInputResult[];
  user?: TUser;
  contentID: string;
  title: string;
  getHistoryForPage: (
    page: number,
    limit: number,
    params?: Record<string, any>,
    sortBy?: string,
    sortOrder?: string,
  ) => Promise<{
    history: THistory[];
    total: number;
  }>;
  contentType: ModelDocumentTypeEnum;
  hideLabel?: boolean;
}

export interface IFilterProps {
  datasets: string[];
  date_range: {
    start_date: number | null;
    end_date: number | null;
  };
  models: string[];
  ran_by: { name: string; cuid: string }[];
}

const modelInfoColumns = [
  {
    Header: 'Architecture',
    accessor: 'architecture',
  },
  {
    Header: 'Framework',
    accessor: 'framework',
  },
  {
    Header: 'Framework Version',
    accessor: 'framework_version',
  },
  {
    Header: 'Language',
    accessor: 'language',
  },
];

const RowSubComponent = ({ row }: { row: any }) => {
  const historyObj = row.original as THistory;
  const dataSets = historyObj.inputs.filter(i => i.type === 'dataset');
  const models = historyObj.inputs.filter(i => i.type === 'model');
  const [datasetsSelectedColumn, setDatasetsSelectedColumn] = useState(
    dataSets.map(ds => {
      return Object.keys(ds.metadata.schema)[0];
    }),
  );

  return (
    <VStack
      pr={1}
      pl={10}
      pb={0}
      w={'full'}
      alignItems={'flex-start'}
      borderBottom={'1px solid'}
      borderBottomColor={useColorModeValue('neutral.200', 'neutral.800')}
    >
      <Tabs size="sm" colorScheme="brand" w={'full'} mt={2}>
        {models.length > 0 && dataSets.length > 0 && (
          <TabList>
            {models.length > 0 && <Tab>Model Information</Tab>}
            {dataSets.length > 0 && <Tab>Dataset Information</Tab>}
          </TabList>
        )}

        <TabPanels>
          {models.length > 0 && (
            <TabPanel>
              <VStack alignItems={'flex-start'} gap={10}>
                {models.map(model => {
                  return (
                    <VStack w={'full'} alignItems={'flex-start'} gap={2}>
                      <Heading size="sm" fontFamily={'mono'}>
                        {model.name}
                      </Heading>
                      <DataTable
                        columns={modelInfoColumns}
                        data={[model.metadata]}
                        isInteractive={false}
                        enableSort={false}
                        getHeaderProps={(column: any) => {
                          return {
                            style: {
                              textTransform: 'uppercase',
                              padding: 'var(--chakra-space-2)',
                              fontSize: 'var(--chakra-fontSizes-xs)',
                            },
                          };
                        }}
                        getCellProps={(cellInfo: any) => {
                          return {
                            style: {
                              padding: 'var(--chakra-space-2)',
                              fontSize: 'var(--chakra-fontSizes-sm)',
                              fontFamily: 'var(--chakra-fonts-mono)',
                            },
                          };
                        }}
                      />
                    </VStack>
                  );
                })}
              </VStack>
            </TabPanel>
          )}
          {dataSets.length && (
            <TabPanel>
              {dataSets.map((ds, dsIndex) => {
                const dataSetIndeces = Object.keys(ds.metadata.schema);
                const dataSetHeaders = ds.metadata.description
                  .filter((desc: any) => {
                    return desc[datasetsSelectedColumn[dsIndex]] !== null;
                  })
                  .map((row: any) => {
                    return {
                      Header: row.index,
                      accessor: row.index,
                    };
                  });
                const attributeSelectorColumn = {
                  Header: `Attributes (${dataSetIndeces.length.toString()} Total)`,
                  accessor: 'index',
                  Cell: ({ value }: any) => {
                    return (
                      <FormControl w={80}>
                        <Select
                          id="attribute"
                          size={'sm'}
                          colorScheme="brand"
                          _focusVisible={{
                            outlineColor: 'brand.50',
                          }}
                          onChange={e => {
                            const newArr = [...datasetsSelectedColumn];
                            newArr[dsIndex] = e.target.value;
                            setDatasetsSelectedColumn(newArr);
                          }}
                          value={datasetsSelectedColumn[dsIndex]}
                          fontFamily={'body'}
                          fontSize={'sm'}
                        >
                          {dataSetIndeces.map(header => {
                            return (
                              <option value={header}>
                                {header} (
                                {ds.metadata.schema[header] === 'int64'
                                  ? 'Numerical'
                                  : 'Categorical'}
                                )
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    );
                  },
                };
                dataSetHeaders.unshift(attributeSelectorColumn);
                const getDataSetInfo = (field: string) => {
                  const data = ds.metadata.description.map((row: any) => {
                    const rowIndex = row.index;
                    return {
                      [rowIndex]: row[field],
                    };
                  });
                  const singleObject = Object.assign({}, ...data);
                  return [singleObject];
                };
                return (
                  <VStack
                    alignItems={'flex-start'}
                    mb={2}
                    mt={6}
                    _first={{
                      marginTop: 0,
                    }}
                  >
                    <VStack w={'full'} alignItems={'flex-start'} gap={2}>
                      <Heading size="sm" fontFamily={'mono'}>
                        {ds.name}
                      </Heading>
                      <DataTable
                        columns={dataSetHeaders}
                        data={getDataSetInfo(datasetsSelectedColumn[dsIndex])}
                        getHeaderProps={(column: any) => {
                          return {
                            style: {
                              textTransform: 'uppercase',
                              paddingTop: 'var(--chakra-space-2)',
                              paddingBottom: 'var(--chakra-space-2)',
                              paddingLeft: 'var(--chakra-space-2)',
                              fontSize: 'var(--chakra-fontSizes-xs)',
                            },
                          };
                        }}
                        getCellProps={(cellInfo: any) => {
                          return {
                            style: {
                              padding: 'var(--chakra-space-1)',
                              paddingLeft: 'var(--chakra-space-2)',
                              fontSize: 'var(--chakra-fontSizes-sm)',
                              fontFamily: 'var(--chakra-fonts-mono)',
                            },
                          };
                        }}
                      ></DataTable>
                    </VStack>
                  </VStack>
                );
              })}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default function InputInfoModal({
  createdAt,
  inputs,
  user,
  contentID,
  title,
  getHistoryForPage,
  contentType,
  hideLabel,
}: InputInfoModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [history, setHistory] = useState<THistory[]>([]);
  const [sortedBy, setSortedBy] = useState<{ id: string; desc: boolean }[]>([
    { id: 'created_at', desc: true },
  ]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filterToReset, setFilterToReset] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<IFilterProps>({
    datasets: [],
    date_range: {
      start_date: null,
      end_date: null,
    },
    models: [],
    ran_by: [],
  });
  const [filteredHistory, setFilteredHistory] = useState<THistory[]>([]);
  const {
    isFetching: isFetchingHistory,
    refetch: refetchHistory,
    isSuccess: isSuccessHistory,
  } = useQuery(
    ['input', 'history', contentID, page, limit, selectedFilters, sortedBy],
    async () => {
      const filters = {
        datasets: selectedFilters.datasets.length
          ? selectedFilters.datasets.join(',')
          : null,
        start_date: selectedFilters.date_range.start_date! || null,
        end_date: selectedFilters.date_range.end_date! || null,
        models: selectedFilters.models.length
          ? selectedFilters.models.join(',')
          : null,
        ran_by: selectedFilters.ran_by.length
          ? selectedFilters.ran_by.map(user => user.cuid).join(',')
          : null,
      };

      const results = await getHistoryForPage(
        page,
        limit,
        filters,
        sortedBy[0]?.id,
        sortedBy[0]?.desc ? 'desc' : 'asc',
      );
      setHistory(results.history);
      setTotal(results.total);
    },
    {
      enabled: false,
    },
  );

  const { setShowTimelineFn } = useContext(BlockWrapperContext);

  useEffect(() => {
    if (setShowTimelineFn) {
      setShowTimelineFn(() => () => onOpen());
    }
  }, []);

  useEffect(() => {
    refetchHistory();
  }, [page, selectedFilters, sortedBy]);

  const columnsName = {
    created_at: 'Date run',
    user: 'Ran by',
    models: 'Model',
    datasets: 'Dataset',
  } as { [k: string]: string };

  const filterNames = {
    models: 'Model',
    ran_by: 'Ran By',
    date_range: 'Date Run',
    datasets: 'Dataset',
  } as { [k: string]: string };

  const sortType = (desc: boolean) => (desc ? 'Latest first' : 'Oldest first');

  const historyColumns = [
    {
      Header: '',
      accessor: 'index',
      Cell: ({ row }: any) => {
        const historyObject = row.original as THistory;
        const isActive = historyObject.created_at === createdAt;

        if (isActive) {
          return <Tag colorScheme={'green'}>Active</Tag>;
        }
        return null;
      },
    },
    {
      Header: 'Date run',
      accessor: 'created_at',
      Cell: ({ value }: any) => {
        return (
          <Text textAlign={'left'} fontSize={'sm'}>
            {displayFormatedDateAndTime(value)}
          </Text>
        );
      },
    },
    {
      Header: 'Ran by',
      id: 'ran_by',
      accessor: 'user',
      Cell: ({ value }: any) => {
        if (!value) {
          return <></>;
        }
        return (
          <Tag key={value.cuid} size={'md'}>
            <AvatarProxy
              src={value.picture}
              size="xs"
              name={value.name}
              ml={-1}
              mr={2}
            />
            <TagLabel>{value.name}</TagLabel>
          </Tag>
        );
      },
    },
    {
      id: 'models',
      Header: 'Model',
      accessor: 'inputs',
      Cell: ({ value }: { value: TInputResult[] }) => {
        let text = '';
        if (value) {
          text = value
            .filter(i => i.type === 'model')
            .map(i => i.name)
            .join(', ');
        }
        return <Text fontFamily={'monospace'}>{text}</Text>;
      },
    },
    {
      id: 'datasets',
      Header: 'Dataset',
      accessor: 'inputs',
      Cell: ({ value }: { value: TInputResult[] }) => {
        let text = '';
        if (value) {
          text = value
            .filter(i => i.type === 'dataset')
            .map(i => i.name)
            .join(', ');
        }
        return <Text fontFamily={'monospace'}>{text || '-'}</Text>;
      },
    },
    {
      id: 'expander',
      size: 1,
      Header: '',
      Cell: ({ row }: any) => {
        const historyObject = row.original as THistory;
        const hasData = historyObject.inputs.length > 0;
        if (!hasData) {
          return <></>;
        }
        return (
          <Box textAlign={'right'}>
            {row.isExpanded ? (
              <Icon as={ChevronUpIcon} boxSize={6} />
            ) : (
              <Icon as={ChevronDownIcon} boxSize={6} />
            )}
          </Box>
        );
      },
    },
  ];

  const latestDateFormatted = displayFormatedDateAndTime(createdAt);

  const latestDatasets = inputs
    .filter(i => i.type === 'dataset')
    .map(i => i.name);

  const latestModels = inputs.filter(i => i.type === 'model').map(i => i.name);

  const getTagFilters = useCallback(() => {
    const tags = [] as { [k: string]: { label: string; rawKey: string } }[];
    Object.entries(selectedFilters).forEach(([key, value]) => {
      const isValidObject =
        !Array.isArray(value) &&
        typeof value === 'object' &&
        Object.values(value).every(i => !!i);

      if (isValidObject) {
        value = Object.values(value);
      }

      if (value.length > 0 && value.every((v: any) => !!v)) {
        const values = value.map((v: any) => {
          if (typeof v === 'string') {
            return v;
          }

          if (typeof v === 'object' && 'label' in v) {
            return v.label;
          }

          if (typeof v === 'object' && 'name' in v) {
            return v.name;
          }

          if (typeof v === 'number') {
            return displayFormatedDateAndTime(v);
          }

          return v;
        });

        tags.push({
          [filterNames[key]]: {
            label: values.join(', '),
            rawKey: key,
          },
        });
      }
    });
    return tags;
  }, [selectedFilters]);

  const getTagComponents = () => {
    const tags = getTagFilters();

    if (tags.length === 0) {
      return null;
    }

    return tags.map(tag => {
      return (
        <Tag size={'md'}>
          {Object.entries(tag).map(([key, value]) => {
            return (
              <TagLabel>
                <strong>{key}:</strong> {value.label}
              </TagLabel>
            );
          })}
          <TagCloseButton
            onClick={() =>
              setSelectedFilters(prev => {
                const newFilters = { ...prev };
                const currentKey = Object.values(tag)[0].rawKey;

                setFilterToReset(prev => [...prev, currentKey]);

                if (currentKey === 'date_range') {
                  newFilters[currentKey] = {
                    start_date: null,
                    end_date: null,
                  };
                }

                newFilters[
                  currentKey as keyof Omit<IFilterProps, 'date_range'>
                ] = [];
                return newFilters;
              })
            }
            zIndex={4}
          />
        </Tag>
      );
    });
  };

  useEffect(() => {
    setFilteredHistory(history);
  }, [selectedFilters, history]);

  const Tags = getTagComponents();
  return (
    <>
      {!hideLabel && (
        <Stack alignItems={'flex-start'}>
          <Text
            m={4}
            fontSize={'sm'}
            fontWeight={'normal'}
            variant={'ghost'}
            color={'neutral.500'}
            backgroundColor="transparent"
            rounded="md"
            onClick={(e: any) => {
              // onOpen();
              e.stopPropagation();
            }}
            whiteSpace={'break-spaces'}
          >
            <HStack gap={4}>
              <HStack gap={1}>
                <Icon as={CalendarIcon} boxSize={5} />
                <Text>{`${latestDateFormatted} (Latest)`}</Text>
              </HStack>
              {latestDatasets.length > 0 && (
                <HStack gap={1}>
                  <Icon as={CircleStackIcon} boxSize={5} />
                  <Text
                    fontFamily={'monospace'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    maxW={'200px'}
                  >
                    {latestDatasets.join(', ')}
                  </Text>
                </HStack>
              )}
              {latestModels.length > 0 && (
                <HStack gap={1}>
                  <Icon as={CubeIcon} boxSize={5} />
                  <Text
                    fontFamily={'monospace'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    maxW={'200px'}
                  >
                    {latestModels.join(', ')}
                  </Text>
                </HStack>
              )}
              {user && (
                <HStack gap={1}>
                  <Tag key={'id'} size={'md'}>
                    <AvatarProxy
                      src={user.picture}
                      size="xs"
                      name={user.name}
                      ml={-2}
                      mr={2}
                    />
                    <TagLabel>{user.name}</TagLabel>
                  </Tag>
                </HStack>
              )}
            </HStack>
          </Text>
        </Stack>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'6xl'}
        blockScrollOnMount={true}
        isCentered
        motionPreset="slideInBottom"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isFetchingHistory && (
              <Center p={12}>
                <Stack justify={'center'} align={'center'}>
                  <CircularProgress
                    size="40px"
                    thickness="2px"
                    isIndeterminate
                    color="brand.base"
                  />
                  <Text fontSize={'xs'} color={'inherit'}>
                    Please hold...
                  </Text>
                </Stack>
              </Center>
            )}
            {!isFetchingHistory && (
              <Stack gap={4}>
                <VStack alignItems={'flex-start'}>
                  <HStack justifyContent={'flex-end'} w={'full'}>
                    <TestResultsFilter
                      onFilterChange={newFilters => {
                        setSelectedFilters(newFilters);
                      }}
                      filterToReset={filterToReset}
                      setFilterToReset={setFilterToReset}
                      history={history}
                      contentId={contentID}
                      contentType={contentType}
                      currentSelectedFilters={selectedFilters}
                    />
                  </HStack>
                  <HStack>
                    <HStack>
                      <Flex
                        alignItems={'center'}
                        gap={2}
                        whiteSpace={'nowrap'}
                        color={'neutral.500'}
                      >
                        <Icon as={BarsArrowDownIcon} boxSize={5} />
                        <Text fontSize={'sm'} fontWeight={'semibold'}>
                          Sorted by:
                        </Text>

                        <Wrap>
                          <Tag size={'md'}>
                            {
                              <TagLabel>
                                {columnsName[sortedBy[0]?.id] ? (
                                  <>
                                    <strong>
                                      {columnsName[sortedBy[0]?.id]}:{' '}
                                    </strong>
                                    {sortType(sortedBy[0]?.desc)}
                                  </>
                                ) : (
                                  <strong>-</strong>
                                )}
                              </TagLabel>
                            }
                          </Tag>
                        </Wrap>
                      </Flex>
                    </HStack>
                    {Tags && Tags?.length > 0 && (
                      <HStack alignItems={'flex-start'}>
                        <Flex
                          alignItems={'center'}
                          gap={2}
                          color={'neutral.500'}
                          whiteSpace={'nowrap'}
                        >
                          <Icon as={FunnelIcon} width={5} height={5} />
                          <Text fontSize={'sm'} fontWeight={'semibold'}>
                            Filtered by:
                          </Text>
                        </Flex>

                        <Flex>
                          <Wrap>{Tags}</Wrap>
                        </Flex>
                      </HStack>
                    )}
                  </HStack>
                </VStack>
                {!isFetchingHistory &&
                  isSuccessHistory &&
                  filteredHistory.length > 0 && (
                    <Box
                      bg={useColorModeValue(
                        'white',
                        'var(--chakra-colors-neutral-950)',
                      )}
                      rounded={'md'}
                      w={'full'}
                    >
                      <DataTable
                        columns={historyColumns}
                        data={filteredHistory}
                        defaultSortBy={
                          sortedBy.length > 0
                            ? sortedBy
                            : [{ id: 'created_at', desc: true }]
                        }
                        isInteractive={false}
                        enableSort={true}
                        manualSortBy={true}
                        onSort={column => {
                          // Only allow desc/asc for created_at instead of a 3rd "unsorted" option.
                          // The unsorted option defaults to created_at, descending. We know we need
                          // to set it to created_at, ascending when `column` is empty and the current
                          // `sortedBy` is ['created_at', true].
                          //
                          // This is a weird behavior in DataTable we need to investigate further.
                          if (
                            column.length === 0 &&
                            sortedBy.length > 0 &&
                            sortedBy[0].id === 'created_at' &&
                            sortedBy[0].desc
                          ) {
                            column = [{ id: 'created_at', desc: false }];
                          }
                          setSortedBy(column);
                        }}
                        onClickRow={(row: any) => {
                          const historyObject = row.original as THistory;
                          const hasData = historyObject.inputs.length > 0;
                          if (hasData) {
                            row.toggleRowExpanded();
                          }
                        }}
                        getRowProps={(row: any) => {
                          const defaultBgColor =
                            row.index % 2 === 0
                              ? useColorModeValue(
                                  'var(--chakra-colors-neutral-25)',
                                  'var(--chakra-colors-neutral-800)',
                                )
                              : 'transparent';

                          return {
                            style: {
                              background: defaultBgColor,
                              borderTop: row.isExpanded
                                ? `1px solid ${useColorModeValue(
                                    'var(--chakra-colors-neutral-200)',
                                    'var(--chakra-colors-neutral-800)',
                                  )}`
                                : 'none',
                              cursor: 'pointer',
                              _hover: {
                                background: 'red !important',
                              },
                            },
                          };
                        }}
                        renderRowSubComponent={(props: any) => (
                          <RowSubComponent {...props} />
                        )}
                      />
                    </Box>
                  )}
                {history.length > 0 && (
                  <Center mt={5}>
                    <HStack>
                      <Button
                        size={'sm'}
                        variant={'ghost'}
                        leftIcon={<Icon as={ArrowLongLeftIcon} boxSize={5} />}
                        onClick={() => setPage(page - 1)}
                        visibility={page === 1 ? 'hidden' : 'visible'}
                        disabled={page === 1}
                      >
                        Prev
                      </Button>
                      <Text fontSize={'sm'}>
                        Showing {(page - 1) * limit + 1} to{' '}
                        {Math.min(page * limit, total)} of {total}
                      </Text>
                      <Button
                        size={'sm'}
                        variant={'ghost'}
                        rightIcon={<Icon as={ArrowLongRightIcon} boxSize={5} />}
                        onClick={() => {
                          setPage(page + 1);
                        }}
                        visibility={
                          total / limit <= page ? 'hidden' : 'visible'
                        }
                        disabled={total / limit <= page}
                      >
                        Next
                      </Button>
                    </HStack>
                  </Center>
                )}
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <HStack w={'full'} justifyContent={'start'}>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
