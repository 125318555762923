import { useQuery } from 'react-query';
import API from '../../api/API';
import { schemaPropertiesToSchemaPropertyItems } from '../../utils';
import { SchemaPropertyItem } from '../../models/json_schemas';

const useModelSchema = (onSuccess?: (data: SchemaPropertyItem[]) => void) => {
  const { data, refetch, isLoading } = useQuery(
    ['organizations', 'custom-fields'],
    async () => {
      const resp = await API.GetInventoryModelSchema();
      const schemaPropertyItems = schemaPropertiesToSchemaPropertyItems(resp);
      onSuccess?.(schemaPropertyItems);
      return resp;
    },
    {
      staleTime: 1000 * 60 * 60,
      placeholderData: {
        schema: {
          type: 'object',
          properties: {},
          required: [],
        },
        settings: {
          properties: {},
        },
      },
    },
  );

  const propertyItems = data
    ? schemaPropertiesToSchemaPropertyItems(data).sort((a, b) =>
        a.key.localeCompare(b.key),
      )
    : [];

  return { data, propertyItems, refetch, isLoading };
};

export default useModelSchema;
