import { useState } from 'react';
import {
  Button,
  Box,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/solid';

interface MenuItem {
  icon: React.ReactElement;
  label: string;
  onClick: () => void;
}

interface FloatingActionButtonProps {
  menuItems: MenuItem[];
}

const FloatingActionButton = ({ menuItems }: FloatingActionButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(prev => !prev);

  return (
    <Box>
      <Menu
        onOpen={() => {
          toggleMenu();
        }}
        onClose={() => {
          toggleMenu();
        }}
      >
        <MenuButton
          as={Button}
          variant={'ghost'}
          leftIcon={<Icon as={PlusIcon} boxSize={5} />}
        >
          Add Widget
        </MenuButton>
        <MenuList>
          {menuItems.map((item, index) => (
            <MenuItem
              fontSize={'lg'}
              py={2}
              px={4}
              _hover={{
                background: useColorModeValue(
                  'brandSecondary.25',
                  'brandSecondary.950',
                ),
              }}
              icon={item.icon}
              onClick={() => {
                item.onClick();
                toggleMenu();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default FloatingActionButton;
