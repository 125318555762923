import { useQuery } from 'react-query';
import API from '../../api/API';

export default function useOrganizationAssessmentOptions() {
  const queryResponse = useQuery(
    ['organization', 'assessment-options'],
    async () => {
      return API.GetOrganizationAssessmentOptions();
    },
  );

  return { assessmentOptions: queryResponse.data, ...queryResponse };
}
