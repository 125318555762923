import { useState } from 'react';
import { Stack, Box, Icon, Button } from '@chakra-ui/react';
import { OfflineDocumentationFile } from '../../models/template';
import API from '../../api/API';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';

interface Props {
  files: OfflineDocumentationFile[];
}

const DownloadOfflineDocument = ({ files }: Props) => {
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async (file: OfflineDocumentationFile) => {
    try {
      setDownloading(true);
      const blob = await API.DownloadOfflineDocument(file);

      // Create a link to the file
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', file.name);

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up and remove the link
      link.parentNode!.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Stack>
      {files.map((item, index) => (
        <Box key={index}>
          <Button
            isLoading={downloading}
            leftIcon={<Icon as={DocumentArrowDownIcon} boxSize={5} />}
            onClick={() => downloadFile(item)}
          >
            Download Document
          </Button>
        </Box>
      ))}
    </Stack>
  );
};

export default DownloadOfflineDocument;
