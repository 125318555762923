import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  HStack,
  Icon,
  Spacer,
  useColorModeValue,
  // useToast,
} from '@chakra-ui/react';
import {
  TrashIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import API from '../../../api/API';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import { TGroup } from '../../../models';

interface GroupMemberEditorProps {
  group: TGroup;
  membersToRemove: string[];
  onOpenAddMember: () => void;
  onDeleteGroup: () => void;
}

export default function GroupMemberEditor({
  group,
  membersToRemove,
  onOpenAddMember,
  onDeleteGroup,
}: GroupMemberEditorProps) {
  const queryClient = useQueryClient();
  const [showRemoveMembersConfirmation, setShowRemoveMembersConfirmation] =
    useState(false);

  const { data: groupMembers = [] } = useQuery(
    ['group-members', group.cuid],
    async () => {
      return API.GetGroupUsers(group.cuid);
    },
  );

  const removeMemberMutation = useMutation(
    [],
    async ({
      groupId,
      membersToRemove,
    }: {
      groupId: string;
      membersToRemove: string[];
    }) => {
      await API.RemoveUsersFromGroup(groupId, membersToRemove);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups-members');
        void queryClient.invalidateQueries(['group-members', group.cuid]);
      },
    },
  );

  const handleRemoveMember = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      removeMemberMutation.mutate({ groupId: group.cuid, membersToRemove });
    }
    setShowRemoveMembersConfirmation(false);
  };

  return (
    <>
      <HStack pt={4}>
        {membersToRemove.length > 0 && (
          <Button
            isDisabled={false}
            variant={'ghost'}
            leftIcon={<Icon as={UserMinusIcon} boxSize={5} />}
            onClick={() => setShowRemoveMembersConfirmation(true)}
            data-testid="remove-member"
            _hover={{
              color: useColorModeValue('red.600', 'red.300'),
              bg: useColorModeValue('red.50 !important', 'red.900 !important'),
            }}
          >
            {`Remove Member ${membersToRemove.length > 1 ? 's' : ''}`}
          </Button>
        )}
        <Button
          leftIcon={<Icon as={UserPlusIcon} boxSize={5} />}
          onClick={onOpenAddMember}
          data-testid="add-new-member"
          variant={'ghost'}
        >
          Add New Member
        </Button>
        <Spacer />
        {!group.is_default && (
          <Button
            isDisabled={showRemoveMembersConfirmation}
            variant="ghost"
            leftIcon={<Icon as={TrashIcon} boxSize={5} />}
            // onClick={() => setShowDeleteGroupConfirmation(true)}
            onClick={onDeleteGroup}
            data-testid="delete-group"
            _hover={{
              color: useColorModeValue('red.600', 'red.300'),
              bg: useColorModeValue('red.50 !important', 'red.900 !important'),
            }}
          >
            Delete Group
          </Button>
        )}
      </HStack>
      <ConfirmationAlert
        open={showRemoveMembersConfirmation}
        title="Remove Member"
        dialogBody={`${membersToRemove.length} member${
          membersToRemove.length > 1 ? 's' : ''
        } will be removed from the "${
          group.name
        }" group and lose access to the assigned models. Would you like to continue?`}
        cancelButton={<Button variant="ghost">Cancel</Button>}
        confirmButton={
          <Button
            leftIcon={<Icon as={UserMinusIcon} strokeWidth={2.5} />}
            _hover={{
              color: 'red.600',
              bg: 'red.100',
            }}
          >
            {`Remove ${membersToRemove.length} Member${
              membersToRemove.length > 1 ? 's' : ''
            }`}
          </Button>
        }
        onConfirm={handleRemoveMember}
        size="3xl"
      />
    </>
  );
}
