import { Route, Routes, Navigate } from 'react-router-dom';
import { LoadingContainer } from '../components/LoadingContainer';
import Organizations from './pages/Organizations';
import NotFound from './pages/NotFound';
import { useState, useEffect } from 'react';
import OrganizationDefaults from './pages/OrganizationDefaults';
import Users from './pages/Users';
import Tools from './pages/Tools';
import { AuthPrivateAdminRoute } from '../components/PrivateRoute';
import { useAuth } from 'react-oidc-context';
import { useCurrentAdminUser } from '../hooks/admin';
import { setTokenRetrievalMethod } from '../api/AdminAPI';
import ViewOrganization from './pages/ViewOrganization';
import ViewUser from './pages/ViewUser';

function AdminApp() {
  const {
    isLoading: isUserLoading,
    isAuthenticated,
    user,
    signinRedirect,
  } = useAuth();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isTokenMethodSet, setIsTokenMethodSet] = useState(false);

  useEffect(() => {
    if (!isUserLoading && isAuthenticated) {
      setTokenRetrievalMethod(() => {
        return user?.access_token;
      });
      setIsTokenMethodSet(true);
    }
  }, [user, isAuthenticated, isUserLoading]);

  const { data: currentAdminUser, isLoading: isAdminUserLoading } =
    useCurrentAdminUser(isTokenMethodSet);

  useEffect(() => {
    if (!isUserLoading && !isAdminUserLoading && isAuthenticated) {
      const hasAdminAccess =
        currentAdminUser?.role !== null && currentAdminUser?.role !== undefined;

      setIsAuthorized(hasAdminAccess);
    } else if (!isUserLoading && !isAdminUserLoading && !isAuthenticated) {
      signinRedirect({
        state: { returnTo: window.location.pathname },
      });
    }
  }, [
    user,
    isAuthenticated,
    isUserLoading,
    isAdminUserLoading,
    currentAdminUser,
    isTokenMethodSet,
  ]);

  if (!isAuthenticated && isAdminUserLoading) {
    return (
      <LoadingContainer isLoading={true}>
        <></>
      </LoadingContainer>
    );
  }

  // Show a 404 when user is authenticated but not an admin
  if (!isAuthorized) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route element={<AuthPrivateAdminRoute />}>
        {/* <Route path="/" element={<Home />} /> */}
        {/* TODO: remove this once we have a home page */}
        <Route
          path="/"
          element={<Navigate to="/admin/organizations" replace />}
        />
        <Route path="/organizations" element={<Organizations />} />
        <Route path="/organizations/:cuid" element={<ViewOrganization />} />
        <Route
          path="/organization-defaults"
          element={<OrganizationDefaults />}
        />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:cuid" element={<ViewUser />} />
        <Route path="/tools" element={<Tools />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AdminApp;
