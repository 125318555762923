import {
  CommentsAdapterCommentAdded,
  CommentsAdapterCommentRemoved,
  CommentsAdapterCommentUpdated,
  CommentsAdapterThreadAdded,
  CommentsAdapterThreadGet,
  CommentsAdapterThreadRemoved,
  CommentsAdapterThreadReopen,
  CommentsAdapterThreadResolved,
} from '../../models/ckeditor';
import API, { TInventoryModelAnnotationCommentCKEditor } from '../../api/API';
import { ActivityFeedWidgetQueryKey } from '../ActivityFeedWidget';
import { COMMENT_ADDED_DOCUMENT_TYPE } from './CKEditorWrapper';

export class CommentsAdapter {
  private editor: any;
  constructor(editor: any) {
    this.editor = editor;
  }

  static get requires() {
    return ['CommentsRepository', 'Users'];
  }

  async init() {
    const annotationCommentsWidgetQueryKey = 'AnnotationCommentsWidget';
    // console.log('CommentsAdapter init', this.editor);

    // Revision history integration is trying to create a different instance of the adapter
    // and it won't bring the injected context values, return here when that happens.
    if (!this.editor.config._config.extraContext) {
      return;
    }

    const { inventoryModel, metadata, toast, queryClient } =
      this.editor.config._config.extraContext;

    if (!metadata) {
      return;
    }

    const comments = this.editor.plugins.get('CommentsRepository');

    // Set the adapter on the `CommentsRepository#adapter` property.
    comments.adapter = {
      async addCommentThread(thread: CommentsAdapterThreadAdded) {
        // Post the new annotation to the server, together with the first comment.

        // temporally store the document type in the local storage so the post metadata endpoint knows
        // where this comment is added. useful for permissions.
        localStorage.setItem(
          COMMENT_ADDED_DOCUMENT_TYPE,
          metadata.content_type,
        );

        try {
          let newThread = await API.PostAnnotation(
            inventoryModel,
            metadata,
            thread,
          );

          // parse the dates
          newThread = {
            ...newThread,
            createdAt: new Date(
              parseFloat(newThread.createdAt as string) * 1000,
            ),
            comments: newThread.comments.map(
              (c: TInventoryModelAnnotationCommentCKEditor) => ({
                ...c,
                createdAt: new Date(parseFloat(c.createdAt as string) * 1000),
              }),
            ),
          };

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });

          return Promise.resolve(newThread);
        } catch (error) {
          // remove the thread comment from ui
          const t = comments.getCommentThread(thread.threadId);
          t.remove();

          // display the error
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not save the comment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });

          return Promise.reject();
        }
      },

      async getCommentThread(data: CommentsAdapterThreadGet) {
        // console.log('CommentsAdapterThreadGet', data);

        // Searches the <comment-start name="thread-id-1"> element on the content and
        // returns the thread ID for each one. This function is called per each thread.

        // Get the thread from the API:
        try {
          let thread = await API.GetAnnotation(inventoryModel, data.threadId);

          // console.log('- thread ->', thread);

          thread = {
            ...thread,
            createdAt: new Date(parseFloat(thread.createdAt as string) * 1000),
            comments: thread.comments.map(
              (c: TInventoryModelAnnotationCommentCKEditor) => ({
                ...c,
                createdAt: new Date(parseFloat(c.createdAt as string) * 1000),
              }),
            ),
            resolvedAt: thread.resolvedAt
              ? new Date(parseFloat(thread.resolvedAt as string) * 1000)
              : null,
          };

          return Promise.resolve(thread);
        } catch (error) {
          // display the error once when multiples threads are being loaded with failure
          const toastId = 'getCommentThreadError';
          if (!toast.isActive(toastId)) {
            toast({
              id: toastId,
              position: 'bottom-right',
              duration: 3000,
              isClosable: true,
              title: 'Could not retrieve the comments at this moment',
              description: API.getAPIErrorMessage(error),
              status: 'warning',
            });
          }
          return Promise.reject();
        }
      },

      async addComment(data: CommentsAdapterCommentAdded) {
        // console.log('CommentsAdapterCommentAdded', data);

        const { threadId, commentId, content, attributes } = data;

        // Post the comment on the API.
        try {
          let comment = await API.PostComment(
            inventoryModel,
            threadId,
            content,
            commentId,
          );
          comment = {
            ...comment,
            createdAt: new Date(parseFloat(comment.createdAt as string) * 1000),
          };

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });

          return comment;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not add the comment at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });
          return Promise.reject();
        }
      },

      async updateComment(data: CommentsAdapterCommentUpdated) {
        // console.log('CommentsAdapterCommentUpdated', data);

        const { threadId, commentId, content } = data;
        try {
          let comment = await API.PatchComment(
            inventoryModel,
            threadId,
            commentId,
            content,
          );
          comment = {
            ...comment,
            createdAt: new Date(parseFloat(comment.createdAt as string) * 1000),
          };
          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });
          return comment;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not update the comment at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });
          return Promise.reject();
        }
      },

      async removeComment(data: CommentsAdapterCommentRemoved) {
        // console.log('CommentsAdapterCommentRemoved', data);

        // Delete the comment on the API.
        const { threadId, commentId } = data;
        try {
          const removal = await API.DeleteComment(
            inventoryModel,
            threadId,
            commentId,
          );

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });

          return removal;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not remove the comment at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });
          return Promise.reject();
        }
      },

      updateCommentThread(data: any) {
        // Not sure when this is called.
        // console.log('CommentsAdapterThreadGetUpdated', JSON.stringify(data));
        return Promise.resolve();
      },

      async resolveCommentThread(data: CommentsAdapterThreadResolved) {
        // console.log('CommentsAdapterThreadResolved', data);

        // Patch the thread on the API with the resolved flag in true and the user who resolved.
        const { threadId } = data;
        try {
          const resolvedAnnotation = await API.ResolveAnnotation(
            inventoryModel,
            threadId,
            true,
          );

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });

          return resolvedAnnotation;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not mark the comment as resolved at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });
          return Promise.reject();
        }
      },

      async reopenCommentThread(data: CommentsAdapterThreadReopen) {
        // console.log('CommentsAdapterThreadReopen', data);

        // Patch the thread on the API with the resolved flag in false, user who resolved should be null.
        const { threadId } = data;
        try {
          const reopenedAnnotation = await API.ResolveAnnotation(
            inventoryModel,
            threadId,
            false,
          );

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });

          return reopenedAnnotation;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not reopen the comment at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });
          return Promise.reject();
        }
      },

      async removeCommentThread(data: CommentsAdapterThreadRemoved) {
        // console.log('CommentsAdapterThreadRemoved', data);

        // Deleted the thread on the API.
        const { threadId } = data;
        try {
          const removal = await API.DeleteAnnotation(inventoryModel, threadId);
          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });
          queryClient.invalidateQueries({
            queryKey: [annotationCommentsWidgetQueryKey],
          });
          return removal;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not remove the thread at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'error',
          });
          return Promise.reject();
        }
      },
    };
  }
}
