import React from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingContainer } from '../LoadingContainer';
import AppSidebarLayout from '../Layout/AppSidebarLayout';
import { SettingsSidebar } from '../SettingsSidebar';
import AdminLayout from '../Layout/AdminLayout';
import { withAuthenticationRequired } from 'react-oidc-context';

const DashboardLayout = () => {
  return (
    <AppSidebarLayout>
      <SettingsSidebar />
      <PrivateRoute />
    </AppSidebarLayout>
  );
};

const AdminDashboardLayout = () => {
  return (
    <AdminLayout>
      <PrivateRoute />
    </AdminLayout>
  );
};

export const PrivateRoute = () => <Outlet />;

const AuthPrivateRoute = React.memo(
  withAuthenticationRequired(DashboardLayout, {
    OnRedirecting: () => (
      <LoadingContainer isLoading={true}>Loading...</LoadingContainer>
    ),
  }),
);

// Update with an admin layout when it's implemented
const AuthPrivateAdminRoute = React.memo(
  withAuthenticationRequired(AdminDashboardLayout, {
    OnRedirecting: () => (
      <LoadingContainer isLoading={true}>Loading...</LoadingContainer>
    ),
  }),
);

export { AuthPrivateRoute, AuthPrivateAdminRoute };
