import {
  Box,
  Heading,
  Text,
  VStack,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Spinner,
  SimpleGrid,
  Badge,
  Flex,
  Divider,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganizations } from '../../hooks/admin';
import { displayFormatedDateAndTime } from '../../utils';
import { EditOrganizationModal } from '../components/EditOrganizationModal';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/react';

// Helper component for displaying field pairs
const FieldPair = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <Box>
    <Text fontWeight="bold" color="gray.500" mb={1}>
      {label}
    </Text>
    <Text>{value}</Text>
  </Box>
);

function ViewOrganization() {
  const { cuid } = useParams();
  const { data: organizations, isLoading } = useOrganizations();
  const cardBg = useColorModeValue('white', 'gray.800');
  const editModalDisclosure = useDisclosure();

  const organization = organizations?.find(org => org.cuid === cuid);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" h="400px">
        <Spinner size="xl" color="brand.500" />
      </Box>
    );
  }

  if (!organization) {
    return (
      <Box p={8}>
        <Text>Organization not found</Text>
      </Box>
    );
  }

  return (
    <Box>
      <VStack spacing={8} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading size="lg">{organization.name}</Heading>
          <Button
            colorScheme="brand"
            onClick={editModalDisclosure.onOpen}
            leftIcon={<Icon as={PencilIcon} w={4} h={4} />}
          >
            Edit Organization
          </Button>
        </Flex>

        <Card bg={cardBg} shadow="md">
          <CardHeader>
            <Heading size="md">Organization Details</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={6} align="stretch" divider={<Divider />}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                <FieldPair
                  label="Organization Name"
                  value={organization.name}
                />
                <FieldPair label="Organization ID" value={organization.cuid} />
                <FieldPair
                  label="Created At"
                  value={displayFormatedDateAndTime(organization.created_at)}
                />
                <FieldPair
                  label="Last Updated"
                  value={displayFormatedDateAndTime(organization.updated_at)}
                />
              </SimpleGrid>
            </VStack>
          </CardBody>
        </Card>

        <Card bg={cardBg} shadow="md">
          <CardHeader>
            <Heading size="md">Roles</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4} align="stretch">
              {organization.roles.map(role => (
                <Box key={role.cuid} p={4} borderWidth="1px" borderRadius="md">
                  <Flex justify="space-between" align="center" mb={2}>
                    <Heading size="sm">{role.name}</Heading>
                    <HStack spacing={2}>
                      {role.is_admin && (
                        <Badge colorScheme="purple">Admin</Badge>
                      )}
                      {role.is_staff && <Badge colorScheme="blue">Staff</Badge>}
                      <Badge colorScheme="gray">
                        {role.scope
                          ? `${role.scope} Role`
                          : 'Organization Role'}
                      </Badge>
                    </HStack>
                  </Flex>
                  <Text fontSize="sm" color="gray.600">
                    {role.description}
                  </Text>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
                    <FieldPair label="Role ID" value={role.cuid} />
                    <FieldPair
                      label="Created At"
                      value={displayFormatedDateAndTime(role.created_at)}
                    />
                  </SimpleGrid>
                </Box>
              ))}
            </VStack>
          </CardBody>
        </Card>

        {/* OIDC Role Mappings Card */}
        <Card bg={cardBg} shadow="md">
          <CardHeader>
            <Heading size="md">OIDC Role Mappings</Heading>
          </CardHeader>
          <CardBody>
            {organization.oidc_mappings?.role_mappings &&
            Object.keys(organization.oidc_mappings.role_mappings).length > 0 ? (
              <VStack spacing={4} align="stretch">
                {Object.entries(organization.oidc_mappings.role_mappings).map(
                  ([group, roleCuid]) => (
                    <Box key={group} p={4} borderWidth="1px" borderRadius="md">
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                        <Box>
                          <Text fontWeight="medium" color="gray.500" mb={1}>
                            External Group
                          </Text>
                          <Text>{group}</Text>
                        </Box>
                        <Box>
                          <Text fontWeight="medium" color="gray.500" mb={1}>
                            ValidMind Role
                          </Text>
                          <Badge colorScheme="blue">
                            {organization.roles.find(r => r.cuid === roleCuid)
                              ?.name || roleCuid}
                          </Badge>
                        </Box>
                      </SimpleGrid>
                    </Box>
                  ),
                )}
              </VStack>
            ) : (
              <Text color="gray.500">No OIDC role mappings configured</Text>
            )}
          </CardBody>
        </Card>
      </VStack>
      <EditOrganizationModal
        isOpen={editModalDisclosure.isOpen}
        onClose={editModalDisclosure.onClose}
        organization={organization}
      />
    </Box>
  );
}

export default ViewOrganization;
