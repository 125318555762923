import { useQuery } from 'react-query';
import API from '../../api/API';
import { ApprovalVoterStatus } from '../../models/approval';

const useApprovals = (
  voterStatus: ApprovalVoterStatus | null,
  approvalStatus = 'pending',
  targetType?: string,
  targetCuid?: string,
) => {
  const query = useQuery(
    [
      'approvals',
      'voters',
      voterStatus,
      approvalStatus,
      targetType,
      targetCuid,
    ],
    async () => {
      return API.GetApprovalVoters(
        voterStatus,
        approvalStatus,
        targetType,
        targetCuid,
      );
    },
  );
  return query;
};

export default useApprovals;
