import React, { useState } from 'react';
import {
  Box,
  Input,
  Button,
  Center,
  Text,
  Grid,
  GridItem,
  VStack,
  Stack,
  FormControl,
  Link,
  FormErrorMessage,
} from '@chakra-ui/react';

import { Navigate, useNavigate } from 'react-router-dom';
import API from '../../api/API';
import { Copyright } from '../../components/Copyright';
import ProductShots from '../../components/ProductShots';
import ValidMindLogo from '../../components/ValidMindLogo';
import { Label } from '../../components/Layout';
import { useAuth } from 'react-oidc-context';

const SingleSignOn = () => {
  const { signinRedirect, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const [inputValue, setInputValue] = useState('');

  const [resultMessage, setResultMessage] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setResultMessage('');
  };

  const handleSubmit = async () => {
    try {
      const responseData = await API.GetOrganizationConnection(inputValue);

      if (responseData && responseData.connection) {
        // TODO: SSO flow needs to be re-implemented
        signinRedirect({
          // connection: responseData.connection,
        });
      } else {
        setResultMessage('Organization not found');
      }

      setInputValue('');
    } catch (error) {
      console.error('error with get request to sso endpoint: ' + error);
    }

    return;
  };

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(8, 1fr)' }}
      height={{ xl: '100vh' }}
      bg={'neutral.50'}
      color={'neutral.800'}
    >
      <GridItem colSpan={3}>
        <VStack height={'full'} justifyContent={'space-between'}>
          <Center height={'full'} w={'full'}>
            <VStack
              px={16}
              pt={16}
              alignItems={'self-start'}
              gap={8}
              w={'full'}
            >
              <Box color={'brand.base'}>
                <ValidMindLogo />
              </Box>
              <Stack>
                <Text fontSize={'md'} fontWeight={'semibold'}>
                  Single Sign-On (SSO)
                </Text>
                <Text maxWidth={'prose'}>
                  Please enter your team's domain to get access to ValidMind.
                </Text>
              </Stack>
              <VStack w={'full'}>
                <Stack w={'full'}>
                  <FormControl
                    pt={2}
                    isRequired
                    isInvalid={resultMessage !== ''}
                  >
                    <Label>Team's Domain:</Label>
                    <Input
                      type="text"
                      bg={'white !important'}
                      borderColor={'neutral.400 !important'}
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                      placeholder="acme.com"
                      colorScheme="brand.base"
                      focusBorderColor="pink.600"
                      _focus={{
                        borderColor: 'pink.600 !important',
                      }}
                    />
                    {resultMessage && (
                      <FormErrorMessage>
                        We were not able to find an organization with that
                        domain. <br />
                        Please try again and watch for typos.
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Stack>
                <Stack w={'full'}>
                  <Button
                    onClick={handleSubmit}
                    bg={'pink.600 !important'}
                    color={'neutral.50 !important'}
                    _hover={{
                      bg: 'pink.500 !important',
                    }}
                    isDisabled={inputValue === ''}
                    w={'full'}
                  >
                    Continue
                  </Button>
                  <Center w={'full'}>
                    <Link onClick={() => navigate('/')} color={'pink.600'}>
                      Sign in without SSO
                    </Link>
                  </Center>
                </Stack>
              </VStack>
            </VStack>
          </Center>
          <Box w={'full'}>
            <Center>
              <Copyright mt={0} mb={4} />
            </Center>
          </Box>
        </VStack>
      </GridItem>
      <GridItem
        colSpan={5}
        bg={
          'radial-gradient(90.44% 90.44% at 0% 43.78%, #DF2780 0%, #8A144D 100%)'
        }
        scrollBehavior={'smooth'}
        pos={'relative'}
      >
        <ProductShots />
      </GridItem>
    </Grid>
  );
};

export default SingleSignOn;
