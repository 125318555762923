import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Heading,
  Icon,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  VStack,
  FormControl,
  Switch,
  HStack,
  FormLabel,
} from '@chakra-ui/react';
import {
  ClockIcon,
  Cog6ToothIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { BlockWrapperContext } from '../Templates/BlockWrapper';

interface BlockToolBarProps {
  isHovered: boolean;
  setIsHovered: (isHovered: boolean) => void;
  setIsFocused: (isFocused: boolean) => void;
  setConfirmDelete: (confirmDelete: boolean) => void;
  handleConfigChange: (key: string, value: any) => void;
  readOnly: boolean;
}

export const BlockToolBar: React.FC<BlockToolBarProps> = ({
  isHovered,
  setIsHovered,
  setIsFocused,
  setConfirmDelete,
  handleConfigChange,
  readOnly,
}) => {
  const { config, configOptions, showTimelineFn, focusEditorFn } =
    React.useContext(BlockWrapperContext);

  return (
    <Collapse
      in={isHovered}
      animateOpacity
      transition={{ exit: { delay: 0.05 }, enter: { duration: 0.2 } }}
    >
      <Box position={'absolute'} right={4} top={-4}>
        <ButtonGroup
          size="sm"
          isAttached
          variant="outline"
          position={'relative'}
          bg={'white'}
          rounded={'lg'}
          shadow={'base'}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {!!showTimelineFn && (
            <Button
              leftIcon={<Icon as={ClockIcon} boxSize={4} />}
              onClick={showTimelineFn}
            >
              See Timeline
            </Button>
          )}
          {!readOnly && !!focusEditorFn && !config?.hideText && (
            <Button
              leftIcon={
                <Icon
                  as={PencilIcon}
                  boxSize={4}
                  onClick={() => {
                    if (readOnly) return;
                    setIsFocused(true);
                  }}
                />
              }
              onClick={focusEditorFn}
            >
              Edit
            </Button>
          )}
          {!readOnly && configOptions && (
            <Popover closeOnBlur>
              <PopoverTrigger>
                <Button leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}>
                  Configure
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent shadow={'md'}>
                  <PopoverArrow />
                  <PopoverHeader>
                    <Heading as={'h4'} size={'sm'}>
                      Configure Display
                    </Heading>
                  </PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <VStack>
                      {configOptions.title && (
                        <FormControl>
                          <HStack>
                            <Switch
                              size={'sm'}
                              colorScheme="brand"
                              id="display-title"
                              isChecked={config?.hideTitle}
                              onChange={() => {
                                handleConfigChange(
                                  'hideTitle',
                                  !config?.hideTitle,
                                );
                              }}
                            />
                            <FormLabel htmlFor="display-title" mb={0}>
                              Hide Title
                            </FormLabel>
                          </HStack>
                        </FormControl>
                      )}
                      {configOptions.text && (
                        <FormControl>
                          <HStack>
                            <Switch
                              size={'sm'}
                              colorScheme="brand"
                              id="display-text"
                              isChecked={config?.hideText}
                              onChange={() => {
                                handleConfigChange(
                                  'hideText',
                                  !config?.hideText,
                                );
                              }}
                            />
                            <FormLabel htmlFor="display-text" mb={0}>
                              Hide Text
                            </FormLabel>
                          </HStack>
                        </FormControl>
                      )}
                      {configOptions.params && (
                        <FormControl>
                          <HStack>
                            <Switch
                              size={'sm'}
                              colorScheme="brand"
                              id="display-params"
                              isChecked={config?.hideParams}
                              onChange={() => {
                                handleConfigChange(
                                  'hideParams',
                                  !config?.hideParams,
                                );
                              }}
                            />
                            <FormLabel htmlFor="display-params" mb={0}>
                              Hide Parameters
                            </FormLabel>
                          </HStack>
                        </FormControl>
                      )}
                      {configOptions.tables && (
                        <FormControl>
                          <HStack>
                            <Switch
                              size={'sm'}
                              colorScheme="brand"
                              id="display-tables"
                              isChecked={config?.hideTables}
                              onChange={() => {
                                handleConfigChange(
                                  'hideTables',
                                  !config?.hideTables,
                                );
                              }}
                            />
                            <FormLabel htmlFor="display-tables" mb={0}>
                              Hide Tables
                            </FormLabel>
                          </HStack>
                        </FormControl>
                      )}
                      {configOptions.tables && (
                        <FormControl>
                          <HStack>
                            <Switch
                              size={'sm'}
                              colorScheme="brand"
                              id="display-figures"
                              isChecked={config?.hideFigures}
                              onChange={() => {
                                handleConfigChange(
                                  'hideFigures',
                                  !config?.hideFigures,
                                );
                              }}
                            />
                            <FormLabel htmlFor="display-figures" mb={0}>
                              Hide Figures
                            </FormLabel>
                          </HStack>
                        </FormControl>
                      )}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          )}

          {/* <Button
            leftIcon={<Icon as={SparklesIcon} boxSize={4} />}
            _hover={{
              bgGradient: 'linear(to-r, #DF2780, #ba27df)',
              color: 'white',
            }}
          >
            Explain
            <Text
              verticalAlign={'super'}
              fontSize={'xs'}
              fontWeight={'normal'}
              ml={1}
              mb={1}
            >
              Beta
            </Text>
          </Button> */}

          {!readOnly && (
            <Button
              leftIcon={<Icon as={TrashIcon} boxSize={4} />}
              onClick={() => setConfirmDelete(true)}
              _hover={{
                bg: useColorModeValue('red.100', 'red.600'),
                color: useColorModeValue('red.700', 'red.600'),
              }}
            >
              Delete
            </Button>
          )}
        </ButtonGroup>
      </Box>
    </Collapse>
  );
};
