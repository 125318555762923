import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  buildTree,
  flattenTrees,
  getProjection,
  SensorContext,
  FlattenedTemplateSection,
  removeChildrenOf,
  setProperty,
  removeSections,
  EditorTemplateSectionTree,
} from './utilities';
import { AccordionItem } from './AccordionItem';
import { TemplateSectionContents } from '../../../models/template';
import { Contents } from './Contents';
import { Guideline } from '../../../models/guideline';
import API from '../../../api/API';
import { useQuery } from 'react-query';
import { createPortal } from 'react-dom';
import {
  Box,
  Heading,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  UnorderedList,
  useColorModeValue,
  useDisclosure,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  CheckBadgeIcon,
  PlusIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import GuidelinesModal from '../GuidelinesModal';
import { useTemplateEditor } from '../../../hooks/useTemplateEditor';
import { StatusChangedToast } from '../../Layout/Toasts';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

interface AccordionProps {
  isEditing?: boolean;
  editorTemplateSectionTrees: EditorTemplateSectionTree[];
  onTemplateChange: (sections: EditorTemplateSectionTree[]) => void;
  indentationWidth?: number;
}

export function Accordion({
  isEditing,
  editorTemplateSectionTrees,
  indentationWidth = 32,
  onTemplateChange,
}: AccordionProps) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTrees(editorTemplateSectionTrees);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { sections, collapsed, id }) => {
        if (collapsed && sections?.length) {
          return [...acc, id];
        }
        if (activeId === id) {
          return [...acc, id];
        } else {
          return acc;
        }
      },
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, editorTemplateSectionTrees]);

  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId as string,
          overId as string,
          offsetLeft,
          indentationWidth,
        )
      : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  const toast = useToast();

  function handleRemove(id: string) {
    onTemplateChange(removeSections(editorTemplateSectionTrees, id));
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast
          message="Section has been removed"
          status="success"
        />
      ),
    });
  }

  const handleTitleSaved = (id: string) => (title: string) => {
    onTemplateChange(
      setProperty(editorTemplateSectionTrees, id, 'title', () => {
        return title;
      }),
    );
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast message="Title has been changed" status="success" />
      ),
    });
  };

  const handleAddSection = (
    id: string,
    position: 'before' | 'after' | 'child',
  ) => {
    const clonedItems: FlattenedTemplateSection[] = JSON.parse(
      JSON.stringify(flattenTrees(editorTemplateSectionTrees)),
    );

    const sectionIdx = clonedItems.findIndex(item => item.id === id);
    const section = clonedItems[sectionIdx];

    if (position === 'before' || position === 'after') {
      const newSection: FlattenedTemplateSection = {
        id: uuidv4(),
        title: 'New Section',
        parent_section: section.parent_section,
        depth: section.depth,
        index: section.index,
        sections: [],
        contents: [],
      };
      clonedItems.splice(
        position === 'before' ? sectionIdx : sectionIdx + 1,
        0,
        newSection,
      );
      onTemplateChange(buildTree(clonedItems));
    } else {
      const newSection: EditorTemplateSectionTree = {
        id: uuidv4(),
        title: 'New Section',
        collapsed: true,
        parent_section: section.id,
        sections: [],
        contents: [],
      };
      const collapsedSections = setProperty(
        editorTemplateSectionTrees,
        id,
        'collapsed',
        () => false,
      );
      const changedSections = setProperty(
        collapsedSections,
        id,
        'sections',
        value => [...(value || []), newSection],
      );
      onTemplateChange(changedSections);
    }
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast message="Section has been added" status="success" />
      ),
    });
  };

  const handleAddGuidelines = (
    sectionId: string,
    addedGuidelines: Guideline[],
  ) => {
    const newRiskAssessmentBlocks: TemplateSectionContents[] =
      addedGuidelines.map(({ content_id }) => ({
        content_id,
        content_type: 'guideline',
      }));

    const newSections = setProperty(
      editorTemplateSectionTrees,
      sectionId,
      'contents',
      contents => (contents || []).concat(newRiskAssessmentBlocks),
    );
    onTemplateChange(newSections);
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast
          message={
            addedGuidelines.length > 1
              ? 'Guidelines have been added'
              : 'Guideline has been added'
          }
          status="success"
        />
      ),
    });
  };

  const handleRemoveGuideline = (sectionId: string, guideline: Guideline) => {
    const newSections = setProperty(
      editorTemplateSectionTrees,
      sectionId,
      'contents',
      contents => {
        const filteredContents =
          contents?.filter(
            content => content.content_id !== guideline.content_id,
          ) ?? [];
        if (filteredContents.length === 0) {
          return undefined;
        }
        return [...filteredContents];
      },
    );
    onTemplateChange(newSections);
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast
          message="Guideline has been removed"
          status="success"
        />
      ),
    });
  };

  const handleAddAssessmentSummary = (sectionId: string) => {
    const content = {
      content_id: 'assessment_summary',
      content_type: 'assessment_summary',
    };
    const newSections = setProperty(
      editorTemplateSectionTrees,
      sectionId,
      'contents',
      contents => [content, ...(contents || [])],
    );
    onTemplateChange(newSections);
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast
          message="Assessment Summary has been added"
          status="success"
        />
      ),
    });
  };

  const handleRemoveAssessmentSummary = (sectionId: string) => {
    const newSections = setProperty(
      editorTemplateSectionTrees,
      sectionId,
      'contents',
      contents => {
        const filteredContents =
          contents?.filter(
            content => content.content_type !== 'assessment_summary',
          ) ?? [];
        if (filteredContents.length === 0) {
          return undefined;
        }
        return [...filteredContents];
      },
    );
    onTemplateChange(newSections);
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <StatusChangedToast
          message="Assessment Summary has been removed"
          status="success"
        />
      ),
    });
  };

  const handleGuidelineChanged =
    (id: string, guidelineContentId: string) => (guidelines: Guideline[]) => {
      const target = ({ content_id, content_type }: TemplateSectionContents) =>
        content_type === 'risk_assessment' && content_id === guidelineContentId;
      onTemplateChange(
        setProperty(editorTemplateSectionTrees, id, 'contents', value => {
          const content = value?.find(target);
          if (value && content) {
            const idx = value.findIndex(target);
            const newGuidelines =
              guidelines.length > 0
                ? guidelines.map(({ content_id }) => content_id)
                : undefined;
            const newContent = {
              ...content,
              options: {
                ...content.options,
                guidelines: newGuidelines,
              },
            };
            return [
              ...value.slice(0, idx),
              newContent,
              ...value.slice(idx + 1),
            ];
          }
          return value;
        }),
      );
    };

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId as string);
    setOverId(activeId as string);

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId((over?.id as string) ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();
    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedTemplateSection[] = JSON.parse(
        JSON.stringify(flattenTrees(editorTemplateSectionTrees)),
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);

      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = {
        ...activeTreeItem,
        depth,
        parent_section: parentId ? parentId : '',
      };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      if (parentId) {
        sortedItems[sortedItems.findIndex(i => i.id === parentId)].collapsed =
          false;
      }

      const newItems = buildTree(sortedItems);

      onTemplateChange(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    document.body.style.setProperty('cursor', '');
  }

  function handleCollapse(id: string) {
    onTemplateChange(
      setProperty(editorTemplateSectionTrees, id, 'collapsed', value => {
        return !value;
      }),
    );
  }

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const { data: allGuidelines, isLoading } = useQuery(
    ['guidelines', 'all'],
    async () => {
      const response = await API.GetGuidelines(undefined, {
        limit: 999999,
      });
      return response.results;
    },
  );

  if (isLoading || !allGuidelines) {
    return null;
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          {flattenedItems.map((templateSection: FlattenedTemplateSection) => {
            return (
              <SectionItem
                key={templateSection.id}
                depth={
                  templateSection.id === activeId && projected
                    ? projected.depth
                    : templateSection.depth
                }
                indentationWidth={indentationWidth}
                allGuidelines={allGuidelines}
                templateSection={templateSection}
                isEditing={isEditing}
                handleRemove={handleRemove}
                handleTitleSaved={handleTitleSaved}
                handleAddSection={handleAddSection}
                handleCollapse={handleCollapse}
                handleAddGuidelines={handleAddGuidelines}
                handleAddAssessmentSummary={handleAddAssessmentSummary}
                handleRemoveGuideline={handleRemoveGuideline}
                handleRemoveAssessmentSummary={handleRemoveAssessmentSummary}
              />
            );
          })}
          {createPortal(
            <DragOverlay>
              {activeId && activeItem ? (
                <AccordionItem
                  id={activeId}
                  depth={activeItem.depth}
                  clone
                  title={activeItem.title}
                  indentationWidth={indentationWidth}
                  disableAddSubSection={false}
                />
              ) : null}
            </DragOverlay>,
            document.body,
          )}
        </SortableContext>
      </DndContext>
    </>
  );
}

interface SectionItemProps {
  templateSection: FlattenedTemplateSection;
  depth: number;
  indentationWidth: number;
  allGuidelines: Guideline[];
  isEditing: boolean | undefined;
  handleRemove: (id: string) => void;
  handleTitleSaved: (id: string) => (title: string) => void;
  handleAddSection: (
    id: string,
    position: 'before' | 'after' | 'child',
  ) => void;
  handleCollapse: (id: string) => void;
  handleAddGuidelines: (
    sectionId: string,
    addedGuidelines: Guideline[],
  ) => void;
  handleAddAssessmentSummary: (sectionId: string) => void;
  handleRemoveGuideline: (sectionId: string, guideline: Guideline) => void;
  handleRemoveAssessmentSummary: (sectionId: string) => void;
}

const SectionItem = ({
  templateSection,
  depth,
  indentationWidth,
  allGuidelines,
  isEditing,
  handleRemove,
  handleTitleSaved,
  handleAddSection,
  handleCollapse,
  handleAddAssessmentSummary,
  handleAddGuidelines,
  handleRemoveGuideline,
  handleRemoveAssessmentSummary,
}: SectionItemProps) => {
  const {
    state: { templateType },
  } = useTemplateEditor();

  const { id, title, index, collapsed, contents, sections } = templateSection;

  const existingGuidelines =
    contents
      ?.filter(content => content.content_type === 'guideline')
      .map(content => {
        const guideline = allGuidelines.find(
          guideline => guideline.content_id === content.content_id,
        )!;
        return guideline;
      }) || [];

  const assessmentSummary = contents?.find(
    content => content.content_type === 'assessment_summary',
  );

  const isLeafNode = sections?.length === 0;

  const showOutlineMenu = true;
  const showContentMenu = isLeafNode && templateType === 'validation_report';
  const disableAddSubSection = (contents?.length || 0) > 0;

  const guidelineModalControl = useDisclosure();

  const renderMenu = () => {
    return (
      <Menu>
        <Box onClick={e => e.stopPropagation()}>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="sm"
            icon={<Icon as={PlusIcon} boxSize={4} />}
            mr={2}
            aria-label="add"
            _hover={{
              bg: useColorModeValue('neutral.200', 'neutral.600'),
            }}
            color={useColorModeValue('neutral.800', 'neutral.200')}
          />
        </Box>
        <MenuList>
          {showOutlineMenu && (
            <MenuOptionGroup title="OUTLINE" fontSize={'xs'}>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  handleAddSection(id, 'before');
                }}
              >
                <Icon as={ArrowUpIcon} mr={2} />
                Section Before
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  handleAddSection(id, 'after');
                }}
              >
                <Icon as={ArrowDownIcon} mr={2} />
                Section After
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  handleAddSection(id, 'child');
                }}
                hidden={disableAddSubSection}
              >
                <Icon as={ArrowRightIcon} mr={2} />
                Subsection
              </MenuItem>
            </MenuOptionGroup>
          )}
          {showContentMenu && (
            <MenuOptionGroup title="CONTENT" fontSize={'xs'}>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  if (collapsed) {
                    handleCollapse(id);
                  }
                  handleAddAssessmentSummary(id);
                }}
                hidden={!!assessmentSummary}
              >
                <Icon as={QueueListIcon} mr={2} />
                Summary Block
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  guidelineModalControl.onOpen();
                }}
              >
                <Icon as={CheckBadgeIcon} mr={2} />
                Guideline Block
              </MenuItem>
              {/*

            TODO: Add these when we are able to add other block types


            <MenuItem>
              <Icon as={Bars3BottomLeftIcon} mr={2} />
              Text Block
            </MenuItem>
            <MenuItem>
              <Icon as={CodeBracketSquareIcon} mr={2} />
              Test-Driven Block
            </MenuItem> */}
            </MenuOptionGroup>
          )}
        </MenuList>
      </Menu>
    );
  };
  return (
    <>
      <GuidelinesModal
        isOpen={guidelineModalControl.isOpen}
        onClose={guidelineModalControl.onClose}
        allGuidelines={allGuidelines}
        existingGuidelineContentIds={existingGuidelines.map(
          content => content.content_id,
        )}
        onSaved={addedGuidelines => {
          if (collapsed) {
            handleCollapse(id);
          }
          handleAddGuidelines(id, addedGuidelines);
        }}
      />
      <AccordionItem
        key={id}
        id={id}
        index={index}
        title={title}
        depth={depth}
        indentationWidth={indentationWidth}
        collapsed={Boolean(collapsed)}
        onCollapse={
          sections?.length ||
          contents?.length ||
          templateSection.guidelines?.length
            ? () => handleCollapse(id)
            : undefined
        }
        onTitleSaved={handleTitleSaved(id)}
        onRemove={() => handleRemove(id)}
        isEditing={isEditing}
        renderMenu={renderMenu}
      >
        {templateSection.guidelines && (
          <Box
            p={6}
            borderLeft={'1px solid'}
            borderLeftColor={useColorModeValue('neutral.200', 'neutral.800')}
          >
            <Heading mb={4} size={'sm'}>
              Guidelines
            </Heading>
            <UnorderedList>
              {templateSection.guidelines.map((guideline, idx) => {
                return (
                  <ListItem key={`${id}-guideline-${idx}`}>
                    <Text width={'prose'}>{guideline}</Text>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Box>
        )}
        <Contents
          key={`content-${id}`}
          sectionId={id}
          contents={contents || []}
          allGuidelines={allGuidelines}
          isEditing={isEditing}
          // onAddGuideline={addedGuidelines =>
          //   handleAddGuidelines(id, addedGuidelines)
          // }
          onRemoveGuideline={guideline => {
            handleRemoveGuideline(id, guideline);
          }}
          onRemoveAssessmentSummary={() => handleRemoveAssessmentSummary(id)}
        />
      </AccordionItem>
    </>
  );
};
