import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import UsersContext from '../../contexts/UsersContext';
import { TUser } from '../../models';
import { TUserRole } from '../../models/role';
import { TUserWithoutRoles } from '../../models/user';

interface TUserRolesMap {
  [userCuid: string]: TUserRole[];
}

const constructUsersWithRoles = (
  users: TUserWithoutRoles[],
  userRoles: TUserRole[],
): TUser[] => {
  const consolidatedRoles: TUserRolesMap = {};
  userRoles.forEach(userRole => {
    const { cuid: userId } = userRole.user;
    if (!consolidatedRoles[userId]) {
      consolidatedRoles[userId] = [userRole];
    } else {
      consolidatedRoles[userId].push(userRole);
    }
  });

  return users.map(user => ({
    ...user,
    roles: consolidatedRoles[user.cuid] ?? [],
  }));
};

export default function useOrganizationUsersWithRoles() {
  const { organizationUsers } = useContext(UsersContext);
  const [organizationUsersWithRoles, setOrganizationUsersWithRoles] = useState<
    TUser[]
  >([]);

  const queryResponse = useQuery(
    ['organization-users-with-roles'],
    async () => {
      const { results: userRoles } = await API.ListUserRoles();
      return userRoles;
    },
    {
      onSuccess: data => {
        setOrganizationUsersWithRoles(
          constructUsersWithRoles(organizationUsers, data),
        );
      },
    },
  );

  return { organizationUsersWithRoles, ...queryResponse };
}
