import { useEffect } from 'react';
import Highlight from 'react-highlight';
import { useColorModeValue } from '@chakra-ui/react';

const THEME_STYLE_ID = 'hljs-theme-style' as const;

interface CodeProps {
  language?: string;
  children: React.ReactNode;
}

// background colors are commented out to allow chakra to handle the color
const githubCss = `
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  /* background: #f8f8f8; */
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
`;

const draculaCss = `
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  /* background: #282a36; */
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-section,
.hljs-link {
  color: #8be9fd;
}

.hljs-function .hljs-keyword {
  color: #ff79c6;
}

.hljs,
.hljs-subst {
  color: #f8f8f2;
}

.hljs-string,
.hljs-title,
.hljs-name,
.hljs-type,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
  color: #f1fa8c;
}

.hljs-comment,
.hljs-quote,
.hljs-deletion,
.hljs-meta {
  color: #6272a4;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-type,
.hljs-name,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}
`;

/**
 * Code component for syntax highlighting code blocks
 * Uses highlight.js under the hood with GitHub/Dracula themes
 */
export function Code({ language = 'python', children }: CodeProps) {
  const hljsTheme = useColorModeValue('github', 'dracula');
  const hljsCss = hljsTheme === 'github' ? githubCss : draculaCss;

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.id = THEME_STYLE_ID;
    styleElement.textContent = hljsCss;
    document.head.appendChild(styleElement);

    return () => {
      const existingStyle = document.getElementById(THEME_STYLE_ID);
      if (existingStyle) {
        document.head.removeChild(existingStyle);
      }
    };
  }, [hljsCss]);
  return (
    <Highlight className={`${language} code-snippet`}>{children}</Highlight>
  );
}
