import { TDashboardVisualizationJSON } from '../../../../models/report';
import { useEffect, useMemo, useState } from 'react';
import API from '../../../../api/API';
import ReactECharts from 'echarts-for-react';
import { Box, Spinner } from '@chakra-ui/react';

type ReportingBarChartProps = {
  chartClick: (params: any, data: any[]) => void;
  visualizationJSON: TDashboardVisualizationJSON;
};

const ReportBarChart = ({
  chartClick,
  visualizationJSON: {
    dataset,
    metrics = [],
    grouping = [],
    sorting,
    filtering,
    extraSettings,
  },
}: ReportingBarChartProps) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const isPercentage = metrics.length > 0 && metrics[0]?.action === 'count_pct';

  useEffect(() => {
    (async () => {
      if (!dataset || metrics.length === 0 || grouping.length === 0) {
        return;
      }
      setLoading(true);
      const results = await API.GetReportingData(
        dataset,
        metrics,
        grouping,
        sorting,
        filtering,
      );
      setData(results);
      setLoading(false);
    })();
  }, [dataset, metrics, grouping, sorting, filtering]);

  const option = useMemo(() => {
    const xAxisLabels = [];
    const series: any[] = [];

    if (grouping.length === 1) {
      xAxisLabels.push(...data.map(d => d.group[0]));
      series.push({
        data: data.map(d => d.metric),
        type: 'bar',
        name: '',
      });
    } else if (grouping.length === 2) {
      const uniqueGroups = new Set<string>();
      data.forEach(d => {
        uniqueGroups.add(d.group[0]);
      });
      xAxisLabels.push(...Array.from(uniqueGroups));

      const uniqueSeries = new Set<string>();

      data.forEach(d => {
        uniqueSeries.add(d.group[1]);
      });

      uniqueSeries.forEach(s => {
        const seriesData: number[] = [];
        uniqueGroups.forEach(g => {
          const metric =
            data.find(d => d.group[0] === g && d.group[1] === s)?.metric || 0;
          seriesData.push(metric);
        });
        series.push({
          name: s,
          type: 'bar',
          stack:
            extraSettings?.barChartGroupingStyle === 'stacked'
              ? 'total'
              : undefined,
          data: seriesData,
          emphasis: {
            focus: 'series',
          },
        });
      });
    }

    return {
      xAxis: {
        type: 'category',
        data: xAxisLabels,
        axisLabel: {
          formatter: (l: any) => l.replace(' ', '\n'),
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: isPercentage ? (value: number) => `${value}%` : undefined,
        },
        max: isPercentage ? 100 : undefined,
      },
      series: series,
      tooltip: {
        trigger: 'item',
        valueFormatter: (value: number) =>
          isPercentage ? `${value.toFixed(2)}%` : value,
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
      },
      grid: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
        containLabel: true,
      },
    };
  }, [data, grouping, extraSettings, isPercentage]);

  const onEvents = useMemo(() => {
    return {
      click: (params: any) => {
        chartClick(params, data);
      },
    };
  }, [chartClick, data]);

  if (loading) {
    return (
      <Box
        display="flex"
        flex={1}
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <ReactECharts
      notMerge
      option={option}
      style={{ height: '100%', width: '100%' }}
      onEvents={onEvents}
    />
  );
};

export default ReportBarChart;
