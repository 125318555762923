import API from '../../../api/API';
import { useMutation, useQuery } from 'react-query';
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  IconButton,
  LinkBox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Square,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { ContentPageTitle } from '../../../components/Layout';
import {
  TStatusesWorkflow,
  TStatusesWorkflowStatus,
} from '../../../models/statuses_workflow';
import { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { EmptyStateDisplay } from '../../../components/EmptyStateDisplay';
import AddStatusModal from '../../../components/AddStatusModal';
import StatusesWorkflowContext from '../../../contexts/StatusesWorkflowContext';
import { AddIcon } from '@chakra-ui/icons';
import SidebarContext from '../../../contexts/SidebarContext';
import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { DangerMenuItem } from '../../../components/DangerMenuItem';

interface IWorkflowStatusMutation {
  workflow: TStatusesWorkflow;
  status: TStatusesWorkflowStatus;
}

function ModelStatusesWorkflow() {
  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);

  const {
    data: workflows,
    isLoading,
    refetch,
  } = useQuery(
    ['statuses'],
    async () => {
      return API.GetStatusesWorkflows();
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  // The API will now return a single workflow object
  const workflow = (workflows || [])[0];

  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState<
    TStatusesWorkflowStatus | undefined
  >(undefined);

  const createWorkflowStatusMutation = useMutation(
    ['statuses-workflows', workflow?.cuid, 'status'],
    async (variables: IWorkflowStatusMutation) => {
      const { workflow, status } = variables;
      if (status.cuid) {
        return API.PutStatusesWorkflowStatus(workflow.cuid, status);
      }
      return API.PostStatusesWorkflowStatus(workflow.cuid, status);
    },
    {
      onSuccess: (data, variables) => {
        // status was edited
        let edited = variables.workflow.statuses.find(
          s => s.cuid === data.cuid,
        );

        refetch();

        const description = `Status was ${
          edited ? 'modified' : 'created'
        } successfully`;
        toast({
          title: data.name,
          description,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: err => {
        // track errors
      },
    },
  );

  const deleteWorkflowStatusMutation = useMutation(
    [],
    async (variables: IWorkflowStatusMutation) => {
      const { workflow, status } = variables;
      await API.DeleteStatusesWorkflowStatus(workflow.cuid, status);
    },
    {
      onSuccess: (data, variables) => {
        const { status: workflowStatus } = variables;
        refetch();
        toast({
          title: workflowStatus.name,
          description: 'Status was deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error, variables) => {
        toast({
          title: 'Error',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onEditStatusWorkflowStatus = (
    workflowStatus: TStatusesWorkflowStatus,
  ) => {
    setCurrentWorkflowStatus(workflowStatus);
    setOpenModal(true);
  };

  const onDeleteStatusWorkflowStatus = async (
    status: TStatusesWorkflowStatus,
  ) => {
    if (
      workflow &&
      confirm(`Do you want to delete the "${status.name}" status?`)
    ) {
      deleteWorkflowStatusMutation.mutate({ workflow, status });
    }
  };

  const onModalClosed = () => {
    setOpenModal(false);
    setCurrentWorkflowStatus(undefined);
  };

  return (
    <StatusesWorkflowContext.Provider
      value={{ workflow, createWorkflowStatusMutation }}
    >
      <LoadingContainer isLoading={isLoading}>
        <Stack alignSelf={'stretch'} gap={8}>
          <Box>
            <Breadcrumbs />
            <ContentPageTitle>
              Model Lifecycle Statuses
              <MoreInfoPopOver
                title="Customize Model Lifecycle Statuses"
                link="https://docs.validmind.ai/guide/model-workflows/customize-resource-statuses.html"
                placement="right-end"
                iconProps={{
                  ml: 2,
                }}
              />
            </ContentPageTitle>
          </Box>
          <Text>
            Model lifecycle statuses are manipulated via workflow transitions
            and are used to track the status of models through your
            organization's processes.
          </Text>
          <HStack spacing={2} align={'stretch'} justifyContent={'end'}>
            <Button
              onClick={() => setOpenModal(true)}
              leftIcon={<Icon as={AddIcon} boxSize={4} />}
              variant={'ghost'}
            >
              Add New Model Lifecycle Status
            </Button>
          </HStack>
        </Stack>
        {workflow && (
          <Stack maxWidth={'7xl'} mx={'auto'} w={'full'}>
            <VStack gap={1} alignSelf={'stretch'}>
              {workflow.statuses.length > 0 ? (
                workflow.statuses.map(status => (
                  <LinkBox
                    data-testid="model-status"
                    cursor={'pointer'}
                    key={status.cuid}
                    alignSelf={'stretch'}
                    mt={'0 !important'}
                    role="group"
                    rounded={'md'}
                    p={4}
                    border={'1px solid'}
                    _hover={{
                      bg: useColorModeValue(
                        'brandSecondary.25',
                        'brandSecondary.950',
                      ),
                      borderColor: useColorModeValue(
                        'brandSecondary.100',
                        'brandSecondary.800',
                      ),
                      color: useColorModeValue('inherit', 'brandSecondary.25'),
                    }}
                    borderColor={useColorModeValue(
                      'neutral.200',
                      'neutral.800',
                    )}
                    onClick={() => onEditStatusWorkflowStatus(status)}
                    transition={'all 0.3s ease-in-out'}
                  >
                    <HStack alignItems={'flex-start'} gap={4}>
                      <Square
                        size={6}
                        rounded={'md'}
                        border={1}
                        borderStyle="solid"
                        borderColor={status.colors.tertiary}
                        bgColor={status.colors.primary}
                      ></Square>

                      <VStack align={'start'} flex="1" spacing={1}>
                        <Heading as={'h4'}>{status.name}</Heading>
                        <Text>{status.description}</Text>
                      </VStack>

                      <VStack alignItems="flex-end">
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            variant="ghost"
                            aria-label="Options"
                            icon={
                              <Icon as={EllipsisVerticalIcon} boxSize={6} />
                            }
                            visibility={'hidden'}
                            _groupHover={{
                              visibility: 'visible',
                            }}
                            onClick={event => {
                              event.stopPropagation();
                            }}
                          />
                          <MenuList>
                            <MenuItem
                              icon={<Icon as={PencilIcon} boxSize={5} />}
                              onClick={() => onEditStatusWorkflowStatus(status)}
                            >
                              Edit Details
                            </MenuItem>

                            <MenuDivider />
                            <DangerMenuItem
                              icon={<Icon as={TrashIcon} boxSize={5} />}
                              onClick={event => {
                                onDeleteStatusWorkflowStatus(status);
                                event.stopPropagation();
                              }}
                            >
                              Delete Status
                            </DangerMenuItem>
                          </MenuList>
                        </Menu>
                      </VStack>
                    </HStack>
                  </LinkBox>
                ))
              ) : (
                <EmptyStateDisplay variant="no-workflow">
                  <Heading as={'h5'}>No model lifecycle statuses yet.</Heading>
                  <Text align={'center'}>
                    Click "+ Add New Model Lifecycle Status" to get started.
                  </Text>
                </EmptyStateDisplay>
              )}
            </VStack>
            <AddStatusModal
              isOpen={openModal}
              workflowStatus={currentWorkflowStatus}
              onClose={onModalClosed}
            />
          </Stack>
        )}
      </LoadingContainer>
    </StatusesWorkflowContext.Provider>
  );
}

export default function Statuses() {
  const { setInSettings } = useContext(SidebarContext);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={2}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <ModelStatusesWorkflow />
    </VStack>
  );
}
