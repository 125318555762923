import { Box, HStack, StackDivider, useColorModeValue } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { BlockToolBar } from '../../BlockToolBar';
import React from 'react';
import API from '../../../api/API';
import { useQuery } from 'react-query';
import InventoryModelContext from '../../../contexts/InventoryModel';

type BlockWrapperProps = {
  documentType: string;
  readOnly: boolean | undefined;
  contentType: string;
  contentId: string;
  children: React.ReactNode;
  hideToolbar?: boolean;
  showDeleteConfirmation: () => void;
  setIsInView: (isInView: boolean) => void;
};

export type BlockConfig = {
  hideTitle?: boolean;
  hideText?: boolean;
  hideParams?: boolean;
  hideTables?: boolean;
  hideFigures?: boolean;
};

export type BlockConfigOptions = {
  title: boolean;
  text: boolean;
  params: boolean;
  tables: boolean;
  figures: boolean;
};

type BlockWrapperContextType = {
  config?: BlockConfig;
  configOptions?: BlockConfigOptions;
  setConfig: (config: BlockConfig) => void;
  showTimelineFn?: () => void;
  setShowTimelineFn: (fn: () => () => void) => void;
  focusEditorFn?: () => void;
  setFocusEditorFn: (fn: () => () => void) => void;
  enableConfig: (options: BlockConfigOptions) => void;
};

export const BlockWrapperContext = React.createContext<BlockWrapperContextType>(
  {
    config: undefined,
    configOptions: undefined,
    setConfig: () => {},
    showTimelineFn: undefined,
    setShowTimelineFn: () => () => {},
    focusEditorFn: undefined,
    setFocusEditorFn: () => () => {},
    enableConfig: () => {},
  },
);

const BlockWrapper: React.FC<BlockWrapperProps> = ({
  children,
  readOnly,
  documentType,
  contentType,
  contentId,
  hideToolbar = false,
  showDeleteConfirmation,
  setIsInView,
}) => {
  const { inventoryModel } = useContext(InventoryModelContext);
  const blockRef = useRef(null);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsInView(inView);
  }, [inView]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  });

  const handleClickOutside = (e: any) => {
    if (blockRef.current && !(blockRef.current as any).contains(e.target)) {
      setIsFocused(false);
    }
  };

  const [config, setConfig] = useState<BlockConfig>();
  const [configOptions, setConfigOptions] = useState<
    BlockConfigOptions | undefined
  >();
  const [showTimelineFn, setShowTimelineFn] = useState<() => () => void>();
  const [focusEditorFn, setFocusEditorFn] = useState<() => () => void>();

  const enableConfig = useCallback(
    (options: BlockConfigOptions) => {
      setConfigOptions(options);
    },
    [setConfigOptions],
  );

  const { isLoading } = useQuery(
    ['block_config', contentType, contentId],
    async () => {
      const fetchedConfig = await API.GetBlockConfig(
        inventoryModel!.cuid,
        documentType,
        contentType,
        contentId,
      );
      setConfig({
        hideTitle: fetchedConfig?.hideTitle,
        hideText: fetchedConfig?.hideText,
        hideParams: fetchedConfig?.hideParams,
        hideTables: fetchedConfig?.hideTables,
        hideFigures: fetchedConfig?.hideFigures,
      });
    },
    {
      enabled: inView,
      retry: false,
    },
  );

  const updateConfig = useCallback(
    (key: string, value: boolean) => {
      const newConfig = config ? { ...config, [key]: value } : {};
      setConfig(newConfig);
      API.UpdateBlockConfig(
        inventoryModel!.cuid,
        documentType,
        contentType,
        contentId,
        newConfig,
      );
    },
    [config],
  );

  return (
    <BlockWrapperContext.Provider
      value={{
        config,
        configOptions,
        setConfig,
        enableConfig,
        showTimelineFn,
        setShowTimelineFn,
        focusEditorFn,
        setFocusEditorFn,
      }}
    >
      <Box ref={ref} position={'relative'} flex={1}>
        {readOnly !== true && (
          <Box
            position={'absolute'}
            hidden={!isFocused}
            top={'-42px'}
            left={'0'}
            boxShadow={'var(--ck-drop-shadow),0 0'}
            w={'min'}
            rounded={'md'}
          >
            <HStack
              divider={
                <StackDivider borderColor="neutral.200" m={'1 !important'} />
              }
              rounded={'md'}
              bg={'white'}
              border={'1px solid var(--chakra-colors-neutral-200)'}
              gap={0}
            ></HStack>
          </Box>
        )}

        {!hideToolbar && (
          <BlockToolBar
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            setIsFocused={setIsFocused}
            setConfirmDelete={showDeleteConfirmation}
            readOnly={readOnly || false}
            handleConfigChange={updateConfig}
          />
        )}

        <Box
          p={2}
          border={'1px solid'}
          borderColor={'transparent'}
          borderRadius={'md'}
          transition={'all .5s ease-in-out'}
          _hover={{
            borderColor: useColorModeValue('brandSecondary.300', 'neutral.700'),
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={() => {
            if (readOnly) return;
            setIsFocused(true);
          }}
          ref={blockRef}
          flex={1}
        >
          {!isLoading && children}
        </Box>
      </Box>
    </BlockWrapperContext.Provider>
  );
};

export default BlockWrapper;
