import {
  Box,
  Heading,
  HStack,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { Copyright } from '../../components/Copyright';
import UsersContext from '../../contexts/UsersContext';
import GetStartedChecklist from '../../components/GetStartedChecklist';
import AvatarProxy from '../../components/AvatarProxy';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import DashboardPage from '../../components/DashboardPage';

export default function Dashboard() {
  const { currentUser: user } = useContext(UsersContext);

  return (
    <Box
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
    >
      <HStack alignItems={'center'} pt={9} mb={4} gap={4}>
        <AvatarProxy size="md" name={user?.name} src={user?.picture} />

        <Stack>
          <HStack>
            <Heading as={'h1'}>Welcome, {user?.name}!</Heading>
            <MoreInfoPopOver
              title="Customize Your Dashboard"
              link="https://docs.validmind.ai/guide/configuration/customize-your-dashboard.html"
            />
          </HStack>
        </Stack>
      </HStack>

      <DashboardPage dashboardType="user" />

      <Copyright />

      <GetStartedChecklist />
    </Box>
  );
}
