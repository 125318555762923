import { useQuery } from 'react-query';
import API from '../../api/API';

function useOrgRoles() {
  const { isLoading, data: orgRoles } = useQuery(['roles'], async () => {
    const results = await API.GetOrganizationRoles();
    return results;
  });

  return { orgRoles: orgRoles || [], isLoading };
}

export { useOrgRoles };
