import {
  Box,
  Button,
  FormControl,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import UsersContext from '../../../contexts/UsersContext';
import Breadcrumbs from '../../../components/Breadcrumbs';
import RolePill from '../../../components/RolePill';
import SidebarContext from '../../../contexts/SidebarContext';
import ProfileOnboardingSettings from '../../../components/ProfileOnboardingSettings';
import {
  ClipboardDocumentIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import UIColorSchemeSettings from '../../../components/UIColorSchemeSettings';
import { useGroups } from '../../../hooks/useGroups';
import { useQuery } from 'react-query';
import { TGroup } from '../../../models/group';
import { getUserRolesForOrg } from '../../../models/user';
import API from '../../../api/API';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { DataTable } from '../../../components/DataTable';
import { CellProps } from 'react-table';
import { TGroupWithMembers } from '../Groups/Group';
import GroupView from '../../../components/GroupView';
import { ContentPageTitle, Label } from '../../../components/Layout';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';

interface CopyClipboardProps {
  textToCopy: string;
}

const CopyClipboardButton = ({ textToCopy }: CopyClipboardProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  return (
    <Button
      leftIcon={<Icon as={ClipboardDocumentIcon} boxSize={4} />}
      onClick={async () => {
        setHasCopied(true);
        await navigator.clipboard.writeText(textToCopy);
        setTimeout(() => setHasCopied(false), 2000);
      }}
    >
      {hasCopied ? 'Copied!' : 'Copy to Clipboard'}
    </Button>
  );
};

const TermsDisplay = ({
  acceptedAt,
  label,
  link,
  onAccept,
}: {
  acceptedAt?: number;
  label: string;
  link?: string;
  onAccept: () => Promise<void>;
}) => {
  const [acceptLoading, setAcceptLoading] = useState(false);

  let date = '';
  if (acceptedAt) {
    date = dayjs(acceptedAt * 1000).format('MMM D, YYYY');
  }

  return (
    <HStack gap={0}>
      <Icon
        boxSize={4}
        color={!!acceptedAt ? 'green.500' : 'red.500'}
        as={!!acceptedAt ? CheckCircleIcon : WarningIcon}
        mr={2}
      />
      <Link href={link} target="_blank">
        {label}
      </Link>
      <Text>
        {acceptedAt ? `, accepted on ${date}` : `, haven't been accepted yet.`}
      </Text>
      {!acceptedAt && (
        <Button
          isLoading={acceptLoading}
          isDisabled={acceptLoading}
          size={'xs'}
          ml={2}
          onClick={async () => {
            setAcceptLoading(true);
            await onAccept();
            setAcceptLoading(false);
          }}
        >
          Accept
        </Button>
      )}
    </HStack>
  );
};

export default function Profile() {
  const { currentUser, currentOrganization, refetchCurrentUser } =
    useContext(UsersContext);
  const { setInSettings } = useContext(SidebarContext);
  const { groups } = useGroups();
  const [isSecretVisible, setSecretVisible] = useState(false);

  const { data: allGroupsWithMembers = [], isLoading } = useQuery(
    ['groups-settings', groups],
    async (): Promise<TGroupWithMembers[]> => {
      if (!currentUser) {
        return [];
      }
      const getGroupWithMembers = async (
        group: TGroup,
      ): Promise<TGroupWithMembers> => {
        const members = await API.GetGroupUsers(group.cuid);
        return { ...group, members };
      };
      const allGroupsWithMembers = groups.map(getGroupWithMembers);
      return await Promise.all(allGroupsWithMembers);
    },
    {
      staleTime: 5 * 60 * 1000,
    },
  );

  const columns = [
    {
      Header: 'All Groups',
      Cell: ({ row: { original: group } }: CellProps<TGroupWithMembers>) => (
        <GroupView group={group} />
      ),
    },
  ];

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const acceptedTerms = currentUser?.accepted_tyc;
  const acceptedAITerms = currentUser?.accepted_ai_terms;

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflowY="auto"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Profile
          <MoreInfoPopOver
            title="Your Profile"
            link="https://docs.validmind.ai/guide/configuration/view-your-profile.html"
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text>
        View your profile details, manage your onboarding preferences, toggle
        dark mode, and access your API keys.
      </Text>
      <VStack gap={16} alignItems="start" spacing={0} w="full">
        <Stack width={'full'} spacing={4} data-testid="profile-form">
          <Heading as="h3">Profile Details</Heading>
          <Grid templateColumns="repeat(2, 1fr)" gap={8} w="full">
            <GridItem>
              <FormControl data-testid="firstName-field">
                <Label mb={2}>FIRST NAME</Label>
                <Input
                  placeholder="First Name"
                  readOnly
                  value={currentUser?.name ?? ''}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl data-testid="lastName-field">
                <Label mb={2}>LAST NAME</Label>
                <Input
                  placeholder="Last Name"
                  readOnly
                  value={currentUser?.last_name ?? ''}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl data-testid="email-field">
                <Label mb={2}>EMAIL</Label>
                <Input
                  placeholder="Email"
                  readOnly
                  value={currentUser?.email ?? ''}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl data-testid="title-field">
                <Label mb={2}>TITLE</Label>
                <Input
                  placeholder="Title"
                  readOnly
                  value={currentUser?.job_title ?? ''}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl data-testid="roles-field">
                <Label mb={2}>ROLES</Label>
                <HStack
                  py={4}
                  px={3}
                  border="1px"
                  borderRadius="lg"
                  borderColor={useColorModeValue('neutral.200', 'neutral.700')}
                >
                  {getUserRolesForOrg(
                    currentUser,
                    currentOrganization?.cuid,
                  ).map(({ role }) => (
                    <RolePill key={role.cuid} role={role} />
                  ))}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Stack width={'full'} spacing={4} data-testid="profile-assigned-group">
          <Heading as="h3">Assigned Groups</Heading>
          <LoadingContainer isLoading={isLoading}>
            <DataTable
              data={allGroupsWithMembers.filter(({ members }) =>
                members.some(({ cuid }) => cuid === currentUser?.cuid),
              )}
              columns={columns}
            />
          </LoadingContainer>
        </Stack>
        <Stack width={'full'} spacing={4}>
          <ProfileOnboardingSettings />
        </Stack>
        <Stack width={'full'} spacing={4}>
          <UIColorSchemeSettings />
        </Stack>
        <Stack width={'full'} spacing={4}>
          <VStack
            border={'1px solid'}
            borderColor={useColorModeValue('neutral.200', 'neutral.700')}
            borderRadius={'lg'}
            width={'full'}
            p={4}
            spacing={4}
            alignItems={'flex-start'}
            gap={4}
          >
            <Heading as="h3">Terms</Heading>
            <VStack alignItems="flex-start">
              <TermsDisplay
                acceptedAt={acceptedTerms}
                label="Terms and Conditions"
                link="https://validmind.com/about/legal/terms-of-use/"
                onAccept={async () => {
                  await API.PutUserAcceptTerms(true, false);
                  refetchCurrentUser();
                }}
              />
              <TermsDisplay
                acceptedAt={acceptedAITerms}
                label="AI Terms of Use"
                link="https://validmind.com/about/legal/"
                onAccept={async () => {
                  await API.PutUserAcceptTerms(false, true);
                  refetchCurrentUser();
                }}
              />
            </VStack>
          </VStack>
        </Stack>
        <Stack width={'full'} data-testid="access-key-form">
          <Heading as="h3">Access Keys</Heading>
          <SimpleGrid columns={2} spacingX={8} spacingY={6}>
            <FormControl data-testid="apiKey-field">
              <Label mb={2}>API KEY</Label>
              <VStack alignItems={'flex-start'}>
                <Input
                  placeholder="API Key"
                  readOnly
                  value={
                    currentUser?.api_clients.find(
                      apiClient =>
                        apiClient.organization_cuid ===
                        currentOrganization?.cuid,
                    )?.api_key ?? ''
                  }
                />
                <CopyClipboardButton
                  textToCopy={
                    currentUser?.api_clients.find(
                      apiClient =>
                        apiClient.organization_cuid ===
                        currentOrganization?.cuid,
                    )?.api_key || ''
                  }
                />
              </VStack>
            </FormControl>
            <FormControl data-testid="secretKey-field">
              <Label mb={2}>SECRET KEY</Label>
              <VStack align="stretch">
                <Input
                  placeholder="Secret Key"
                  readOnly
                  type={isSecretVisible ? 'text' : 'password'}
                  value={
                    currentUser?.api_clients.find(
                      apiClient =>
                        apiClient.organization_cuid ===
                        currentOrganization?.cuid,
                    )?.api_secret ?? ''
                  }
                />
                <HStack>
                  <CopyClipboardButton
                    textToCopy={
                      currentUser?.api_clients.find(
                        apiClient =>
                          apiClient.organization_cuid ===
                          currentOrganization?.cuid,
                      )?.api_secret || ''
                    }
                  />
                  <Button
                    variant={'ghost'}
                    leftIcon={
                      isSecretVisible ? (
                        <Icon as={EyeIcon} strokeWidth={2} />
                      ) : (
                        <Icon as={EyeSlashIcon} strokeWidth={2} />
                      )
                    }
                    onClick={() => setSecretVisible(!isSecretVisible)}
                  >
                    {isSecretVisible ? 'Hide Key' : 'Reveal Key'}
                  </Button>
                </HStack>
              </VStack>
            </FormControl>
          </SimpleGrid>
        </Stack>
      </VStack>
    </VStack>
  );
}
