import WorkflowCanvas from '../../components/WorkflowCanvas';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import API from '../../../../../api/API';
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import WorkflowContext from '../../../../../contexts/WorkflowContext';
import { useState } from 'react';
import { cleanUpSource } from '../../components/utils';
import { WorkflowSource } from '../../../../../models/workflow';

export default function WorkflowsPage() {
  const { workflowCuid, version } = useParams();
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const [softSelectedNodeId] = useState<string>();
  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  const {
    data: workflow,
    isLoading,
    refetch,
  } = useQuery(
    ['me', 'organizations', 'workflows', workflowCuid, version],
    async () => {
      return API.GetWorkflow(workflowCuid!, version!);
    },
  );

  const saveSource = useMutation(
    ['me', 'organizations', 'workflows', workflowCuid, version],
    async (source: WorkflowSource) => {
      return API.PostWorkflowVersionSource(
        workflowCuid!,
        version!,
        cleanUpSource(source!),
      );
    },
    {
      onSuccess: () => refetch(),
    },
  );

  return (
    <LoadingContainer isLoading={isLoading}>
      <WorkflowContext.Provider
        value={{
          workflow,
          saveSource,
          selectedNodeId,
          setSelectedNodeId,
          forceUpdateKey,
          setForceUpdateKey,
        }}
      >
        <WorkflowCanvas />
      </WorkflowContext.Provider>
    </LoadingContainer>
  );
}
