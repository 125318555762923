import { useContext } from 'react';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import ContentRowLayout from '../ContentRowLayout';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { MetadataContentEditor } from '../../Templates/ModelDocumentation';
import { ModelDocumentTypeEnum } from '../../../models/model_document';
import { Spacer, Text } from '@chakra-ui/react';
import { ContentRowProps } from '../index';
import InventoryModelContext from '../../../contexts/InventoryModel';

function ContentRowMetadata({
  content,
  documentType,
  isChecked = false,
  onCheck,
  onRemoveContent,
}: ContentRowProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const truncateAt = 12;
  const truncateBy = 'words';

  const queryKey = [
    'inventory-model',
    inventoryModel?.cuid,
    'metadata',
    documentType,
    content.content_id,
    truncateAt,
    truncateBy,
  ];
  const { data: metadata, isLoading } = useQuery(queryKey, async () => {
    return await API.GetModelInventoryMetadataByContentId(
      inventoryModel!,
      content.content_id,
      documentType,
      truncateAt,
      truncateBy,
    );
  });
  const text = metadata?.text || '';

  return (
    <ContentRowLayout
      icon={Bars3BottomLeftIcon}
      onCheck={onCheck ? () => onCheck(content) : undefined}
      isChecked={isChecked}
      details={{
        content: (
          <MetadataContentEditor
            readOnly={true}
            contents={{
              content_id: content.content_id,
              content_type: content.content_type,
            }}
            documentType={ModelDocumentTypeEnum.model_documentation}
            overrideDocumentType={ModelDocumentTypeEnum.model_documentation}
          />
        ),
      }}
      onRemoveContent={onRemoveContent}
    >
      <Text isTruncated>{isLoading ? 'Loading...' : text || 'Empty text'}</Text>
      <Spacer />
    </ContentRowLayout>
  );
}

export default ContentRowMetadata;
