import { useQuery } from 'react-query';
import AdminAPI, {
  Organization,
  OrganizationDefaults,
  User,
  AdminUser,
} from '../../api/AdminAPI';

export default function useOrganizations() {
  const queryResponse = useQuery(['admin', 'organizations'], async () => {
    return await AdminAPI.GetOrganizations();
  });

  return {
    organizations: queryResponse.data as Organization[],
    ...queryResponse,
  };
}

export function useOrganizationDefaults() {
  const queryResponse = useQuery(
    ['admin', 'organization-defaults'],
    async () => {
      return await AdminAPI.GetOrganizationDefaults();
    },
  );

  return {
    defaults: queryResponse.data as OrganizationDefaults,
    ...queryResponse,
  };
}

export function useUsers() {
  const queryResponse = useQuery(['admin', 'users'], async () => {
    return await AdminAPI.GetUsers();
  });

  return {
    users: queryResponse.data as User[],
    ...queryResponse,
  };
}

export function useCurrentAdminUser(isReady: boolean) {
  const queryResponse = useQuery(
    ['admin', 'current_user'],
    async () => {
      return await AdminAPI.GetCurrentUser();
    },
    { enabled: isReady },
  );

  return {
    user: queryResponse.data as AdminUser,
    ...queryResponse,
  };
}

export { useOrganizations };
