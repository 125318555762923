import {
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Badge,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { useOrganizationDefaults } from '../../hooks/admin';

function OrganizationDefaults() {
  const { defaults, isLoading } = useOrganizationDefaults();
  const cardBg = useColorModeValue('white', 'gray.800');

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" h="400px">
        <Spinner size="xl" color="brand.500" />
      </Box>
    );
  }

  return (
    <VStack spacing={8} align="stretch">
      {/* Demo Models Section */}
      <Box>
        <Heading size="lg" mb={6}>
          Demo Models
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {defaults?.initial_demo_models.map((model, index) => (
            <Card key={index} bg={cardBg} shadow="md">
              <CardHeader>
                <Heading size="md">{model.name}</Heading>
                <Badge colorScheme="blue" mt={2}>
                  {model.business_area}
                </Badge>
              </CardHeader>
              <CardBody>
                <Text>{model.description}</Text>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>

      <Divider />

      {/* Templates Section */}
      <Box>
        <Heading size="lg" mb={6}>
          Demo Templates
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {defaults?.initial_templates.map((template, index) => (
            <Card key={index} bg={cardBg} shadow="md">
              <CardBody>
                <Text fontWeight="medium">{template.name}</Text>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </VStack>
  );
}

export default OrganizationDefaults;
