import { Handle, NodeProps, Position, useStore } from 'reactflow';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  generateRandomCodeForNodes,
  removeAfterDot,
} from '../../../../../../utils';
import NodeBox from '../../layouts/NodeBox';
import { SignalIcon } from '@heroicons/react/20/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StatusSetNodeType } from '../../types';
import { ForwardIcon } from '@heroicons/react/24/outline';
import useWorkflowExecution from '../../../../../../hooks/useWorkflowExecution';
import { useFlags } from '../../../../../../hooks/useFlags';
import { useContext } from 'react';
import UsersContext from '../../../../../../contexts/UsersContext';
import CustomHandle from '../../handles/CustomHandle';
import { useStatus } from '../../../hooks/useStatuses';

const zoomSelector = (s: any) => s.transform[2] >= 0.7;

function StatusSetNode(props: NodeProps) {
  const { currentUser } = useContext(UsersContext);
  const { setSelectedNodeId } = useWorkflow();
  const { seek } = useWorkflowExecution();
  const { modelWorkflowExecutionSeekToNode } = useFlags();
  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);
  const showContent = useStore(zoomSelector);

  const statusCuid = props.data.state_callbacks.on_enter[0].args.cuid;
  const statusEntity = props.data.state_callbacks.on_enter[0].args.entity;

  const { data: status, isLoading } = useStatus(statusCuid);

  if (!statusCuid || !statusEntity) {
    return (
      <NodeBox
        title={'Status Change'}
        icon={SignalIcon}
        nodeProps={props}
        rounded={'sm'}
      >
        <VStack w={'full'} px={4} py={2}>
          {showContent && (
            <Text fontStyle={'oblique'} w={40} textAlign={'center'}>
              This step hasn't been configured yet.
            </Text>
          )}
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            size={'sm'}
            variant={'primary'}
          >
            Configure Status Change
          </Button>
        </VStack>
      </NodeBox>
    );
  }

  if (isLoading) {
    return (
      <NodeBox
        title={'Status Change'}
        icon={SignalIcon}
        nodeProps={props}
        rounded={'lg'}
      >
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'sm'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </NodeBox>
    );
  }

  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };

  const onSeekToHere = async () => {
    if (seek) {
      seek.mutate({ nodeTargetId: props.id });
    }
  };

  return (
    <NodeBox
      title={'Status Change'}
      icon={SignalIcon}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={showContent ? 'lg' : 'full'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />

      <>
        {seek && modelWorkflowExecutionSeekToNode && isAdmin && (
          <Tooltip label="Double click to transition here">
            <IconButton
              // isRound={true}
              style={{
                position: 'absolute',
                top: '.5rem',
                left: '-1.9rem',
                borderRight: 0,
                background: 'var(--chakra-colors-neutral-25)',
              }}
              borderColor={'neutral.200'}
              aria-label="Seek to Here"
              size={'sm'}
              roundedRight={'0'}
              icon={<Icon as={ForwardIcon} boxSize={6} />}
              onDoubleClick={onSeekToHere}
              isLoading={
                seek?.variables?.nodeTargetId == props.id && seek?.isLoading
              }
            />
          </Tooltip>
        )}
      </>

      <VStack
        w={'full'}
        alignItems={'center'}
        gap={1}
        px={showContent ? 4 : 2}
        py={2}
      >
        {showContent ? (
          <Text fontSize={'sm'}>
            {/* TODO: support validation report */}
            Set {statusEntity === 'InventoryModel' ? 'Model' : 'Document'}{' '}
            Status to{' '}
          </Text>
        ) : (
          ''
        )}

        <Tag
          data-testid="status-badge"
          size={showContent ? 'sm' : '2xl'}
          colorScheme={removeAfterDot(status!.colors.primary)}
        >
          {status!.name}
        </Tag>
      </VStack>

      <CustomHandle
        type="target"
        position={Position.Top}
        style={{ top: -6 }}
        id="top"
      />
      <CustomHandle
        type="target"
        position={Position.Right}
        style={{ right: -6 }}
        id="right"
      />
      <CustomHandle
        type="source"
        position={Position.Left}
        style={{ left: -6 }}
        id="left"
      />
      <CustomHandle
        type="source"
        position={Position.Bottom}
        style={{ bottom: -6 }}
        id="bottom"
      />
    </NodeBox>
  );
}

StatusSetNode.type = 'status_set';
StatusSetNode.autoRuns = true;

StatusSetNode.getDefaultNode = (): StatusSetNodeType => {
  return {
    id: `${StatusSetNode.type}_${generateRandomCodeForNodes()}`,
    type: StatusSetNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_save_status',
            args: { entity: null, cuid: null },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default StatusSetNode;
