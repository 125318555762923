import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Text, useToast } from '@chakra-ui/react';
import API from '../../../api/API';
import { TFinding } from '../../../models';
import RichTextContentEditor from '../../../components/RichTextContentEditor';
import InventoryModelContext from '../../../contexts/InventoryModel';

interface RemediationPlanTextBoxProps {
  finding: TFinding;
  allowEditMetadata: boolean;
}

export default function RemediationPlanTextBox({
  finding,
  allowEditMetadata = true,
}: RemediationPlanTextBoxProps) {
  const toast = useToast();
  const { inventoryModel } = useContext(InventoryModelContext);
  const [remediationPlan, setRemediationPlan] = useState('');

  useEffect(() => {
    setRemediationPlan(finding.metadata.remediationPlan || '');
  }, [finding]);

  const remediationPlanQueryKey = [
    'inventory-model',
    inventoryModel?.cuid,
    'findings',
    finding.cuid,
    'attachments',
  ];

  const mutation = useMutation(
    remediationPlanQueryKey,
    async (remediationPlan: string) => {
      return API.UpdateFinding(inventoryModel!, finding.cuid, {
        metadata: {
          ...finding.metadata,
          remediationPlan: remediationPlan,
        },
      });
    },
  );

  const onSaveClick = async (editorText: string) => {
    setRemediationPlan(editorText);
    mutation.mutate(editorText, {
      onSuccess: () => {
        toast({
          title: 'Findings',
          description: 'The remediation plan note has been saved.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });
  };

  return (
    <>
      {!allowEditMetadata && remediationPlan.length === 0 ? (
        <Text>No proposed remediation plan at this moment.</Text>
      ) : (
        <RichTextContentEditor
          text={remediationPlan}
          onSave={onSaveClick}
          allowEdit={allowEditMetadata}
          placeholder={'Propose the remediation plan here...'}
        />
      )}
    </>
  );
}
