import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import { Label } from '../../Layout';
import { GridViewItem } from '../../GridView/GridViewItem';
import { TWidget } from '../../../models/report';
import ActivityFeedWidget from '../../ActivityFeedWidget';
import FindingsWidget from '../../FindingsWidget';

const FieldSet = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <Stack
    w="full"
    p={2}
    border={1}
    borderRadius={'md'}
    borderStyle={'solid'}
    alignItems={'normal'}
    borderColor={'var(--chakra-colors-chakra-border-color)'}
    gap={2}
  >
    <Label>{label}</Label>
    {children}
  </Stack>
);

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (widget: TWidget) => Promise<void>;
}

const WIDGET_TYPE_LABEL_MAP = {
  'activity-feed': 'Activity Feed',
  'model-findings': 'Model Findings',
};

const AddWidgetToDashboardModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [selectedWidget, setSelectedWidget] = React.useState<TWidget>();
  const [isRenderReady, setIsRenderReady] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { isLoading, data: widgets } = useQuery(
    ['widgets'],
    async () => {
      return await API.GetWidgets();
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    if (isOpen && selectedWidget) {
      setTimeout(() => {
        setIsRenderReady(true);
      }, 500);
    } else {
      setIsRenderReady(false);
    }
  }, [isOpen, selectedWidget]);

  if (!isOpen) {
    return null;
  }

  const widgetOptions =
    widgets?.map(w => ({
      label: WIDGET_TYPE_LABEL_MAP[w.type],
      value: w.cuid,
    })) || [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'6xl'}
    >
      <ModalOverlay />
      <ModalContent
        h={selectedWidget ? '95vh' : 'auto'}
        w={selectedWidget ? '100%' : '33%'}
        transition={'all .3s ease-in-out'}
        maxW="95%"
        maxH="95vh"
      >
        <ModalHeader>Add Widget</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flex={1} overflow={'visible'}>
          <HStack spacing={4} flex={1}>
            <VStack
              minWidth={selectedWidget ? '33%' : '100%'}
              h="100%"
              align="flex-start"
            >
              <FieldSet label="Widgets">
                <Select
                  size={'md'}
                  colorScheme="brand"
                  isSearchable={false}
                  onChange={e => {
                    setSelectedWidget(widgets?.find(w => w.cuid === e?.value));
                  }}
                  options={widgetOptions}
                  value={widgetOptions?.find(
                    v => v.value === selectedWidget?.cuid,
                  )}
                />
              </FieldSet>
            </VStack>
            {selectedWidget && (
              <VStack h="full" flexGrow={1}>
                <Box
                  w="100%"
                  h="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isRenderReady && (
                    <GridViewItem
                      style={{
                        width: '90%',
                        maxHeight: '500px',
                        overflow: 'hidden',
                      }}
                      title={WIDGET_TYPE_LABEL_MAP[selectedWidget.type]}
                    >
                      {selectedWidget.type === 'activity-feed' && (
                        <ActivityFeedWidget variant="user-summary" />
                      )}
                      {selectedWidget.type === 'model-findings' && (
                        <FindingsWidget variant="user-summary" />
                      )}
                    </GridViewItem>
                  )}
                </Box>
              </VStack>
            )}
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isLoading={isSubmitting}
            variant={'primary'}
            onClick={async () => {
              if (selectedWidget) {
                setIsSubmitting(true);
                await onSubmit(selectedWidget);
                setIsSubmitting(false);
              }
            }}
            isDisabled={!selectedWidget || isLoading}
          >
            Add Widget
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddWidgetToDashboardModal;
