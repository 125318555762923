import {
  Button,
  CloseButton,
  FormControl,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { WaitNodeType, WaitType, WaitUnit } from '../../types';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { Label } from '../../../../../../components/Layout';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';
import useModelSchema from '../../../../../../hooks/useModelSchema';
import { convertLocalToUTC, convertUTCToLocal } from '../../../../../../utils';

interface WaitPanelProps {
  node: WaitNodeType;
  onAddNode: (node: WaitNodeType) => void;
  onDeleteNode: (node: WaitNodeType) => void;
}

const WaitPanel = ({ node, onAddNode, onDeleteNode }: WaitPanelProps) => {
  const { setSelectedNodeId } = useWorkflow();
  const [tempNode, setTempNode] = useState<WaitNodeType>(node);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { propertyItems } = useModelSchema();

  useEffect(() => {
    setTempNode(node);
  }, [node]);

  const onWaitTypeChange = (value: WaitType) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.wait_type = value;
      if (value === 'interval') {
        clonedNode.data.state_callbacks.on_enter[0].args.wait_unit = 'minutes';
        clonedNode.data.state_callbacks.on_enter[0].args.wait_amount = 1;
      }
      setTempNode(clonedNode);
    }
  };

  const onSave = () => {
    if (tempNode) {
      onAddNode(tempNode);
      setSelectedNodeId!();
    }
  };

  const onConfirmDeleteStep = (confirmed: boolean) => {
    if (tempNode && confirmed) {
      onDeleteNode(tempNode);
    }
    setConfirmDelete(false);
  };

  const onClose = () => {
    setSelectedNodeId!();
  };

  return (
    <>
      <Stack
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        p={4}
        rounded={4}
        shadow={'lg'}
      >
        <VStack gap={4} alignItems={'flex-start'}>
          <HStack w={'full'}>
            <Heading as={'h3'}>Configure Wait</Heading>
            <Spacer />
            <CloseButton onClick={onClose} />
          </HStack>

          <FormControl>
            <Label mb={2}>RESUME WORKFLOW:</Label>
            <RadioGroup
              onChange={value => onWaitTypeChange(value as WaitType)}
              value={
                tempNode?.data.state_callbacks.on_enter[0].args.wait_type || ''
              }
            >
              <Stack spacing={3}>
                <Radio
                  size={'lg'}
                  bg={'white'}
                  colorScheme="brand"
                  value={'interval' as WaitType}
                >
                  <Stack gap={0}>
                    <Text fontSize={'md'}>After Time Interval</Text>
                    <Text fontSize="sm" color="neutral.500">
                      Waits for a certain amount of time
                    </Text>
                  </Stack>
                </Radio>
                <Radio
                  size={'lg'}
                  colorScheme="brand"
                  value={'specific_date' as WaitType}
                >
                  <Text fontSize={'md'}>At Specified Date</Text>
                  <Text fontSize="sm" color="neutral.500">
                    Waits until a specific date to continue
                  </Text>
                </Radio>
                <Radio
                  size={'lg'}
                  colorScheme="brand"
                  value={'custom_field' as WaitType}
                >
                  <Text fontSize={'md'}> At Specified Date on Model Field</Text>
                  <Text fontSize="sm" color="neutral.500">
                    Waits until a specific date on a model field to continue
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          {/* Interval Configuration */}
          {tempNode?.data.state_callbacks.on_enter[0].args.wait_type ===
            'interval' && (
            <FormControl>
              <Label mb={2}>Wait Duration</Label>
              <HStack>
                <NumberInput
                  size="sm"
                  value={
                    tempNode.data.state_callbacks.on_enter[0].args
                      .wait_amount || 0
                  }
                  onChange={value => {
                    const clonedNode = _.cloneDeep(tempNode);
                    clonedNode.data.state_callbacks.on_enter[0].args.wait_amount =
                      parseInt(value);
                    setTempNode(clonedNode);
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select
                  size="sm"
                  value={
                    tempNode.data.state_callbacks.on_enter[0].args.wait_unit ||
                    'minutes'
                  }
                  onChange={e => {
                    const clonedNode = _.cloneDeep(tempNode);
                    clonedNode.data.state_callbacks.on_enter[0].args.wait_unit =
                      e.target.value as WaitUnit;
                    setTempNode(clonedNode);
                  }}
                >
                  <option value={'minutes' as WaitUnit}>Minutes</option>
                  <option value={'hours' as WaitUnit}>Hours</option>
                  <option value={'days' as WaitUnit}>Days</option>
                </Select>
              </HStack>
            </FormControl>
          )}

          {/* Specific Date Configuration */}
          {tempNode?.data.state_callbacks.on_enter[0].args.wait_type ===
            'specific_date' && (
            <FormControl>
              <Label mb={2}>Wait Until</Label>
              {/* This input needs to display dates in local timezone but store them in UTC */}
              <Input
                size="sm"
                type="datetime-local"
                value={
                  convertUTCToLocal(
                    tempNode.data.state_callbacks.on_enter[0].args
                      .specific_datetime || '',
                  ) || ''
                }
                onChange={e => {
                  const localDateTime = e.target.value; // Get local datetime from input
                  const utcDateTime = convertLocalToUTC(localDateTime); // Convert to UTC
                  const clonedNode = _.cloneDeep(tempNode);
                  clonedNode.data.state_callbacks.on_enter[0].args.specific_datetime =
                    utcDateTime;
                  setTempNode(clonedNode);
                }}
              />
            </FormControl>
          )}

          {/* Custom Field Configuration */}
          {tempNode?.data.state_callbacks.on_enter[0].args.wait_type ===
            'custom_field' && (
            <FormControl>
              <Label mb={2}>MODEL FIELD</Label>
              <Select
                size="sm"
                value={
                  tempNode.data.state_callbacks.on_enter[0].args.custom_field ||
                  ''
                }
                onChange={e => {
                  const clonedNode = _.cloneDeep(tempNode);
                  clonedNode.data.state_callbacks.on_enter[0].args.custom_field =
                    e.target.value;
                  setTempNode(clonedNode);
                }}
                placeholder="Select a field"
              >
                {propertyItems
                  .filter(
                    field =>
                      field.typeId === 'string:date' ||
                      field.typeId === 'string:date-time' ||
                      field.typeId === 'string:code-python',
                  )
                  .map(field => (
                    <option key={field.key} value={field.key}>
                      {field.title}
                      {field.typeId === 'string:code-python' && ' (Calculated)'}
                    </option>
                  ))}
              </Select>
              <Text fontSize="sm" color="gray.500" mt={2}>
                When selecting a calculated field, it must return an ISO 8601
                date.
              </Text>
            </FormControl>
          )}

          <HStack justifyContent={'space-between'} w={'full'}>
            <Button
              size={'sm'}
              onClick={() => setConfirmDelete(true)}
              _hover={{
                bg: 'red.100',
                color: 'red.700',
              }}
              variant={'ghost'}
            >
              Delete Step
            </Button>
            <Button size={'sm'} onClick={onSave} variant={'primary'}>
              Update Step
            </Button>
          </HStack>
        </VStack>
      </Stack>
      <ConfirmationAlert
        title={`Deleting step`}
        dialogBody={'Are you sure?'}
        open={confirmDelete}
        onConfirm={onConfirmDeleteStep}
      />
    </>
  );
};

export default WaitPanel;
