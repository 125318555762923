import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
} from '@chakra-ui/react';
import API from '../../api/API';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PermissionsList from '../PermissionsList';
import { TRolePermissions } from '../../models/role';

type Props = {
  isOpen: boolean;
  roleDetails?: TRolePermissions;
  onSuccess: () => void;
  onClose: () => void;
  permissionsScope?: string;
};

export default function EditPermissionsModal({
  isOpen,
  roleDetails,
  onClose,
  onSuccess,
  permissionsScope,
}: Props) {
  const [newRoleDetails, setNewRoleDetails] = useState<TRolePermissions>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (roleDetails) {
      setNewRoleDetails(_.cloneDeep(roleDetails));
    }
  }, [roleDetails]);

  const handleSavePermissions = useCallback(async () => {
    setIsLoading(true);
    const existingPermissions = Object.keys(roleDetails!.categories)
      .flatMap((categoryKey: string) =>
        roleDetails!.categories[categoryKey].filter(
          permission => permission.assigned,
        ),
      )
      .map(action => action.action_id);

    const newPermissions = Object.keys(newRoleDetails!.categories)
      .flatMap((categoryKey: string) =>
        newRoleDetails!.categories[categoryKey].filter(
          permission => permission.assigned,
        ),
      )
      .map(action => action.action_id);

    // find added permissions
    const addedPermissions = newPermissions.filter(
      permission => !existingPermissions.includes(permission),
    );

    // find removed permissions
    const removedPermissions = existingPermissions.filter(
      permission => !newPermissions.includes(permission),
    );

    await API.PatchRolePermissions(roleDetails!.role.cuid, {
      added_permissions: addedPermissions,
      deleted_permissions: removedPermissions,
    });

    setIsLoading(false);
    onSuccess();
    onClose();
  }, [roleDetails, newRoleDetails, onSuccess]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalBody>
          <ModalHeader pl={0}>Edit Permissions</ModalHeader>
          <ModalCloseButton />
          <VStack w="full" alignItems="flex-start" spacing={6}>
            <Text>
              Add and/or remove permissions to create your desired role.
            </Text>
            <PermissionsList
              mode="edit"
              categories={newRoleDetails?.categories}
              permissionsScope={newRoleDetails?.role.scope}
              onPermissionChange={changes => {
                if (!newRoleDetails) return;
                const newRoleDetailsCopy = _.cloneDeep(newRoleDetails);
                changes.forEach(change => {
                  newRoleDetailsCopy.categories[change.category][
                    change.index
                  ].assigned = change.value;
                });
                setNewRoleDetails(newRoleDetailsCopy);
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={handleSavePermissions}
          >
            Save Permissions
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
