import { useQuery } from 'react-query';
import API from '../../api/API';
import { TInventoryModel } from '../../models/inventory_model';

interface UseValidationGuidelineHookProps {
  inventoryModel: TInventoryModel;
  content_id: string;
}
export default function useValidationGuideline({
  inventoryModel,
  content_id,
}: UseValidationGuidelineHookProps) {
  const queryResponse = useQuery(
    [
      'inventory-model',
      inventoryModel.cuid,
      'validation_guideline',
      content_id,
    ],
    async () => {
      return API.GetValidationGuideline(inventoryModel, content_id);
    },
    {
      retry: false,
    },
  );

  return { testResult: queryResponse.data, ...queryResponse };
}
