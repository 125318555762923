import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI, { PatchUserRequest, Role } from '../../api/AdminAPI';

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

export function EditUserModal({ isOpen, onClose, user }: EditUserModalProps) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const queryClient = useQueryClient();
  const toast = useToast();

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.name);
      setLastName(user.last_name || '');
    }
  }, [user, isOpen]);

  const updateUserMutation = useMutation({
    mutationFn: async (patchData: PatchUserRequest) => {
      return AdminAPI.PatchUser(user.cuid, patchData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      toast({
        title: 'User updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    },
    onError: (error: any) => {
      toast({
        title: 'Error updating user',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleSubmit = () => {
    const updateData: PatchUserRequest = {
      email,
      name: firstName,
      last_name: lastName,
      organization: {
        organization_cuid: user.organizations[0].cuid,
        roles: user.organizations[0].roles.map((role: Role) => role.cuid),
      },
    };

    updateUserMutation.mutate(updateData);
  };

  const handleClose = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                disabled={true}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter user's email"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                placeholder="Enter user's first name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder="Enter user's last name"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="tertiary" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            isLoading={updateUserMutation.isLoading}
            isDisabled={!email || !firstName || !lastName}
          >
            Update User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
