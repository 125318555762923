import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import MasterPage from '../../../components/Layout/MasterPage';
import InventoryModelContext, {
  InventoryModelTemplates,
} from '../../../contexts/InventoryModel';
import { LoadingContainer } from '../../../components/LoadingContainer';
import MasterDetails from '../../../components/Layout/MasterDetails';
import InventoryModelOverview from './Overview';
import { UsersContext } from '../../../contexts';
import InventoryModelFindings from './Findings';
import InventoryModelActivity from './Activity';
import { KBarProvider } from 'kbar';
import CommandPalette from '../../../components/CommandPalette';
import SidebarContext from '../../../contexts/SidebarContext';
import ForbiddenAlert from '../../../components/ForbiddenAlert';
import GetStartedChecklist from '../../../components/GetStartedChecklist';
import { useCurrentModel } from '../../../hooks/useModelInventory';
import {
  addIndexNumbersToSections,
  convertSectionsToLookup,
  convertToSectionTree,
} from '../../../models/template';
import { TInventoryModel } from '../../../models/inventory_model';
import ModelInventoryOverview from '../../ModelInventoryOverview';

export default function InventoryModel() {
  const currentPath = useLocation();
  const navigate = useNavigate();
  const { currentOrganization } = useContext(UsersContext);
  const { setInventoryModel: setSidebarInventoryModel } =
    useContext(SidebarContext);
  const { id } = useParams();
  const [isForbidden, setIsForbidden] = useState(false);
  const { templates: initialTemplates } = useContext(InventoryModelContext);
  const [templates, setTemplates] = useState(initialTemplates);

  const { inventoryModel, refetch } = useCurrentModel();

  // Any dependency change will trigger a refetch
  const setInventoryModel = (inventoryModel: TInventoryModel) => {
    refetch();
  };

  useEffect(() => {
    // Redirect to model overview when visiting the model base path
    if (currentPath.pathname.endsWith(id!)) {
      navigate('overview', { replace: true });
    }
  }, [currentPath.pathname, inventoryModel]);

  useEffect(() => {
    if (inventoryModel) {
      setSidebarInventoryModel(inventoryModel);
    }
    return () => {
      // Resets the sidebar inventory model when leaving the inventory model page
      setSidebarInventoryModel(undefined);
    };
  }, [inventoryModel]);

  const { data: inventoryModelPermissions } = useQuery(
    [
      'inventory-model',
      inventoryModel?.cuid,
      'permissions',
      inventoryModel?.cuid,
      currentOrganization?.cuid,
    ],
    async () => {
      return API.GetUserPermissions('Model', inventoryModel!.cuid);
    },
    {
      enabled: !!inventoryModel?.cuid,
      staleTime: 1000 * 60 * 10,
      onError: err => {
        // track errors
      },
    },
  );

  // Load the necessary template context
  useEffect(() => {
    if (inventoryModel) {
      const allTemplates: InventoryModelTemplates = {
        ...templates,
        get isReady() {
          return (
            Object.keys(allTemplates.documentation.lookup).length > 0 ||
            Object.keys(allTemplates.validation_report.lookup).length > 0 ||
            Object.keys(allTemplates.monitoring.lookup).length > 0
          );
        },
        get isOfflineDocumentation() {
          return inventoryModel.template.is_offline;
        },
        get isOfflineValidationReport() {
          return inventoryModel.template_vr.is_offline;
        },
        get isOfflineMonitoring() {
          return inventoryModel.template_monitoring.is_offline;
        },
      };

      type templateKeyType = 'template' | 'template_vr' | 'template_monitoring';
      const templateKeys: templateKeyType[] = [
        'template',
        'template_vr',
        'template_monitoring',
      ];
      templateKeys.forEach((templateKey: templateKeyType) => {
        // for offline docs there are not templates defined in db

        if (inventoryModel[templateKey].is_offline) {
          return;
        }

        const startIndex = inventoryModel[templateKey].template.start_index;
        const templateSections = inventoryModel[templateKey].template.sections;

        const lookup = convertSectionsToLookup(templateSections);
        const sectionTree = convertToSectionTree(templateSections);
        addIndexNumbersToSections(sectionTree, lookup, '', startIndex);

        if (templateKey === 'template_vr') {
          allTemplates.validation_report.sections = templateSections;
          allTemplates.validation_report.sectionTree = sectionTree;
          allTemplates.validation_report.lookup = lookup;
        } else if (templateKey === 'template_monitoring') {
          allTemplates.monitoring.sections = templateSections;
          allTemplates.monitoring.sectionTree = sectionTree;
          allTemplates.monitoring.lookup = lookup;
        } else {
          allTemplates.documentation.sections = templateSections;
          allTemplates.documentation.sectionTree = sectionTree;
          allTemplates.documentation.lookup = lookup;
        }
      });

      setTemplates(allTemplates);
    }
  }, [inventoryModel]);

  const userHasInventoryModelPermission = useCallback(
    (actions: string[], match: 'any' | 'all') => {
      if (!inventoryModelPermissions) return false;
      if (match === 'any') {
        return actions.some(action =>
          inventoryModelPermissions.some(permission => permission === action),
        );
      } else {
        return actions.every(action =>
          inventoryModelPermissions.some(permission => permission === action),
        );
      }
    },
    [inventoryModelPermissions],
  );

  if (isForbidden) {
    return (
      <ForbiddenAlert
        title={'You are not allowed to access this model'}
        description={'ask to your administrator for more information.'}
      />
    );
  }

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
    >
      <InventoryModelContext.Provider
        value={{
          inventoryModel,
          userHasInventoryModelPermission,
          setInventoryModel,
          setTemplates,
          templates,
        }}
      >
        <CommandPalette />
        <MasterPage>
          <MasterDetails>
            <LoadingContainer isLoading={!inventoryModel}>
              <Routes>
                <Route path="overview" element={<InventoryModelOverview />} />
                <Route path="findings" element={<InventoryModelFindings />} />
                <Route path="activity" element={<InventoryModelActivity />} />
                <Route path="*" element={<ModelInventoryOverview />} />
              </Routes>
            </LoadingContainer>
          </MasterDetails>
          <GetStartedChecklist />
        </MasterPage>
      </InventoryModelContext.Provider>
    </KBarProvider>
  );
}
