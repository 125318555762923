import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TInventoryModelMetadata } from '../../models';
import { CKEditorWrapper } from './CKEditorWrapper';
import {
  Box,
  Center,
  Collapse,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AIGenerationConfig } from './AITextContentEditor';
import { BlockWrapperContext } from '../Templates/BlockWrapper';

interface ITextContentEditorProps {
  text: string;
  metadata?: TInventoryModelMetadata; // Optional Content ID for metadata based editors
  isLoading?: boolean;
  onSave: (editorText: string, silent?: boolean, documentType?: string) => void;
  onCancel?: () => void;
  onSaveRevisions?: (revisionsData: any, documentType?: string) => void;
  containerSize?: string;
  allowEdit?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  removeBlock?: any;
  aiGenerationConfig?: AIGenerationConfig;
}

export function TextContentEditor({
  text,
  metadata,
  isLoading = false,
  onSave,
  onSaveRevisions,
  allowEdit = true,
  setIsEditing,
  removeBlock,
  aiGenerationConfig,
}: ITextContentEditorProps) {
  let [searchParams] = useSearchParams();
  const [editor, setEditor] = useState<any>();
  const [editorReady, setEditorReady] = useState(false);
  const [
    allowEditFromEmptyOrGeneratedText,
    setAllowEditFromEmptyOrGeneratedText,
  ] = useState(false);

  const [highlightedKeywords, setHighlightedKeywords] = useState<string[]>([]);

  const { setFocusEditorFn } = useContext(BlockWrapperContext);

  useEffect(() => {
    const keywords = searchParams.get('keywords');
    if (keywords) {
      setHighlightedKeywords(keywords.split(','));
    }
  }, []);

  useEffect(() => {
    // let know the upper component if the editor is open or not
    setIsEditing && setIsEditing(editorReady);
  }, [editorReady]);

  const onStartEditing = () => {
    if (allowEdit) {
      setAllowEditFromEmptyOrGeneratedText(true);
      setTimeout(() => {
        editor.focus();
      }, 100);
    }
  };

  if (isLoading) {
    return (
      <Stack mt={4} spacing={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }

  const this_section_hasnt_been_documented_yet = allowEdit ? (
    <Text fontStyle={'oblique'} color={'neutral.500'} textAlign={'center'}>
      Start editing by typing here...
    </Text>
  ) : null;

  // Show the text editor under two conditions:
  //
  // 1. Text is not empty and not loading, we're ready to show the editor
  // 2. The user has clicked on the empty space to start editing or
  //    they have click on the AI suggestion button
  const showEditor =
    (text !== '' || allowEditFromEmptyOrGeneratedText) && !isLoading;

  return (
    <>
      <Box w={'full'} hidden={!showEditor}>
        <CKEditorWrapper
          text={text}
          metadata={metadata}
          readOnly={!allowEdit}
          onSave={onSave}
          onSaveRevisions={onSaveRevisions}
          searchKeywords={highlightedKeywords}
          setEditorToParents={setEditor}
          aiGenerationConfig={aiGenerationConfig}
          onEditorReady={(ready, editor) => {
            setFocusEditorFn(() => () => editor.editing.view.focus());
          }}
          enabledFeatures={{
            images: true,
            comments: true,
            revisions: true,
            deleteBlock: false,
            generateWithAI: aiGenerationConfig ? true : false,
          }}
        />
      </Box>
      <Collapse in={!showEditor}>
        <Center width={'full'}>
          <Stack w={'full'}>
            {allowEdit ? (
              <Stack
                alignItems={'center'}
                py={2}
                bg={'trasparent'}
                w={'full'}
                borderRadius={'md'}
                border={'1px solid'}
                borderColor={'transparent'}
                _hover={{
                  borderColor: useColorModeValue('neutral.200', 'neutral.700'),
                }}
                transition={'all .5s ease-in-out'}
                onClick={onStartEditing}
                cursor={'text'}
              >
                {this_section_hasnt_been_documented_yet}
              </Stack>
            ) : (
              <>{this_section_hasnt_been_documented_yet}</>
            )}
          </Stack>
        </Center>
      </Collapse>
    </>
  );
}
