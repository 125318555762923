import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Text,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { useAuth } from 'react-oidc-context';

export default function AcceptInvitationButton({
  inviteCuid,
}: {
  inviteCuid: string;
}) {
  const { signinRedirect } = useAuth();
  const { data, isLoading } = useQuery([], async () => {
    return API.GetInvitationCheck(inviteCuid);
  });

  if (isLoading) {
    return <Text>Please wait...</Text>;
  }

  if (data?.result === false) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>This invitation expired.</AlertDescription>
      </Alert>
    );
  }

  const appState = {
    inviteCuid: inviteCuid,
  };
  const appStateJSON = encodeURIComponent(JSON.stringify(appState));

  if (data?.user_exists === true) {
    const options = {
      state: { appStateJSON: appStateJSON },
    };
    return (
      <Button
        onClick={() => {
          signinRedirect(options);
        }}
      >
        Accept Invitation
      </Button>
    );
  }

  const newUserAppState = {
    inviteCuid: inviteCuid,
    isNewUser: true,
  };

  const newUserAppStateJSON = encodeURIComponent(
    JSON.stringify(newUserAppState),
  );

  const options = {
    state: { appStateJSON: newUserAppStateJSON },
    screen_hint: 'signup',
  };

  return (
    <Button onClick={() => signinRedirect(options)}>Accept Invitation</Button>
  );
}
