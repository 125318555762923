import {
  VStack,
  Heading,
  Text,
  Button,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ValidMindLogo from '../components/ValidMindLogo';
import { useAuth } from 'react-oidc-context';

const NotFound = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Box
      height="100vh"
      bg={useColorModeValue('neutral.25', 'black')}
      color={useColorModeValue('neutral.800', 'white')}
      pt="30vh"
    >
      <VStack spacing={12} maxW="container.sm" px={8} mx="auto">
        <Box color="brand.base">
          <ValidMindLogo />
        </Box>

        <VStack spacing={6}>
          <Heading as="h1" size="xl">
            404 Not Found
          </Heading>
          <Text fontSize="lg" textAlign="center" maxWidth="prose">
            Sorry, the page you are looking for could not be found.
          </Text>
          {isAuthenticated && (
            <Button variant="primary" as={Link} to="/" size="lg">
              Go to Home
            </Button>
          )}
        </VStack>
      </VStack>
    </Box>
  );
};

export default NotFound;
