import { defineStyleConfig } from '@chakra-ui/react';

export const Radio = defineStyleConfig({
  baseStyle: {
    label: {
      marginInlineStart: 0,
    },
    control: {
      background: 'white',
      marginRight: '1rem',
      marginLeft: '.5rem',

      _dark: {
        background: 'neutral.900',
      },
      _checked: {
        _dark: {
          background: 'brand.base',
          color: 'white',
        },
      },
    },
  },

  // The default size and variant values
  defaultProps: {
    size: 'lg',
    colorScheme: 'brand',
  },
});
