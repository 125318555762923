import React, { useEffect, useState } from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  DragStartEvent,
  DragOverEvent,
  useDroppable,
  DragOverlay,
  CollisionDetection,
  rectIntersection,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Text,
  HStack,
  Icon,
  Switch,
  InputGroup,
  Input,
  InputLeftElement,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import {
  TInventoryModelLayout,
  TModelInventoryLayoutItem,
} from '../../models/inventory_model';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleIcon } from '@chakra-ui/icons';
import { FunnelIcon } from '@heroicons/react/24/outline';

interface EditModelLayoutModalProps {
  existingLayout: TInventoryModelLayout;
  isOpen: boolean;
  onClose: () => void;
  onSave: (newLayout: TInventoryModelLayout) => Promise<void>;
}

interface DraggableItemProps {
  item: TModelInventoryLayoutItem;
  disableDrag?: boolean;
  onToggle?: (item: TModelInventoryLayoutItem) => void;
}

interface ColumnProps {
  id: string;
  width?: string;
  label: string;
  columns: TModelInventoryLayoutItem[];
  filter?: string;
  beforeItems: TModelInventoryLayoutItem[];
  afterItems: TModelInventoryLayoutItem[];
  onItemToggle: (item: TModelInventoryLayoutItem) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  item,
  disableDrag,
  onToggle,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({ id: item.propertyKey });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const cursor = disableDrag ? 'default' : 'grab';
  const hover = disableDrag
    ? {}
    : { bg: useColorModeValue('brandSecondary.25', 'neutral.800') };

  return (
    <HStack
      style={style}
      w={'full'}
      borderWidth={1}
      borderRadius="md"
      opacity={active?.id === item.propertyKey && !disableDrag ? 0.2 : 1}
      cursor={cursor}
      bg={useColorModeValue('white', 'neutral.850')}
      borderColor={useColorModeValue('neutral.200', 'neutral.800')}
      _hover={hover}
      transition={'all .3s ease-in-out'}
      pl={4}
      pr={2}
      py={2}
      role="group"
      color={!onToggle ? 'neutral.500' : 'inherit'}
    >
      {onToggle ? (
        <Switch
          isChecked={!item.isOmitted}
          onChange={() => onToggle(item)}
          colorScheme={'brand'}
        />
      ) : (
        <Switch isChecked={true} isDisabled={true} colorScheme={'brand'} />
      )}
      <HStack
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        width={'full'}
        justifyContent={'space-between'}
        cursor={cursor}
      >
        <Text cursor={cursor} userSelect="none">
          {item.label}
        </Text>
        {!disableDrag && (
          <Icon
            as={DragHandleIcon}
            boxSize={3}
            cursor={cursor}
            opacity={0}
            _groupHover={{
              opacity: 1,
            }}
          />
        )}
      </HStack>
    </HStack>
  );
};

type OverlayProps = {
  item: TModelInventoryLayoutItem;
};

const Overlay: React.FC<OverlayProps> = ({ item }) => {
  return (
    <HStack
      p={2}
      bgColor={'neutral.50'}
      borderWidth={1}
      borderRadius="md"
      bg={useColorModeValue('brandSecondary.25', 'neutral.800')}
      borderColor={useColorModeValue('brandSecondary.50', 'neutral.850')}
      color={useColorModeValue('brandSecondary.500', 'neutral.500')}
      cursor={'grab'}
      shadow={'lg'}
    >
      <Text userSelect="none">{item.label}</Text>
    </HStack>
  );
};

const Column: React.FC<ColumnProps> = ({
  id,
  columns,
  label,
  width,
  filter,
  beforeItems,
  afterItems,
  onItemToggle,
}) => {
  const { setNodeRef, isOver } = useDroppable({ id });

  return (
    <SortableContext
      items={columns.map(c => c.propertyKey)}
      strategy={verticalListSortingStrategy}
      disabled={!!filter}
    >
      <VStack
        align="flex-start"
        h={'full'}
        rounded={'md'}
        border="1px solid"
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        bg={useColorModeValue('white', 'neutral.1000')}
        p={4}
        minWidth={width}
        flexGrow={1}
        gap={1}
        overflowY="auto" // Enable scrolling when content overflow
      >
        <Heading as={'h4'}>{label}</Heading>
        {
          // Render items before the main list
          !filter &&
            beforeItems.map(item => (
              <DraggableItem key={item.propertyKey} item={item} disableDrag />
            ))
        }
        <VStack ref={setNodeRef} borderRadius="md" width={'full'} gap={1}>
          {columns.length > 0 ? (
            columns
              .filter(col => {
                if (!filter) return true;
                return col.label.toLowerCase().includes(filter.toLowerCase());
              })
              .map(item => (
                <DraggableItem
                  key={item.propertyKey}
                  item={item}
                  disableDrag={!!filter}
                  onToggle={onItemToggle}
                />
              ))
          ) : (
            <div></div> // Placeholder for empty columns
          )}
        </VStack>
        {
          // Render items after the main list
          !filter &&
            afterItems.map(item => (
              <DraggableItem key={item.propertyKey} item={item} disableDrag />
            ))
        }
      </VStack>
    </SortableContext>
  );
};

// Items before custom fields
const beforeItems = {
  main: [
    {
      propertyKey: 'static_owners',
      label: 'Model Stakeholders (Owners, Developers and Validators)',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_model_interdependencies',
      label: 'Model Interdependencies',
      typeId: 'string',
      isOmitted: false,
    },
  ],
  side: [
    {
      propertyKey: 'static_id',
      label: 'ID',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_tier',
      label: 'Tier',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_stage',
      label: 'Model Stage',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_status',
      label: 'Model Status',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_group',
      label: 'Group',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_business_unit',
      label: 'Business Unit',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_use_case',
      label: 'Use Case',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_is_vendor_model',
      label: 'Is Vendor Model',
      typeId: 'boolean',
      isOmitted: false,
    },
    {
      propertyKey: 'static_vendor_name',
      label: 'Vendor Name',
      typeId: 'string',
      isOmitted: false,
    },
  ],
};

// Items after custom fields
const afterItems = {
  main: [
    {
      propertyKey: 'static_model_findings',
      label: 'Model Findings',
      typeId: 'string',
      isOmitted: false,
    },
    {
      propertyKey: 'static_recent_activity',
      label: 'Recent Activity',
      typeId: 'string',
      isOmitted: false,
    },
  ],
  side: [],
};

const EditModelLayoutModal: React.FC<EditModelLayoutModalProps> = ({
  existingLayout,
  isOpen,
  onClose,
  onSave,
}) => {
  const [columns, setColumns] = useState<
    Record<string, TModelInventoryLayoutItem[]>
  >({
    main: [],
    side: [],
  });

  const [filterText, setFilterText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (existingLayout) {
      setColumns({
        main: [...existingLayout.layout.mainColumnItems],
        side: [...existingLayout.layout.sideColumnItems],
      });
    }
  }, [existingLayout]);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
  );

  function findContainer(id: UniqueIdentifier) {
    if (columns[id]) {
      return id; // This handles cases where the column ID itself is passed
    }

    return Object.keys(columns).find(key =>
      columns[key].some(item => item.propertyKey === id),
    );
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;
    const { id: activeId } = active;
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return; // No change if active and over are in the same container
    }

    // Is the item in the main column?
    const foundItem = columns.main.find(item => item.propertyKey === activeId);

    // If so, check if it's an item that should not be moved to the side column
    if (foundItem) {
      if (
        foundItem.typeId === 'string:multi-line' ||
        foundItem.typeId === 'array:attachments'
      ) {
        return; // Do not allow these items to be moved to the side column
      }
    }

    setColumns(prev => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(
        item => item.propertyKey === activeId,
      );
      const overIndex = overItems.findIndex(
        item => item.propertyKey === overId,
      );

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = over && overIndex === overItems.length - 1;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter(
            (item: any) => item.propertyKey !== activeId,
          ),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          columns[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  const fixCursorSnapOffset: CollisionDetection = (args: any) => {
    // Bail out if keyboard activated
    if (!args.pointerCoordinates) {
      return rectIntersection(args);
    }
    const { x, y } = args.pointerCoordinates;
    const { width, height } = args.collisionRect;
    const updated = {
      ...args,
      // The collision rectangle is broken when using snapCenterToCursor. Reset
      // the collision rectangle based on pointer location and overlay size.
      collisionRect: {
        width,
        height,
        bottom: y + height / 2,
        left: x - width / 2,
        right: x + width / 2,
        top: y - height / 2,
      },
    };
    return rectIntersection(updated);
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = columns[activeContainer].findIndex(
      (item: any) => item.propertyKey === id,
    );

    const overIndex = columns[overContainer].findIndex(
      (item: any) => item.propertyKey === overId,
    );

    if (activeIndex !== overIndex) {
      setColumns((columns: any) => ({
        ...columns,
        [overContainer]: arrayMove(
          columns[overContainer],
          activeIndex,
          overIndex,
        ),
      }));
    }

    setActiveId(null);
  }

  const saveNewLayout = async () => {
    setIsSaving(true);
    await onSave({
      ...existingLayout,
      layout: {
        mainColumnItems: columns.main,
        sideColumnItems: columns.side,
      },
    });
    setIsSaving(false);
    onClose();
  };

  const activeItem =
    columns.main.find(item => item.propertyKey === activeId) ||
    columns.side.find(item => item.propertyKey === activeId);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="100%" maxW="95%">
        <ModalHeader>
          <Heading as={'h3'}>Customize Section Layout</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4} alignItems={'self-start'}>
            <Text>
              Customize the fields on your Model Overview page by toggling them
              on or off and rearranging their order. Your changes are personal
              and won’t affect anyone else in your organization.
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={FunnelIcon} boxSize={5} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Filter by typing the field to add and hide"
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
              />
            </InputGroup>
            <DndContext
              sensors={sensors}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              collisionDetection={fixCursorSnapOffset}
            >
              <HStack
                gap={4}
                justifyContent="flex-start"
                alignItems="flex-start"
                w={'full'}
                h={'75vh'}
              >
                {Object.entries(columns).map(([id, col], idx) => (
                  <Column
                    label={idx === 0 ? 'Main Column' : 'Side Column'}
                    width={idx === 0 ? '60%' : undefined}
                    key={id}
                    id={id}
                    columns={col}
                    filter={filterText}
                    beforeItems={beforeItems[id as keyof typeof beforeItems]}
                    afterItems={afterItems[id as keyof typeof afterItems]}
                    onItemToggle={item =>
                      setColumns(prev => {
                        const column = prev[id];
                        const itemIndex = column.findIndex(
                          i => i.propertyKey === item.propertyKey,
                        );
                        column[itemIndex] = {
                          ...column[itemIndex],
                          isOmitted: !column[itemIndex].isOmitted,
                        };
                        return {
                          ...prev,
                          [id]: column,
                        };
                      })
                    }
                  />
                ))}
              </HStack>
              <DragOverlay>
                {activeId && activeItem ? <Overlay item={activeItem} /> : null}
              </DragOverlay>
            </DndContext>
          </VStack>
        </ModalBody>
        <ModalFooter
          position={'sticky'}
          bottom={0}
          bg={useColorModeValue('neutral.25', 'neutral.950')}
        >
          <HStack>
            <Button isDisabled={isSaving} variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isDisabled={isSaving}
              isLoading={isSaving}
              colorScheme="blue"
              onClick={saveNewLayout}
              variant={'primary'}
            >
              Save Layout
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModelLayoutModal;
