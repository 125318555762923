import {
  HStack,
  Heading,
  Spacer,
  Tag,
  TagLabel,
  Text,
  AvatarGroup,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { TGroup, TUser } from '../../models';
import AvatarProxy from '../AvatarProxy';

export interface TGroupWithMembers extends TGroup {
  members: TUser[];
}

interface GroupViewProps {
  group: TGroupWithMembers;
  menu?: React.ReactNode;
  collapseButton?: React.ReactNode;
}

export default function GroupView({
  group,
  menu,
  collapseButton,
}: GroupViewProps) {
  return (
    <Stack>
      <HStack>
        <Heading as="h4">{group.name}</Heading>
        {group.is_default ? (
          <Tag>
            <TagLabel>DEFAULT GROUP</TagLabel>
          </Tag>
        ) : null}
        <HStack my={1}>
          <AvatarGroup size="sm" max={5}>
            {group.members.map((user: TUser) => {
              return <AvatarProxy name={user.name} src={user.picture} />;
            })}
          </AvatarGroup>
          <Text fontSize={'sm'}>{`${group.members.length} Member${
            group.members.length > 1 ? 's' : ''
          }`}</Text>
        </HStack>
        <Spacer />
        {menu}
      </HStack>
      <HStack>
        <Text>{group.description ?? group.description}</Text>
        <Spacer />
      </HStack>
    </Stack>
  );
}
