import { useNavigate } from 'react-router-dom';
import { oidcConfig } from '../config';
import { AuthProvider } from 'react-oidc-context';

export const OIDCProviderWithHistory = ({ children }: any) => {
  const navigate = useNavigate();

  const onSigninCallback = (user: any) => {
    const appState = user?.state;
    if (appState && appState.appStateJSON) {
      const appStateJSON = appState.appStateJSON;
      const parsedAppStateJSON = JSON.parse(decodeURIComponent(appStateJSON));
      const isInvitedUser = !!parsedAppStateJSON?.isNewUser;

      const inviteQuery = isInvitedUser ? '&invitedUser=true' : '';

      const queryString = `?appStateJSON=${appStateJSON}${inviteQuery}`;
      window.location.search = queryString;
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
      {children}
    </AuthProvider>
  );
};
