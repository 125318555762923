export default class RevisionHistoryButtonPlugin {
  private editor: any;

  constructor(editor: any) {
    this.editor = editor;
  }

  init() {
    const editor = this.editor;

    editor.on('ready', () => {
      if (editor.ui.view.stickyPanel) {
        const targetDiv = editor.ui.view.stickyPanel.contentPanelElement;

        if (targetDiv) {
          const buttonContainer = targetDiv.querySelector('.button_container');

          if (!buttonContainer) {
            const newDiv = document.createElement('div');
            newDiv.className = 'button_container';
            newDiv.style.backgroundColor = 'transparent';
            newDiv.style.padding = '5px 0px 5px 5px';
            newDiv.style.marginBottom = '10px';
            newDiv.style.borderBottom = '1px solid var(--ck-color-base-border)';

            targetDiv.prepend(newDiv);

            const backToEditingButton =
              editor.ui.view.toolbar.element.querySelector(
                '[data-cke-tooltip-text="Back to editing"]',
              );

            if (backToEditingButton) {
              newDiv.appendChild(backToEditingButton);
            } else {
              console.error(
                'Button with data-cke-tooltip-text "Back to editing" not found.',
              );
            }

            const toolbarItemsDiv =
              editor.ui.view.element.querySelector('[role="toolbar"]');

            if (toolbarItemsDiv) {
              const restoreButton = toolbarItemsDiv.querySelector(
                '[data-cke-tooltip-text="Restore this revision"]',
              );

              if (restoreButton) {
                restoreButton.style.marginLeft = '5px';
                restoreButton.style.marginRight = '10px';
                (toolbarItemsDiv as HTMLElement).style.marginBottom = '10px';

                toolbarItemsDiv.appendChild(restoreButton);
              } else {
                console.error(
                  '"Restore this revision" button not found in the toolbar.',
                );
              }
            } else {
              console.error('Toolbar items container not found.');
            }
          }
        } else {
          console.error('Target div (sticky panel content) not found.');
        }
      }
    });
  }
}
