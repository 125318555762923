import {
  TrackChangesAdapterSuggestionAdded,
  TrackChangesAdapterSuggestionUpdated,
} from '../../models/ckeditor';
import API from '../../api/API';
import { ActivityFeedWidgetQueryKey } from '../ActivityFeedWidget';

export class TrackChangesAdapter {
  private editor: any;
  constructor(editor: any) {
    this.editor = editor;
  }

  init() {
    // console.log('TrackChangesAdapter init', this.editor);

    // Revision history integration is trying to create a different instance of the adapter
    // and it won't bring the injected context values, return here when that happens.
    if (!this.editor.config._config.extraContext) {
      return;
    }

    const { inventoryModel, metadata, toast, queryClient } =
      this.editor.config._config.extraContext;

    if (!metadata) {
      return;
    }

    const trackChanges = this.editor.plugins.get('TrackChanges');

    trackChanges.adapter = {
      async getSuggestion(suggestionId: string) {
        // Searches the <suggestion-start name="insertion:suggestion-1:user-2"></suggestion-start>
        // element on the content and returns the Suggestion ID for each one.
        // This function is called per each suggestion.
        // console.log('TrackChangesAdapter.getSuggestion', suggestionId);

        // Post the comment on the API.
        try {
          let suggestion = await API.GetSuggestion(
            inventoryModel,
            suggestionId,
          );

          suggestion = {
            ...suggestion,
            createdAt: new Date(
              parseFloat(suggestion.createdAt as string) * 1000,
            ),
          };

          return suggestion;

          // add the users
        } catch (error) {
          const toastId = 'getSuggestionError';
          if (!toast.isActive(toastId)) {
            toast({
              id: toastId,
              position: 'bottom-right',
              duration: 3000,
              isClosable: true,
              title:
                'Could not retrieve the track changes suggestions at this moment',
              description: API.getAPIErrorMessage(error),
              status: 'warning',
            });
          }
          return Promise.reject();
        }
      },

      async addSuggestion(suggestionData: TrackChangesAdapterSuggestionAdded) {
        // console.log('TrackChangesAdapter.addSuggestion', suggestionData);

        try {
          const suggestion = await API.PostSuggestion(
            inventoryModel,
            metadata,
            suggestionData,
          );

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });

          return suggestion;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title: 'Could not add the track changes suggestion at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'warning',
          });
          return Promise.reject();
        }
      },

      async updateSuggestion(
        id: string,
        suggestionData: TrackChangesAdapterSuggestionUpdated,
      ) {
        // console.log('TrackChangesAdapter.updateSuggestion', id, suggestionData);
        // Patch the Annotation type 'suggestion' to the API with its new state and if it has comments or not.

        try {
          const updatedSuggestion = await API.PatchSuggestion(
            inventoryModel,
            id,
            suggestionData,
          );

          queryClient.invalidateQueries({
            queryKey: [ActivityFeedWidgetQueryKey],
          });

          return updatedSuggestion;
        } catch (error) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            title:
              'Could not update the track changes suggestion at this moment',
            description: API.getAPIErrorMessage(error),
            status: 'warning',
          });
          return Promise.reject();
        }
      },
    };
  }
}
