import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import AdminAPI from '../../api/AdminAPI';
import { LoadingContainer } from '../../components/LoadingContainer';

const Tools = () => {
  const toast = useToast();

  const handleActionClick = async (action: string) => {
    try {
      await AdminAPI.PatchRBAC(action);
      toast({
        title: `${action} action executed successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: unknown) {
      const axiosError = error as any;
      toast({
        title: `Failed to execute ${action} action.`,
        description:
          axiosError?.response?.data?.message || 'An error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const actions = [
    {
      title: 'Refresh RBAC',
      description: 'Refresh Role-Based Access Control data.',
      action: 'refresh',
      enabled: true,
    },
    {
      title: 'Rebuild Permissions (Actions Update)',
      description: 'Recreate permissions.',
      action: 'rebuild',
      enabled: false,
    },
  ];

  return (
    <Box p={8}>
      <Heading mb={4}>Admin Tools</Heading>
      <LoadingContainer isLoading={false}>
        <VStack spacing={4} align="stretch">
          {actions.map(({ title, description, action, enabled }, index) => (
            <HStack
              key={index}
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="sm"
            >
              <Box>
                <Text fontWeight="bold">{title}</Text>
                <Text fontSize="sm" color="gray.600">
                  {description}
                </Text>
              </Box>
              <Spacer />
              <Button
                colorScheme="blue"
                onClick={() => handleActionClick(action)}
                isDisabled={!enabled}
              >
                Execute
              </Button>
            </HStack>
          ))}
        </VStack>
      </LoadingContainer>
    </Box>
  );
};

export default Tools;
