import { Box, Center, HStack, Text } from '@chakra-ui/react';
import packageJson from '../../../package.json';

import ValidMindLogo from '../ValidMindLogo';

export function Copyright({ mt = 40, mb = 10 }: { mt?: number; mb?: number }) {
  const packageJsonVersion = packageJson.version;
  return (
    <Center fontSize={'sm'} mt={mt} mb={mb}>
      <HStack gap={4} alignItems={'flex-start'}>
        <Box color={'brand.base'}>
          <ValidMindLogo height="16px" width="73px" />
        </Box>
        <Text>© 2025, Inc. All rights reserved.</Text>
        <Text
          color={'neutral.300'}
          fontFamily={'monospace'}
          fontSize={'xs'}
          mt={'2px'}
        >
          {`v${packageJsonVersion}`}
        </Text>
      </HStack>
    </Center>
  );
}
